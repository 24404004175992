import { makeStyles, withStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import {useHistory, useParams, useLocation} from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Popover from "@material-ui/core/Popover";
import Tooltip from "@material-ui/core/Tooltip";
import { toast } from 'react-toastify';
import _ from "lodash";
import {FaInfoCircle, FaRegPlusSquare, FaStar} from "react-icons/fa";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ResultTable from './ResultTable';
import CustomPagination from '../../shared/CustomPagination';
import DisplayColumnModal from '../../shared/Modal/DisplayColumnModal';
import fieldConfig from "./FieldConfig";
import filter from "./ResultFilter";
import DiscoverService from "../../services/discoverService";
import styled from "styled-components";
import UIlog from "./UILog"
import ReactHtmlParser from "react-html-parser";
import ResultStatsBar from "./ResultStatsBar";
import ChartService from "./ChartService";
import TabUnderTool from "./TabUnderTool";
import '../../assets/css/flag-icon.css';
import DedupPrefModal from '../../shared/Modal/DedupPrefModal';
import DiscoverContinueModal from "../../shared/Modal/DiscoverContinueModal";
import DiscoverExportAllModal from "../../shared/Modal/DiscoverExportAllModal";
import DiscoverExportChoicesModal from "../../shared/Modal/DiscoverExportChoicesModal";
import DiscoverPatbaseModal from "../../shared/Modal/DiscoverPatbaseModal";
import DiscoverExportVmModal from "../../shared/Modal/DiscoverExportVmModal";
import DiscoverSaveResultsModal from "../../shared/Modal/DiscoverSaveResultsModal";
import DiscoverSaveResultsSucModal from "../../shared/Modal/DiscoverSaveResultsSucModal";
import DiscoverMoreItemsModal from "../../shared/Modal/DiscoverMoreItemsModal";
import DiscoverFilterModal from "../../shared/Modal/DiscoverFilterModal";
import DiscoverMessageModal from "../../shared/Modal/DiscoverMessageModal";
import Authorities from '../../helpers/authorities';
import GQPowerTools from './GQPowerTools/GQPowerTools';
import SortingWidgetModal from '../../shared/Modal/SortingWidgetModal';
import {useSelector} from "react-redux";
import DiscoverFilterDeleteModal from "../../shared/Modal/DiscoverFilterDeleteModal";
import ftAccess from '../../services/ftAccess';

const Styles = styled.div`
  .side-menu-item-view {
    padding-left: 30px;
    cursor: default;
    margin-top: 2px;
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
 
    &:hover {
      overflow: visible;
    }
  }
`;
const useStyles = makeStyles((theme) => ({
    grow: {
        //flexGrow: 1,
        //width: '96%',
        //maxWidth: '1200px',
        margin: '0 auto',
        minHeight: '260px',
        padding: '23px 0 5px'
    },
    projectTitle: {
        margin: '0px 4px',
        position: 'relative',
        top: '2px',
        fontSize: '14px',
        padding: '0'
    },
    displayTitle: {
        margin: '0px 4px',
        position: 'relative',
        top: '2px',
        fontSize: '18px',
        padding: '0'
    },
    projectListItem: {
        padding: '0.2rem 0.25rem !important',
        border: 'none !important'
    },
    projTitleActive: {
        backgroundColor: '#008EC5',
        color: '#fff',
        padding: '3px',
        borderRadius: '3px'
    },
    txtwrp: {
        overflow: 'hidden',
        //whiteSpace: 'nowrap',
        display: 'block',
        textOverflow: 'ellipsis'
    },
    folderIcon: {
        width: '8%'
    },
    modalHeader: {
        borderBottom: 'none !important',
    },
    modalBody: {
        paddingBottom: '20px'
    },
    modal_body: {
        padding: '10px 0 0 0'
    },
    footerDiv: {
        padding: '0 10px'
    },
    checkBox: {
        transform: "scale(0.9)",
    },
    addNewLabel: {
        marginTop: '-20px',
        marginLeft: '31px'
    },
    addNewText: {
        marginTop: '-6px',
        marginLeft: '30px',
        height: '65px'
    },
    selectedTitle: {
        backgroundColor: '#337ab7',
        color: '#fff',
        padding: '3px',
        borderRadius: '3px'
    },
    buttonStyle: {
        backgroundColor: '#EEEEEE !important',
        border: '1px solid #CCCCCC !important',
        float: 'right',
        textTransform: 'none !important',
        marginTop: '4px !important',
        color: '#777777!important',
        boxShadow: 'none !important'
    },
    disabledButtonStyle: {
        float: 'right',
        textTransform: 'none !important',
        marginTop: '4px !important',
        backgroundColor: '#db862c!important',
        border: '1px solid #ca751b!important',
        color: 'white !important'
    },
    loader :{
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height:' 100%',
        zIndex: '999999',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        display: 'block',
 
    },
    loaderPosition:{
        marginLeft: '602px',
        marginTop: '300px',
    },
    hoverChanges: {
        zIndex: '350',
        position: 'relative',
   
        '&:hover': {
            padding: '5px',
            border: '1px solid grey',
            // background: 'white',
        }
    }   
}));

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        minHeight: 3,
        width: '102%',
        margin: 0,
        '&$expanded': {
            minHeight: 3,
            margin: 0,
        },
    },
    content: {
        margin: '0',
        padding: '0',
        '&$expanded': {
            margin: '0',
        },
    },
    expanded: {},
    // '@media (max-width: 780px)': {
    //     root: {
    //         width: '100%',
    //     }
    // }
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: '0 0 0 5px',
        margin: '0',
        display: 'block'
    },
}))(MuiAccordionDetails);

const toolTipStyles = {
    tooltip: {
        width: "auto",
        //height: "40px",
        fontSize: '12px',
        textAlign: 'justify',
        padding: "8px 14px 8px 14px",
        boxShadow: "0px 1px 7px #888",
        backgroundColor: '#777',
        color: '#FFF'
    }
};
const CustomTooltip = withStyles(toolTipStyles)(Tooltip);

const colors = ['White', 'Red', 'Orange', 'Yellow', 'Green', 'Blue', 'Purple',
     'Gray', 'Tan', 'Light Orange', 'Light Yellow', 'Light Green', 'Light Blue', 'Violet'];
const highlightColors = [
    'FFEB3B', 'FFA726', 'EF9A9A', 'B39DDB', '80CBC4', 'F48FB1', '81D4FA',
    'A5D6A7', 'E6EE9C', 'FFF59D', 'FFE082', 'FFCC80', 'FFAB91',
    'FF7043', 'BCAAA4', 'B0BEC5'
];
const famViewOptions = {
    '1': '1 Patent Number per Family',
    '3': '3 Patent Numbers per Family',
    '5': '5 Patent Numbers per Family',
    'All': 'All'
};
const pnViewOptions = {
    '1': '1 Alignment per Patent Number',
    '3': '3 Alignments per Patent Number',
    '5': '5 Alignments per Patent Number',
    '10': '10 Alignments per Patent Number',
    'All': 'All'
};
const ssvOptions = {
    '1': '1 Alignment per Subject Sequence',
    '3': '3 Alignments per Subject Sequence',
    '5': '5 Alignments per Subject Sequence',
    '10': '10 Alignments per Subject Sequence',
    'All': 'All'
};
const ufsOptions = {
    '1': '1 Alignment per UFS',
    '3': '3 Alignments per UFS',
    '5': '5 Alignments per UFS',
    '10': '10 Alignments per UFS',
    'All': 'All'
};
const qsOptions = {
    '1': '1 Alignment per Query Sequence',
    '3': '3 Alignments per Query Sequence',
    '5': '5 Alignments per Query Sequence',
    '10': '10 Alignments per Query Sequence',
    'All': 'All'
};
const hspOptions = {
    '1': '1 Alignment per Blast HSPs',
    '3': '3 Alignments per Blast HSPs',
    '5': '5 Alignments per Blast HSPs',
    '10': '10 Alignments per Blast HSPs',
    'All': 'All'
};

const _NOT_SEARCHING = 0;
const defaultCustomFiltersHelpMessage = {
    '0': 'Sample custom filter most appropriate for >20 residue sequences',
    '1': 'Finds subjects comprised by the query',
    '2': 'GenePast only; best for short queries.  Use as a foundation for additional filters.',
    '3': 'Antibody workflow only; Sample custom filter for Diff. Count <= 1 or %Id Query >=90. ',
};

const abFiltersMap = {
    querySeqDbs: "Query Sequence ID",
    subjSeqFrag: "Subject Sequence Fragment",
    matchingDocuments: "Matching Documents For",
    matchingSequences: "Matching Sequences For",
    cdrCombinationsOrder: "CDR Mismatches Order",
    cdrCombinations: "CDR Mismatches For"
};

function Discover(props) {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const userInfo = useSelector((state) => state.setUserInfo);
    const pageCount = useState('50');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [showLoader, setShowLoader] = useState(false);
    const [viewsHover, setViewsHover] = React.useState([]);
    const [addDisplayColumnShow, setAddDisplayColumnShow] = useState(false);
    const [tempTemplate, setTempTemplate] = useState({});

    const [ftWindowsToOpen, setFtWindowsToOpen] = useState(null);

    // Prepare the GenomeQuest Alignments
    let [gqAlignments, setGqAlignments] = useState([]);
    let [gqAlignmentsNoSumm, setGqAlignmentsNoSumm] = useState([]);
    let [gqAlignmentsStrNoSumm, setGqAlignmentsStrNoSumm] = useState([]);
    let [gqAlignmentsSumm, setGqAlignmentsSumm] = useState([]);
    let [gqAlignmentsVariation, setGqAlignmentsVariation] = useState([]);

    let [facetInfo, setFacetInfo] = useState({});
    let [resInfo, setResInfo] = useState({});
    let [sideMenuCollapse ,setSideMenuCollapse] = useState(false);
    let [chartMenu ,setChartMenu] = useState(false);
    let [multiQuerySeqMenu, setMultiQuerySeqMenu] = useState(false);
    let [state, setState] = useState({
        claimCollapse: {},          // whether the claims for a specific PN are shown fully, or only the first three claims
        sideMenuCollapse: false,    // which of the side-bar menus are open / collapsed
        currentPage: 1,   // which page of the results we're on
        itemsCurrentPage: 1,
        maxed_out_exceptions: {},   // which PNs to show all SEQ ID NOs for, regardless of the amount (normally it gets cut off)
        showAll: false,             // render 3 results per PN first, then render the rest when this variable is set to true
        tableGetsKeyPress: true,    // when set to true, the key presses are used by the table (for navigation and annotation)
        showHighlightColorBox: false,
        //showCommentIcon: false,
        //showCommentBox: false,
        toolbarTab: false,
    });
    const [originalState, setOriginalState] = useState({
        // facet filters
        SUBJECT_PN: {},
        SUBJECT_PG: {},
        SUBJECT_PS: {},

        // default sort
        sort: 'high_percid_query',

        // list of PNs "Publication Number" list / text area in the UI
        publicationNumberList: '',

        smartTextFilter: {},
        smartText: '',
        smartTextPopup: false,
        smartTextOptions: [],

        smartHighlightPopup: false,
        smartHighlight: '',
        smartHighlightOptions: [],
        smartHighlightFilter: {},
        smartFiltersHover: [],

        // state of the "Alignment Annotation" filter
        annot: {
            checkboxes: {
                checked: false,
                unchecked: false
            },
            colors: [
                false, false, false, false, false, false, false,
                false, false, false, false, false, false, false
            ],
            stars: [
                false, false, false, false
            ],
            notes: []
        },

        // state of the "Alignment Quality" filter
        align: {
            len: false,
            percidq: false,
            percids: false,
            percida: false,
            vallen: 100,
            valpercidq: 70,
            valpercids: 70,
            valpercida: 70
        },

        // SUBJECT_GNAME
        databaseNames: {},

        querySeqDbs: {},

        // switch for apply button enabled / disabled
        applyEnabled: false,

        // which checkboxes are checked in the UI (UI state)
        customFilterCheckboxes: [],

        // which LVA checkboxes are checked in the UI (UI state)
        lvaFilterCheckboxes: [],

        // which global filter checkboxes are checked in the UI (UI state)
        globalLvaFiltersCheckboxes: []
    });


    //antibody
    const [abFilterDesc, setAbFiltersDesc] = useState([])


    //powerTools
    const [abFilters, setAbFilters] = useState({});
    const [gqApiKey, setGqApiKey] = useState("");
    const [resultReportFilters, setResultReportFilters] = useState({});

    //states
    //let [isloaded, setIsloaded] = useState(false);
    let [isFtWorkflow, setIsFtWorkflow] = useState(false);
    let [isAbWorkflow, setIsAbWorkflow] = useState(false);
    let [hasLvaAccess, setHasLvaAccess] = useState(false);
    let [hasftAccess, setHasftAccess] = useState(false);
    let [hasRegAccess, setHasRegAccess] = useState(false);
    let [hasRegNp, setHasRegNp] = useState(false);
    const [lqAuthKey, setLqAuthKey] = useState('');
    const [lqBaseUrl, setLqBaseUrl] = useState('');
    const [loginName, setLoginName] = useState('');
    let [userdir, setUserdir] = useState('');
    let [infoMsg, setInfoMsg] = useState('');
    //let [appErrorMsg, setAppErrorMsg] = useState('');
    let [showInfoMsg, setShowInfoMsg] = useState(false);

    let [lvaQueries, setLvaQueries] = useState({});
    const [vmIds, setVmIds] = useState({});
    let [defaultLvaQuery, setDefaultLvaQuery] = useState();
    const [lvaLoadingReady, setLvaLoadingReady] = useState(false);
    let [loadingLvaTables, setLoadingLvaTables] = useState(false);
    let [seqReports, setSeqReports] = useState([]);
    //const [abReportNames, setAbReportNames] = useState([]);

    let [data, setData] = useState({});
    const [searchResultData, setSearchResultData] = useState({});
    const [annotState, setAnnotState] = useState({});
    const [itemsAnnotState, setItemsAnnotState] = useState({});

    let [querydb, setQuerydb] = useState([]);
    const [querySeqIds, setQuerySeqIds] = useState([]);
    let [querySeqIdsCount, setQuerySeqIdsCount] = useState(1);
    let [stats, setStats] = useState([]);
    let [docItems, setDocItems] = useState({});
    let [docItemsSelected, setDocItemsSelected] = useState({});
    const [databases, setDatabases] = useState({});
    const [dataBasesForDed, setDataBasesForDed] = useState({});  //database pref for dedup
    let databasesForDed = {};
    const [dedupModal, setDedupModal] = useState(false);
    const [dedDataBasePrefsForUI, setDedDataBasePrefsForUI] = useState([]);
    let [queryDbStats, setQueryDbStats] = useState({});
    let [supportedDisplayModes, setSupportedDisplayModes] = useState({});
    // Whether to clear all filters/groupings
    const [showContinueAlert, setShowContinueAlert] = useState(false);
    const [continueMsg, setContinueMsg] = useState('');
    const [showReportAlert, setShowReportAlert] = useState(false);
    const [editable, setEditable] = useState(true);

    const [manageSortModal, setManageSortModal] = useState(false);

    // Highlight Text variables
    const [smartHighlightText, setSmartHighlightText] = useState('');
    const [smartHighlightPopup, setSmartHighlightPopup] = useState(false);
    const [smartHighlightFilterHover, setSmartHighlightFilterHover] = useState([]);
    const [highlightColorBox, setHighlightColorBox] = useState([]);
    let [smartHighlightFilter, setSmartHighlightFilter] = useState({});
    const [smartHighlightOptions, setSmartHighlightOptions] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [hlFilterSearching, setHlFilterSearching] = React.useState('');
    const minSmartTextLen = 2;

    const [applyEnabled, setApplyEnabled] = useState(false);

    // Filter Text variables
    const [smartText, setSmartText] = useState('');
    const [smartTextPopup, setSmartTextPopup] = useState(false);
    const [smartTextHover, setSmartTextHover] = useState([]);
    let [smartTextFilter, setSmartTextFilter] = useState({});
    const [smartTextOptions, setSmartTextOptions] = useState([]);
    const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);
    const [filterSearching, setFilterSearching] = React.useState('');
    const [filterState, setFilterState] = useState({});
    // For Advanced Filter
    const [addFilterPopup, setAddFilterPopup] = useState(false);
    const [deleteFilterPopup, setDeleteFilterPopup] = useState(false);
    const [deleteIdx,setDeleteIdx] = useState(false);
    const [widgetState, setWidgetState] = useState({});
    const [customFiltersHover, setCustomFiltersHover] = React.useState([]);

    // PN list variable
    const [pnListErr, setPnListErr] = useState('');

    // Export variables
    const [totalAlignmentNumber, setTotalAlignmentNumber] = useState(0);
    const [totalPatentNumber, setTotalPatentNumber] = useState(0);
    const [customTtlCount, setCustomTtlCount] = useState(0);
    const [downloadType, setDownloadType] = useState('');
    const [downloadTypeDesc, setDownloadTypeDesc] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [customAlertMessage, setCustomAlertMessage] = useState('');
    const [showExportAllModal, setShowExportAllModal] = useState(false);
    const [showExportChoicesModal, setShowExportChoicesModal] = useState(false);
    const [displayCustomSelection, setDisplayCustomSelection] = useState(false);
    const [customSelectionLabel, setCustomSelectionLabel] = useState('');
    const [selectedAlertMessage, setSelectedAlertMessage] = useState('');
    const [selectedAlignmentsCount, setSelectedAlignmentsCount] = useState(0);
    const [selectedAlignmentsIds, setSelectedAlignmentsIds] = useState([]);
    const [showExportVmModal, setShowExportVmModal] = useState(false);
    const [showSaveResultsModal, setShowSaveResultsModal] = useState(false);
    const [showSaveResultsSucModal, setShowSaveResultsSucModal] = useState(false);
    const [saveAsName, setSaveAsName] = useState('');
    const [saveFiltedResultMsg, setSaveFiltedResultMsg] = useState('');
    const [includeResult, setIncludeResult] = useState(false);
    const [includeAnnotation, setIncludeAnnotation] = useState(false);
    const [includeSS, setIncludeSS] = useState(false);
    // For Patbase export
    const [showPatbaseModal, setShowPatbaseModal] = useState(false);
    const [pbCountdown, setPbCountdown] = useState(3);
    const [patbasePns, setPatbasePns] = useState([]);
    const [totalPatbasePns, setTotalPatbasePns] = useState(0);
    const [patbasePnsStr, setPatbasePnsStr] = useState('');
    let pbTimer;

    // More items page
    const [itemsCurrentPage, setItemsCurrentPage] = useState(1);
    const [itemsTotalPage, setItemsTotalPage] = useState(1);
    const [itemsData, setItemsData] = useState({});
    const [showItemsModal, setShowItemsModal] = useState(false);
    const [groupValue1, setGroupValue1] = useState('');
    const [groupValue2, setGroupValue2] = useState('');

    let [sideMenuDetail, setSideMenuDetail] = useState(null);
    let [alignmentsCollapseAll, setAlignmentsCollapseAll] = useState(true);
    let [alignmentsCollapse, setAlignmentsCollapse] = useState({});
    let [claimsCollapseAll, setClaimsCollapseAll] = useState(true);
    let [claimsCollapse, setClaimsCollapse] = useState({});

    let [detailPageCollapseAllLevelOne, setDetailPageCollapseAllLevelOne] = useState(false);
    let [detailPageCollapseAllLevelTwo, setDetailPageCollapseAllLevelTwo] = useState(false);
    let [detailPageCollapseAllLevelThree, setDetailPageCollapseAllLevelThree] = useState(false);

    const [deduplication, setDeduplication] = useState(false);
    const [dedDatabasePrefs, setDedDatabasePrefs] = useState([]);
    const [showDedAlert, setShowDedAlert] = useState(false);
    const [selectedDatabase, setSelectedDatabase] = useState('');

    const [viewKeys, setViewKeys] = useState([]);
    const [viewCount, setViewCount] = useState({});
    const [viewOptions, setViewOptions] = useState(null);
    const [groupOptions, setGroupOptions] = useState([
        'family', 'document', 'flat', 'ufs', 'ssv', 'qs', 'hsp', 'cas'
    ]);
    let [selectedGroupOption, setSelectedGroupOption] = useState('');

    let [groupQuery, setGroupQuery] = useState({});

    const {resid, alerts, singleAlignmentId, abExp, vmExp, vmQueryId, reportId} = useParams();
    const {abReportfilters} = useParams();
    const toggle = useSelector(state => state.setToggle.toggle);

    const changePage = async (e, page) => {
        //let start, stop;
        if (page) {
            //start = ((page - 1) * pageCount) + 1;
            //stop = page * pageCount;
            setCurrentPage(page);
            state.currentPage = page;
            setSearchResultData({});
            fetchResultsNow(true);
        }
    }

    useEffect(() => {

        if(abReportfilters !== undefined && abReportfilters !== null && abReportfilters !== ""){
            var localAbFilters = JSON.parse(decodeURIComponent(abReportfilters))
            if(!_.isEmpty(localAbFilters)){
                window.reportFilters = localAbFilters;
                if (reportId) {
                    history.push("/discover/"+resid+"/report/" + reportId);
                } else {
                    history.push("/discover/"+resid+"");
                }
            }
        }

        if (userInfo && userInfo.current_user && userInfo.current_user.gq_user_id) {
            if (singleAlignmentId === undefined) {
                fieldConfig.singleAlignment = false;
            } else {
                fieldConfig.singleAlignment = true;
            }
            if (abExp === undefined) {
                fieldConfig.forAb = false;
            } else {
                fieldConfig.forAb = true;
            }
            if (alerts === undefined) {
                fieldConfig.forAlert = false;
            } else {
                fieldConfig.forAlert = true;
            }
            if (vmExp === undefined) {
                fieldConfig.forVm = false;
            } else {
                fieldConfig.forVm = true;
            }
            if (reportId === undefined) {
                fieldConfig.reportId = '';
            } else {
                fieldConfig.reportId = reportId;
            }
            setShowLoader(true);
            fieldConfig.combinedFields = fieldConfig.prepCombinedFields();
            // firstTimeInitial - 1
            fieldConfig.loadConfig('result_rb2', fieldConfig, filter, resid.match(/\d+/)[0]).then(views => {
                //filter = [...filter];
                setViewKeys(views);
            });
            getAuthInfo(); // firstTimeInitial - 2, will call loadResultState()
            // sameAsOriginalOrContinue(); // firstTimeInitial - 3
            //variationReportOperation('list', '', '');

            const timer = setInterval(() => {
                (async () => {
                    await callGQApi();
                })();
            }, 882000); // every 14.7 mins as the api token is valid for 15 mins

            return () => {
                clearInterval(timer);
            };
        }
    }, [userInfo]);

    async function getAuthInfo() {
        let response = await DiscoverService.getAuthInfo(resid, history);
        
        if (response && response.response_content) {
            if (response.response_content.type === 'GQEXCEPTION') {
                alert(response.response_content.message);
                return;
            }

            setGqApiKey(response.response_content.gqApiKey);
            // Workflow path/title/algorithm
            let workflowDir = response.response_content.userdir;
            setUserdir(response.response_content.userdir);
            let workflowId = resid.match(/\d+/)[0];
            if (workflowId) {
                // e.g. /opt/genomequest/apache/data/GQdata/userdata/admin/workflow/1341/results.res, "workflow/1341/results.res" to be removed in frontend
                userdir = workflowDir.replace('workflow/' + workflowId + '/results.res', '');
                filter.userdir = userdir;
            }
            resInfo = {...resInfo};
            resInfo['title'] = response.response_content.title;
            document.title = 'GenomeQuest - '+response.response_content.title;
            resInfo['algorithm'] = response.response_content.algorithm;
            setResInfo(resInfo);
            setSaveAsName("copy of " + resInfo['title']);

            if (response.response_content.status && response.response_content.status === 'ARCHIVED') {
                alert("These search results have been archived. If you require access to the search results, " +
                    "please reach out to support@gqlifesciences.com and request for the data to be restored. Note that this process can take up to 48 hours.");
                // setAppErrorMsg();
                //setShowAppError(true);
                return;
            }

            if (response.response_content.dbs) {
                filter.dbs = response.response_content.dbs;
                /*response.response_content.dbs.forEach(function (value) {
                    if (value.startsWith("CAS") || value.startsWith("REG")) {
                        setHasRegAccess(true);
                        //break;
                    }
                });*/
            }
            hasLvaAccess = Boolean(response.response_content.lvaAccess);
            setHasLvaAccess(hasLvaAccess);
            hasftAccess = Boolean(response.response_content.ftAccess);
            setHasftAccess(hasftAccess);
            let hasCasAccess = Boolean(response.response_content.casAccess);
            setHasRegAccess(hasCasAccess);

            setLqAuthKey(response.response_content.lqAuthKey);
            setLqBaseUrl(response.response_content.lqBaseUrl);
            setLoginName(response.response_content.loginName);
            isAbWorkflow = response.response_content.isAbWorkflow;
            setIsAbWorkflow(isAbWorkflow);
            isFtWorkflow = response.response_content.isFtWorkflow;
            let algorithm = response.response_content.algorithm;
            if (isFtWorkflow) {
                setGroupOptions(['family', 'document', 'flat']);
            } else if (algorithm && (algorithm.toLowerCase() === 'genepast' || algorithm.toLowerCase() === 'hash')) {
                setGroupOptions(['family', 'document', 'flat', 'ufs', 'ssv', 'qs', 'cas']);
            }

            let javaCall = {
                data: {
                    workflowId: resid.match(/\d+/)[0],
                    userId: userdir,
                    gqApiKey: response.response_content.gqApiKey
                }
            };
            window.$javaCall = javaCall;

            updateLastAccess();
            loadResultState(resInfo); // clearAnnotation() method requires the above info
        }
    }

    async function saveResultState(filter, fieldConfig) {
        filter.lvaWidgetState = {...filter.lvaWidgetState};
        filter.widgetState = {...filter.widgetState};
        filter.state = {...filter.state};

        let saveLvaFilters = [];
        if (filter.lvaWidgetState && filter.lvaWidgetState.lvaFilters) {
            for (let idx in filter.lvaWidgetState.lvaFilters) {
                if (!filter.lvaWidgetState.lvaFilterDeleted || !filter.lvaWidgetState.lvaFilterDeleted[idx]) {
                    saveLvaFilters.push(filter.lvaWidgetState.lvaFilters[idx]);
                }
            }
        }
        let tmpAlignmentsCollapse = {};
        for (let col in alignmentsCollapse) {
            if (alignmentsCollapse[col] !== alignmentsCollapseAll) {
                tmpAlignmentsCollapse[col] = alignmentsCollapse[col];
            }
        }
        let tmpClaimsCollapse = {};
        for (let col in claimsCollapse) {
            if (claimsCollapse[col] !== claimsCollapseAll) {
                tmpClaimsCollapse[col] = claimsCollapse[col];
            }
        }
        if (singleAlignmentId === undefined && abExp === undefined && vmExp === undefined && reportId === undefined) {
            let data = {
                'do': 'gqresult2.save_state',
                'db': resid,
                'state': {
                    resbrowseCtrlState: state,
                    resultFilterState: filter.state,
                    sideMenuDetail: sideMenuDetail,
                    tableDisplayMode: fieldConfig.tableDisplayMode,
                    famViewOption: fieldConfig.famViewOption,
                    famViewAliOption: fieldConfig.famViewAliOption,
                    pnViewOption: fieldConfig.pnViewOption,
                    ssvOption: fieldConfig.ssvOption,
                    ufsOption: fieldConfig.ufsOption,
                    qsOption: fieldConfig.qsOption,
                    hspOption: fieldConfig.hspOption,
                    groupOption: fieldConfig.groupOption,
                    lvaFilters: saveLvaFilters, // This is saved by result, not with fieldConfig
                    alignmentsCollapseAll: alignmentsCollapseAll,
                    alignmentsCollapse: tmpAlignmentsCollapse,
                    claimsCollapseAll: claimsCollapseAll,
                    claimsCollapse: tmpClaimsCollapse,
                    detailPageCollapseAllLevelOne: detailPageCollapseAllLevelOne,
                    detailPageCollapseAllLevelTwo: detailPageCollapseAllLevelTwo,
                    detailPageCollapseAllLevelThree: detailPageCollapseAllLevelThree,
                    saved: true,
                    userTableTemplate: fieldConfig.userTableTemplate,
                    currentView: fieldConfig.currentView,
                    deduplication: filter.state.deduplication,
                    dedDatabasePrefs: filter.state.dedDatabasePrefs
                },
                format: 'json',
                error_detection: true,
                parm_location: 'inner'
            };
            // Check the data if it is a valid JSON string, and its length < 65535
            /*if (JSON.stringify(data).length > 65000) { // 65535 length limitation in app_state table
                popupInfoMsg('Maximum state value limit reached. Please reduce your variation filters to proceed.');
                return;
            }*/
            await DiscoverService.saveState(data);
        }
    }

    async function updateLastAccess() {
        let workflowId = resid.match(/\d+/)[0];
        let data = {workflowId: workflowId};
        await DiscoverService.updateLastAccess(data, window.$javaCall.data.gqApiKey);
    }

    async function loadResultState(resInfo) {
        //if (singleAlignmentId !== undefined || abExp !== undefined || vmExp !== undefined ) { // || window.notLoadSavedResFilters
            const response = await DiscoverService.loadResultState(resid, reportId === undefined ? '' : reportId);
            //filter.state = originalState;
            Object.assign(filter.state, originalState);
            setFilterState({...originalState});
            if (response && response.response_status === 0 && response.response_content) {

                if (response.response_content.resbrowseCtrlState) {
                    state = response.response_content.resbrowseCtrlState;
                }
                if (response.response_content.resultFilterState) {
                    filter.state = _.cloneDeep(response.response_content.resultFilterState);
                    if (!filter.state.customFilterCheckboxes) {
                        filter.state.customFilterCheckboxes = [];
                    }
                    if (!filter.state.lvaFilterCheckboxes) {
                        filter.state.lvaFilterCheckboxes = [];
                    }
                    if (!filter.state.annot) {
                        filter.state.annot = {};
                    }
                    if (!filter.state['SUBJECT_PN']) {
                        filter.state['SUBJECT_PN'] = {};
                    }
                    if (!filter.state['SUBJECT_PG']) {
                        filter.state['SUBJECT_PG'] = {};
                    }
                    if (!filter.state['SUBJECT_PS']) {
                        filter.state['SUBJECT_PS'] = {};
                    }
                    if (!filter.state['querySeqDbs']) {
                        filter.state['querySeqDbs'] = {};
                    }
                    if (!response.response_content.resultFilterState.sort) {
                        filter.state.sort = 'high_percid_query';
                    }
                    //Object.assign(filter.state, response.response_content.resultFilterState);
                    setSmartHighlightFilter(_.cloneDeep(filter.state.smartHighlightFilter));
                    setSmartHighlightText(filter.state.smartHighlight);
                    setSmartText(filter.state.smartText);
                    if (filter.state.smartTextFilter) {
                        setSmartTextFilter(JSON.parse(JSON.stringify(filter.state.smartTextFilter)));
                    }
                    Object.assign(filterState, response.response_content.resultFilterState);
                }
                sideMenuDetail = {};
                if (response.response_content.sideMenuDetail) {
                    Object.assign(sideMenuDetail, response.response_content.sideMenuDetail);
                    //setDefaultViews(response.response_content.sideMenuDetail['saved-view']);
                } else {
                    //setDefaultViews(true);
                    sideMenuDetail['saved-view'] = false;
                    sideMenuDetail['lvaModules'] = false;
                    sideMenuDetail['lvaFilters'] = false;
                    sideMenuDetail['globalLvaFilters'] = false;
                    sideMenuDetail['customfilter'] = false;
                    sideMenuDetail['mycustomfilter'] = false;
                }
                setSideMenuDetail({...sideMenuDetail});
                if (response.response_content.tableDisplayMode) {
                    fieldConfig.tableDisplayMode = response.response_content.tableDisplayMode;
                    selectedGroupOption = response.response_content.tableDisplayMode;
                    setSelectedGroupOption(response.response_content.tableDisplayMode);
                }
                let options = {};
                if (response.response_content.famViewOption) {
                    options.selectedFamViewOption = response.response_content.famViewOption;
                    fieldConfig.famViewOption = response.response_content.famViewOption;
                } else {
                    options.selectedFamViewOption = '3';
                    fieldConfig.famViewOption = '3';
                }
                if (response.response_content.famViewAliOption) {
                    options.selectedFamViewAliOption = response.response_content.famViewAliOption;
                    fieldConfig.famViewAliOption = response.response_content.famViewAliOption;
                } else {
                    options.selectedFamViewAliOption = '10';
                    fieldConfig.famViewAliOption = '10';
                }
                if (response.response_content.pnViewOption) {
                    options.selectedPnViewOption = response.response_content.pnViewOption;
                    fieldConfig.pnViewOption = response.response_content.pnViewOption;
                } else {
                    options.selectedPnViewOption = '10';
                    fieldConfig.pnViewOption = '10';
                }
                if (response.response_content.ssvOption) {
                    options.selectedSsvOption = response.response_content.ssvOption;
                    fieldConfig.ssvOption = response.response_content.ssvOption;
                } else {
                    options.selectedSsvOption = '10';
                    fieldConfig.ssvOption = '10';
                }
                if (response.response_content.ufsOption) {
                    options.selectedUfsOption = response.response_content.ufsOption;
                    fieldConfig.ufsOption = response.response_content.ufsOption;
                } else {
                    options.selectedUfsOption = '10';
                    fieldConfig.ufsOption = '10';
                }
                if (response.response_content.qsOption) {
                    options.selectedQsOption = response.response_content.qsOption;
                    fieldConfig.qsOption = response.response_content.qsOption;
                } else {
                    options.selectedQsOption = '10';
                    fieldConfig.qsOption = '10';
                }
                if (response.response_content.hspOption) {
                    options.selectedHspOption = response.response_content.hspOption;
                    fieldConfig.hspOption = response.response_content.hspOption;
                } else {
                    options.selectedHspOption = '10';
                    fieldConfig.hspOption = '10';
                }
                if (response.response_content.groupOption) {
                    options.selectedGroupOption = response.response_content.groupOption;
                    fieldConfig.groupOption = response.response_content.groupOption;
                } else {
                    options.selectedGroupOption = '10';
                    fieldConfig.groupOption = '10';
                }
                setViewOptions(options);

                if (!filter.lvaWidgetState) {
                    filter.lvaWidgetState = {};
                }
                if (response.response_content.lvaFilters) {
                    filter.lvaWidgetState.lvaFilters = _.cloneDeep(response.response_content.lvaFilters);
                    for (let key in filter.lvaWidgetState.lvaFilters) {
                        let tmpFilter = filter.lvaWidgetState.lvaFilters[key];
                        if (!tmpFilter.uuid) {
                            tmpFilter.uuid = filter.generateUUID();
                        }
                        if (!tmpFilter.widgetMode) {
                            tmpFilter.widgetMode = 'AND';
                        }
                        //tmpFilter.filterIdx = key;
                        for (let methodIdx in tmpFilter.widgetContent) {
                            tmpFilter.widgetContent[methodIdx].colors = {
                                color: tmpFilter.selectedColor ? tmpFilter.selectedColor : "blue",
                                bgColor: "white"
                            };
                        }
                    }
                    // To refresh the VM filters for GQPowerTools & ResultStatsBar
                    setVmIds({...vmIds});
                }
                if (response.response_content.userTableTemplate) {
                    fieldConfig.userTableTemplate = response.response_content.userTableTemplate;
                    fieldConfig.estimateTableWidth(fieldConfig.userTableTemplate, fieldConfig.tableDisplayMode);
                }
                if (response.response_content.currentView) {
                    fieldConfig.currentView = response.response_content.currentView;
                }
                setViewsHover([]);

                filter.state.deduplication = false;
                if (response.response_content.deduplication) {
                    setDeduplication(response.response_content.deduplication);
                    filter.state.deduplication = response.response_content.deduplication;
                }
                if (response.response_content.dedDatabasePrefs) {
                    setDedDatabasePrefs(response.response_content.dedDatabasePrefs);
                    filter.state.dedDatabasePrefs = response.response_content.dedDatabasePrefs;
                }

                let sameAsOriginal = true;
                if (!(singleAlignmentId !== undefined || abExp !== undefined || vmExp !== undefined || reportId !== undefined)) {
                    // || window.notLoadSavedResFilters
                    if (isFtWorkflow) {
                        fieldConfig.tableDisplayMode = 'document';
                        selectedGroupOption = 'document';
                        setSelectedGroupOption('document');
                        fieldConfig.currentView = 'defaultFt';
                        fieldConfig.selectView(fieldConfig, 'defaultFt', filter, editable);
                    }
                    if (response.response_content.alignmentsCollapseAll === false) {
                        alignmentsCollapseAll = false;
                        setAlignmentsCollapseAll(false);
                    } else {
                        alignmentsCollapseAll = true;
                        setAlignmentsCollapseAll(true);
                    }
                    alignmentsCollapse = response.response_content.alignmentsCollapse;
                    if (response.response_content.claimsCollapseAll === false) {
                        claimsCollapseAll = false;
                        setClaimsCollapseAll(false);
                    } else {
                        claimsCollapseAll = true;
                        setClaimsCollapseAll(true);
                    }
                    claimsCollapse = response.response_content.claimsCollapse;
                    detailPageCollapseAllLevelOne = response.response_content.detailPageCollapseAllLevelOne;
                    detailPageCollapseAllLevelTwo = response.response_content.detailPageCollapseAllLevelTwo;
                    detailPageCollapseAllLevelThree = response.response_content.detailPageCollapseAllLevelThree;

                    if (JSON.stringify(originalState['publicationNumberList']) !== JSON.stringify(filter.state['publicationNumberList'])
                        || JSON.stringify(originalState['databaseNames']) !== JSON.stringify(filter.state['databaseNames'])) {
                        sameAsOriginal = false;
                    }

                    if (sameAsOriginal) {
                        for (let key in originalState) {
                            if (key === 'annot') {
                                if (filter.state[key]) {
                                    if (filter.state.annot.checkboxes) {
                                        for (let anKey in filter.state.annot.checkboxes) {
                                            if (filter.state.annot.checkboxes[anKey] === true) {
                                                sameAsOriginal = false;
                                                break;
                                            }
                                        }
                                    }
                                    if (filter.state.annot.colors) {
                                        for (let anKey in filter.state.annot.colors) {
                                            if (filter.state.annot.colors[anKey] === true) {
                                                sameAsOriginal = false;
                                                break;
                                            }
                                        }
                                    }
                                    if (filter.state.annot.stars) {
                                        for (let anKey in filter.state.annot.stars) {
                                            if (filter.state.annot.stars[anKey] === true) {
                                                sameAsOriginal = false;
                                                break;
                                            }
                                        }
                                    }
                                    if (filter.state.annot.noteList) {
                                        sameAsOriginal = false;
                                        break;
                                    }
                                }
                            } else if (key === 'customFilterCheckboxes' || key === 'lvaFilterCheckboxes'
                                || key === 'SUBJECT_PN' || key === 'SUBJECT_PG' || key === 'SUBJECT_PS' || key === 'querySeqDbs') {
                                if (filter.state[key] && _.size(filter.state[key]) > 0) {
                                    for (let cbKey in filter.state[key]) {
                                        if (filter.state[key][cbKey] === true) {
                                            sameAsOriginal = false;
                                            break;
                                        }
                                    }
                                }
                            } else if (key === 'smartTextFilter') {
                                if (Object.keys(filter.state.smartTextFilter).length > 0) {
                                    for (let typeFilter in filter.state.smartTextFilter) {
                                        for (let tff in filter.state.smartTextFilter[typeFilter]) {
                                            //console.log(filter.state.smartTextFilter[typeFilter][tff].value);
                                            if (filter.state.smartTextFilter[typeFilter][tff].value === true) {
                                                sameAsOriginal = false;
                                                break;
                                            }
                                        }
                                    }
                                }
                            } else if (key === 'smartHighlightFilter') {
                                if (filter.state.smartHighlightFilter && Object.keys(filter.state.smartHighlightFilter).length > 0) {
                                    for (let typeFilter in filter.state.smartHighlightFilter) {
                                        for (let tff in filter.state.smartHighlightFilter[typeFilter]) {
                                            //console.log(filter.state.smartHighlightFilter[typeFilter][tff].value);
                                            if (filter.state.smartHighlightFilter[typeFilter][tff].value === true) {
                                                sameAsOriginal = false;
                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if (filter.state.deduplication) {
                        sameAsOriginal = false;
                    }

                    if (!sameAsOriginal) {
                        setShowLoader(false);
                        if (_.isEmpty(window.reportFilters)) {
                            let msg = 'Welcome back to ' + resInfo.title + ' results. ' +
                                'You can continue to work on your results, or you can clear all filters, groupings and annotations.';
                            setContinueMsg(msg);
                            setShowContinueAlert(true);
                        } else {
                            // Set the grouping for AB linkouts in advance
                            fieldConfig.tableDisplayMode = window.reportFilters.groupBy;
                            selectedGroupOption = window.reportFilters.groupBy;
                            setSelectedGroupOption(window.reportFilters.groupBy);
                            fieldConfig.currentView = 'default';
                            // Don't call below to avoid saving state for AB links
                            //fieldConfig.selectView(fieldConfig, 'default', filter);
                            closeContinueModal(resInfo);
                        }
                    }
                }

                if (singleAlignmentId !== undefined) {
                    //resetAllFitlers(fieldConfig, filter);
                    filter.state.singleAlignmentId = singleAlignmentId;
                } else if (abExp !== undefined) {
                    //resetAllFitlers(fieldConfig, filter);
                    filter.state.abExp = abExp;
                } else if (vmExp !== undefined) {
                    //resetAllFitlers(fieldConfig, filter);
                    //clearCustomFilter(filter, 'lvaFilterCheckboxes');
                    filter.state.vmExp = vmExp;
                    filter.state.vmQueryId = vmQueryId;
                    if (reportId !== undefined) {
                        filter.state.reportId = reportId;
                    }
                } else if (reportId !== undefined) {
                    filter.state.reportId = reportId;
                }

                Object.assign(filterState, filter.state);
                setFilterState({...filterState});

                if (reportId !== undefined) {
                    setShowLoader(false);
                    // Show report dialog
                    let msg = 'Welcome back to ' + resInfo.title + ' results. ' +
                        'You can continue to view your Read Only subset, or you can go back to the Editable report.';
                    setContinueMsg(msg);
                    setShowReportAlert(true);
                } else if (sameAsOriginal) {
                    // Load data
                    fetchResultsNow();
                    loadStatistic(resInfo);
                }
            }

            let localAbRepFilters = _.cloneDeep(window.reportFilters)
            console.log("Is from AB Module:" + !_.isEmpty(localAbRepFilters));
            if (!_.isEmpty(localAbRepFilters)) {

                fieldConfig.tableDisplayMode = localAbRepFilters.groupBy;
                selectedGroupOption = localAbRepFilters.groupBy;
                setSelectedGroupOption(localAbRepFilters.groupBy);
                setAbFiltersDesc(getAbFiltersDesc());

                // if(localAbRepFilters.reportName!== undefined && localAbRepFilters.reportName!== null &&localAbRepFilters.reportName!== ""){
                //     let data = {
                //         abReportName: localAbRepFilters.reportName,
                //         userId: window.$javaCall.data.userId,
                //         workflowId: resid.match(/\d+/)[0],
                //     }
                //     const results = await DiscoverService.loadAbReport(data, window.$javaCall.data.gqApiKey)
                //     setFiltersFromAb(results.filters, filter)
                // }

            }

            //firstTimeInitial++;
        //}
    }

    const closeReportModal = (resInfo) => {
        setEditable(false);
        setShowReportAlert(false);
        fetchResultsNow();
        loadStatistic(resInfo);
    }

    const startOverReport  = (filter, filterState, resInfo, fieldConfig) => {
        // Remove reportId to work with the current results
        history.push(location.pathname.replace(reportId, ''));
    }

    const closeContinueModal = (resInfo) => {
        setShowContinueAlert(false);
        fetchResultsNow();
        loadStatistic(resInfo);
    }

    const startOver = async(filter, filterState, resInfo, fieldConfig) => {
        let tmpSmartHighlightFilter = filter.state.smartHighlightFilter;
        if (tmpSmartHighlightFilter && Object.keys(tmpSmartHighlightFilter).length > 0) {
            for (let typeFilter in tmpSmartHighlightFilter) {
                for (let filter in tmpSmartHighlightFilter[typeFilter]) {
                    if (tmpSmartHighlightFilter[typeFilter][filter].value === true) {
                        tmpSmartHighlightFilter[typeFilter][filter].value = false;
                    }
                }
            }
        }
        resetAllFitlers(fieldConfig, filter);
        // Set to default grouping
        if (isFtWorkflow) {
            fieldConfig.tableDisplayMode = 'document';
            selectedGroupOption = 'document';
            setSelectedGroupOption('document');
            fieldConfig.currentView = 'defaultFt';
            fieldConfig.selectView(fieldConfig, 'defaultFt', filter, editable);
        } else {
            fieldConfig.tableDisplayMode = 'flat';
            selectedGroupOption = 'flat';
            setSelectedGroupOption('flat');
            fieldConfig.currentView = 'default';
            fieldConfig.selectView(fieldConfig, 'default', filter, editable);
        }
        if (editable === true) {
            saveResultState(filter, fieldConfig);
        }

        // while the backend is preparing results we show the user a spinner animation
        setShowLoader(true);
        // Clear Annatation
        let call = {
            workflowId: resid.match(/\d+/)[0],
            userId: window.$javaCall.data.userId
        };
        const response = await DiscoverService.clearAnnotation(call, window.$javaCall.data.gqApiKey, history);
        if (response && response.status === 'success') { // Waiting for the response to avoid fetching old annotations
            closeContinueModal(resInfo);
        }
    }

    async function getStats(statsApi) {
        let javaCall = {
            data: {
                workflowId: resid.match(/\d+/)[0],
                userId: window.$javaCall.data.userId,
            }
        };
        applyFilterForJava(javaCall);
        return await DiscoverService.getStats(statsApi, javaCall.data, window.$javaCall.data.gqApiKey);
    }

    let drawCharts = 0;
    async function loadStatistic(resInfo) {
        facetInfo = {};
        // Run in parallel to improve the performance
        const [groupStats, resultCharts, facetStats, multiQuerySeq] = await Promise.all(
            [getStats('groupViewStats'), getStats('resultCharts'), getStats('facetStats'), getStats('multiQuerySeqV3')]
        );

        if (groupStats) {
            let newGroupCount = {};
            newGroupCount["RESULT_ID"] = groupStats.alignmentCount.replace("\n", "");
            newGroupCount["SUBJECT_PB"] = groupStats.familyCount.replace("\n", "");
            newGroupCount["SUBJECT_PJ"] = groupStats.ufsCount.replace("\n", "");
            newGroupCount["SUBJECT_PN"] = groupStats.patentNumCount.replace("\n", "");
            newGroupCount["SUBJECT_ID"] = groupStats.ssvNumCount.replace("\n", "");
            newGroupCount["SUBJECT_RG"] = groupStats.casRegNumCount.replace("\n", "");
            newGroupCount["QUERY_ID"] = groupStats.qsNumCount ? groupStats.qsNumCount.replace("\n", "") : undefined;
            newGroupCount["HSP"] = groupStats.hspNumCount ? groupStats.hspNumCount.replace("\n", ""): undefined;

            let stat = {};
            stat["count"] = newGroupCount;
            setStats(stat);
            if (data === undefined) {
                data = {};
            }
            if (data.stats === undefined || !data.stats) {
                data.stats = [];
            }
            data.stats.count = newGroupCount;
            setViewCount(newGroupCount);
        }

        if (resultCharts) {
            let res = fieldConfig.resultChartsConverter(resultCharts);
            Object.keys(res).forEach(function (key) {
                facetInfo[key] = res[key];
            });
            
            drawCharts++;

            if (drawCharts === 2) {
                setFacetInfo({...facetInfo});
                ChartService.facet = _.cloneDeep(facetInfo);
                //$timeout(function () {
                    ChartService.drawNow(facetInfo);
               // }, 100);
                drawCharts = 0;
            }
        }

        if (facetStats) {
            resInfo = {...resInfo};
            resInfo["databases"] = facetStats.databases;
            setResInfo(resInfo);
            setDatabases(prepDatabases(resInfo));

            // console.log(response);
            let res = fieldConfig.facetConverter(facetStats);
            Object.keys(res).forEach(function (key) {
                facetInfo[key] = res[key];
            });
            setQuerySeqIds(facetStats.queryInfo);
            setQuerySeqIdsCount(Object.keys(facetStats.queryInfo).length);
            for (let qi in facetStats.queryInfo) {
                // Disable the UFS/CAS group options for FT workflows
                if (facetStats.queryInfo[qi]['seq'] === 'A') {
                    setGroupOptions(['family', 'document', 'flat']);
                    break;
                }
            }
            drawCharts++;

            if (drawCharts === 2) {
                setFacetInfo({...facetInfo});
                ChartService.facet = _.cloneDeep(facetInfo);
                //$timeout(function () {
                    ChartService.drawNow(facetInfo);
               // }, 100);
                drawCharts = 0;
            }

            filter.state.querySeqMap = {};
            let sequenceId = {};
            Object.keys(facetStats.queryInfo).forEach(function (key) {
                sequenceId[key] = facetStats.queryInfo[key].id;
                filter.state.querySeqMap[facetStats.queryInfo[key].id] = key;
            });
            querydb["SEQUENCE_ID"] = sequenceId;
            querydb["SEQUENCE_ID_COUNT"] = facetStats.queryInfo;
            querydb["count"] = Object.keys(facetStats.queryInfo).length;
            setQuerydb(querydb);
            // $scope.querySeqIdsCount = Object.keys(response.queryDb).length;
            if (hasLvaAccess) {
                let lvaQueries = {};
                Object.keys(facetStats.queryInfo).forEach(function (key) {
                    let lvaQuery = {};
                    lvaQuery["QUERY_ID"] = facetStats.queryInfo[key].id;
                    lvaQuery["QUERY_L"] = facetStats.queryInfo[key].queryLen;
                    lvaQuery["QUERY_N"] = facetStats.queryInfo[key].queryN;
                    lvaQuery["RESULTS"] = facetStats.queryInfo[key].count;
                    lvaQuery["RESULT_NFQ"] = facetStats.queryInfo[key].resultNfq;
                    lvaQuery["SUBJECT_T"] = facetStats.queryInfo[key].subjectType;
                    lvaQueries[key] = lvaQuery;
                });
                setVmIds(lvaQueries);
                //console.log(lvaQueries);
                filter.lvaQueries = lvaQueries;
                if (Object.keys(filter.lvaQueries).length === 1) {
                    filter.defaultLvaQuery = filter.lvaQueries[Object.keys(filter.lvaQueries)[0]];
                } else {
                    filter.defaultLvaQuery = null;
                }
                loadingLvaTables = false;
            }
        }

        if (multiQuerySeq) {
            setQueryDbStats(multiQuerySeq.queryDbStats);
            if (hasLvaAccess) {
                setLvaLoadingReady(true);
                if (multiQuerySeq.error !== null) {
                    // Important check to see if it is a VM workflow
                    setLvaLoadingReady(false);
                }
            }  else {
                // No LVA access
                filter.lvaQueries = {};
                filter.defaultLvaQuery = null;
                setLvaLoadingReady(true);
                loadingLvaTables = false;
            }
        }
    }

    async function fetchResultsNow(applied) {
        if (!showLoader) {
            setShowLoader(true);
        }
        data = {};
        let groupCriteria;
        let pnsPerPage = '';
        let alignmentsPerPage = '';
        let workflowId = resid.match(/\d+/)[0];
        switch (fieldConfig.tableDisplayMode) {
            case 'family':
                groupCriteria = 'fam';
                pnsPerPage = fieldConfig.famViewOption;
                alignmentsPerPage = fieldConfig.famViewAliOption;
                break;
            case 'document':
                groupCriteria = 'patent';
                alignmentsPerPage = fieldConfig.pnViewOption;
                break;
            case 'flat':
                groupCriteria = 'alignment';
                break;
            case 'ufs':
                groupCriteria = 'ufs';
                alignmentsPerPage = fieldConfig.ufsOption;
                break;
            case 'cas':
                groupCriteria = 'cas';
                break;
            case 'ssv':
                groupCriteria = 'ssv';
                alignmentsPerPage = fieldConfig.ssvOption;
                break;
            case 'qs':
                groupCriteria = 'qs';
                alignmentsPerPage = fieldConfig.qsOption;
                break;
            case 'hsp':
                groupCriteria = 'hsp';
                alignmentsPerPage = fieldConfig.hspOption;
                break;
            case 'ali':
            case 'gname':
            case 'org':
            case 'npa':
                groupCriteria = fieldConfig.tableDisplayMode;
                alignmentsPerPage = fieldConfig.groupOption;
                break;
            default:
                break;
        }

        let call = {
            data: {
                workflowId: workflowId,
                userId: userdir ? userdir : window.$javaCall.data.userId,
                groupCriteria: groupCriteria,
                pnsPerPage: pnsPerPage,
                alignmentsPerPage: alignmentsPerPage,
                sortCriteria: filter.state.sort,
                sortCriterias: fieldConfig.populateSortCriterias(filter.state.sort),
                pageNum: state.currentPage,
                groupSize: fieldConfig.groupSize,
                groupSizeOper: fieldConfig.groupSizeOper,
           }
        };

        applyFilterForJava(call, applied);
        setAbFilters(previewABReport());
        setResultReportFilters(call.data);
        let response = await DiscoverService.getSummary(call.data, window.$javaCall.data.gqApiKey);
        if (response) {
            let newData = fieldConfig.javaObjectConverter(response, groupCriteria, querydb.SEQUENCE_ID, fieldConfig);
            data.data = newData.data;
            if (!data.stats) {
                data.stats = [];
            }
            data.stats.nav = newData.stats.nav;
            if (!data.facet) {
                data.facet = {};
            }

            state.showAll = false; // first render only the first 3 members of last group level
            //self.table_key = response.table_key; // record the hash key that tells when to refresh the cache

            // restores checkboxes, colors, stars
            annotState.checkboxes = {};
            annotState.colors = {};
            annotState.stars = {};
            annotState.notes = {};
            annotState.dbkeys = {};
            annotState.showColorBoxes = {};
            annotState.dbkeysMap = {};
            if (newData.checkboxes) {
                annotState.checkboxes = newData.checkboxes;
            }
            if (newData.colors) {
                annotState.colors = newData.colors;
            }
            if (newData.stars) {
                annotState.stars = newData.stars;
            }
            if (newData.notes) {
                annotState.notes = newData.notes;
            }

            if (data.data !== undefined && data.data !== null) {
                prepClaimsCollapse(data, fieldConfig.tableDisplayMode, claimsCollapseAll);
                prepAlignmentsCollapse(data, fieldConfig.tableDisplayMode, alignmentsCollapseAll);
                prepDetailPageCollapse(data, fieldConfig.tableDisplayMode, detailPageCollapseAllLevelOne,
                    detailPageCollapseAllLevelTwo, detailPageCollapseAllLevelThree, annotState);
            }

            // Populate dbkeys
            if (fieldConfig.tableDisplayMode === 'family' || fieldConfig.tableDisplayMode === 'ufs') {
                for (let idx in data.data) {
                    annotState.dbkeysMap[data.data[idx]["dbkey"]] = [];
                    for (let sub in data.data[idx]["nodes"]) {
                        for (let subIdx in data.data[idx]["nodes"][sub]["nodes"]) {
                            annotState.dbkeys[data.data[idx]["nodes"][sub]["nodes"][subIdx]["dbkey"]] = data.data[idx]["nodes"][sub]["nodes"][subIdx]["RESULT_ID"];
                            annotState.dbkeysMap[data.data[idx]["dbkey"]].push(data.data[idx]["nodes"][sub]["nodes"][subIdx]["RESULT_ID"]);
                        }
                    }
                }
            } else if (fieldConfig.tableDisplayMode === 'document' || fieldConfig.tableDisplayMode === 'cas' || fieldConfig.tableDisplayMode === 'ssv'
                || fieldConfig.tableDisplayMode === 'qs' || fieldConfig.tableDisplayMode === 'hsp'
                || fieldConfig.tableDisplayMode === 'ali' || fieldConfig.tableDisplayMode === 'npa'
                || fieldConfig.tableDisplayMode === 'gname' || fieldConfig.tableDisplayMode === 'org') {
                for (let idx in data.data) {
                    annotState.dbkeysMap[data.data[idx]["dbkey"]] = [];
                    for (let sub in data.data[idx]["nodes"]) {
                        annotState.dbkeys[data.data[idx]["nodes"][sub]['dbkey']] = data.data[idx]["nodes"][sub]["RESULT_ID"];
                        annotState.dbkeysMap[data.data[idx]["dbkey"]].push(data.data[idx]["nodes"][sub]["RESULT_ID"]);
                    }
                }
            } else {
                for (var idx  in data.data) {
                    annotState.dbkeys[data.data[idx]['dbkey']] = data.data[idx]["RESULT_ID"];
                }
            }

            // find out which display modes are supported for this data set by analyzing which subject databases there are
            setSupportedDisplayModes(getSupportedDisplayModes(resInfo));
            //setalgorithm(resInfo.algorithm);
            // copy the current display mode here, so the UI knows what to display
            //$scope.table.setTableDisplayMode(fieldConfig.tableDisplayMode);
            if (!state.title) {
                state.title = resInfo.title; // set the title for the result set
            }
            state.fileName = _.replace(state.title, new RegExp('/[^\-_a-zA-Z0-9\.]+/', 'g'), '_');
            state.userdir = call.data.userId;

            //self.prepNavBar(); // prepare the result pagination bar
            //$scope.table.prepRowIdxToRow($scope.data.data); // link objects (table rows) displayed to a simple index (0,1,2, ... N) so that the UI can link a table row number to a specific object (PN, family, alignment, UFS, ...)
            //$scope.table.selectFirstRow(); // select the first item displayed

            if (filter.widgetState.defaultFilterAdded === null || filter.widgetState.defaultFilterAdded !== true
                || (isAbWorkflow && filter.widgetState.defaultCustomFilters.length <= 3)) {
                filter.addDefaultCustomFilters(filter, fieldConfig);
            }
            setWidgetState(filter.widgetState);

            gqAlignments = [];
            gqAlignmentsNoSumm = [];
            gqAlignmentsStrNoSumm = [];
            gqAlignmentsSumm = [];
            gqAlignmentsVariation = [];
            populateGqAlignments(filter, data.data, gqAlignments, gqAlignmentsNoSumm, gqAlignmentsStrNoSumm, gqAlignmentsSumm, gqAlignmentsVariation);

            // For the table display
            setTotalPage(response.lastPage);
            data.columns = fieldConfig.userTableTemplate['flat'];
            data.displayMode = fieldConfig.tableDisplayMode;
            data.tableWidth = fieldConfig.estimateTableWidth(fieldConfig.userTableTemplate, fieldConfig.tableDisplayMode);
            annotState.claimsCollapse = claimsCollapse;
            annotState.claimsCollapseLess = {};
            annotState.alignmentsCollapse = alignmentsCollapse;
            data.annotState = annotState;
            data.gqAlignments = gqAlignments;
            data.gqAlignmentsNoSumm = gqAlignmentsNoSumm;
            data.gqAlignmentsStrNoSumm = gqAlignmentsStrNoSumm;
            data.gqAlignmentsSumm = gqAlignmentsSumm;
            data.gqAlignmentsVariation = gqAlignmentsVariation;
            data.docItems = docItems;
            data.docItemsSelected = docItemsSelected;
            data.algorithm = resInfo.algorithm;
            setAnnotState({...annotState});
            setSearchResultData(data);
            window.scrollTo({top: 0});
            setShowLoader(false);

            //$scope.firstTimeInitial = 0;

            // we're done reacting stop waiting for results animation
            //$scope.showSpinner = false;

            // after rendering initial 3 results in the last group level, show them all
            //$timeout(function () {
                state.showAll = true;
            //}, 100);
            //self.startTour();
        }
    }

    function applyFilterForJava(javaCall, applied) {
        let query;
        if (applied) {
            query = filter.returnQuery();
        } else {
            Object.assign(filterState, filter.state);
            let fs = {...filterState};
            query = filter.getQuery(fs, filter);
        }
        let filterTextsTrue = {};
        let filterTextsFalse = {};

        if (alerts && (alerts === 'alert' || alerts === 'alert/' || alerts === 'alert/1'
            || alerts === 'alertChanged' || alerts === 'alertChanged/' || alerts === 'alertChanged/1')) {
            if (alerts === 'alert' || alerts === 'alert/' || alerts === 'alert/1') {
                javaCall.data["fromAlert"] = true;
            } else {
                javaCall.data["fromAlertChanged"] = true;
            }
        } else if (filter.state.vmExp !== undefined && filter.state.vmExp !== null) {
            javaCall.data["vmExp"] = vmExp;
            javaCall.data["vmQueryId"] = vmQueryId;
        }

        javaCall.data["gqUserId"] = userInfo.current_user?.gq_user_id;
        if (filter.state.reportId !== undefined && filter.state.reportId !== null) {
            javaCall.data["oldCollName"] = reportId;
        }

        if (filter.state.singleAlignmentId !== undefined && filter.state.singleAlignmentId !== null) {
            javaCall.data["singleAlignmentId"] = singleAlignmentId;
        } else if (filter.state.abExp !== undefined && filter.state.abExp !== null) {
            javaCall.data["abExp"] = query.abExp;
        } else {
            Object.keys(query).forEach(function (key) {
                if (key === "SUBJECT_PG" && query[key].length > 0) {
                    javaCall.data["legalStatuses"] = query[key];
                } else if (key === "SUBJECT_PN" && query[key].length > 0) {
                    let authorities = [];
                    query[key].forEach(function (auth) {
                        if (auth === "_empty_") {
                            authorities.push("");
                        } else {
                            authorities.push(auth);
                        }
                    });
                    javaCall.data["authorities"] = authorities;
                } else if (key === "databaseNames" && query[key].length > 0) {
                    let databases = [];
                    Object.keys(query[key]).forEach(function (key) {
                        databases.push(key);
                    });
                    javaCall.data["databases"] = databases;
                } else if (key === "SUBJECT_PS" && query[key].length > 0) {
                    javaCall.data["seqLocations"] = query[key];
                } else if (key === "querySeqDbs" && Object.keys(query[key]).length > 0) {
                    javaCall.data["querySeqDbs"] = Object.keys(query[key]);
                    javaCall.data["queryNums"] = [];
                    /*
                    if (filter.state.querySeqMap) {
                        Object.keys(query[key]).forEach(function (val) {
                            javaCall.data["queryNums"].push(filter.state.querySeqMap[val]);
                        });
                    }*/
                } else if (key === "databaseNames" && Object.keys(query[key]).length > 0) {
                    javaCall.data["databases"] = Object.keys(query[key]);
                } else if (key === "publicationNumberList" && query[key].length > 0) {
                    javaCall.data["publicationNumbers"] = query[key];
                } else if (key === "customFilters" && query[key].length > 0) {
                    let newFilters = [];
                    let customFilters = query[key];
                    for (let i in customFilters) {
                        let newFilter = {};
                        let filter = customFilters[i];
                        newFilter["filterName"] = filter.customFilterName;
                        newFilter["filterMode"] = filter.widgetMode;
                        newFilter["filterCustomQuery"] = filter.widgetCustomQuery;
                        let widgetContent = filter["widgetContent"];
                        let criterias = [];
                        for (let j in widgetContent) {
                            let content = widgetContent[j];
                            let newContent = {};
                            newContent["fieldName"] = content.field;
                            newContent["fieldType"] = content.field_type;
                            newContent["fieldOperator"] = content.operator;
                            newContent["fieldValue"] = content.value;
                            newContent["fieldSecondValue"] = content.second_value;
                            criterias.push(newContent);
                        }
                        newFilter["fieldCriterias"] = criterias;
                        newFilters.push(newFilter);
                    }
                    javaCall.data["customFilters"] = newFilters;
                } else if (key === "lvaFilters" && query[key].length > 0) {
                    //console.log(query[key]);
                    let newVmFilters = [];
                    let vmFilters = query[key];
                    for (let i in vmFilters) {
                        let newFilter = {};
                        let filter = vmFilters[i];
                        let lvaQuery = filter.lvaQuery;
                        let widget = filter.widgetContent;
                        newFilter["filterName"] = filter.lvaFilterName;
                        newFilter["queryId"] = lvaQuery.QUERY_ID;
                        let vmCriteria = [];
                        for (let j in widget) {
                            let newCriteria = {};
                            let criteria = widget[j];
                            // when start === stop, don't pass as exactPosition to avoid backend logic issue
                            if (criteria.start !== undefined && criteria.start !== "" &&
                                criteria.stop !== undefined && criteria.stop !== "" && criteria.start === criteria.stop) {
                                newCriteria["exactPosition"] = criteria.start;
                                newCriteria["residue"] = [""];
                            } else {
                                if (criteria.start !== undefined && criteria.start !== "") {
                                    newCriteria["rangeStart"] = criteria.start;
                                }
                                if (criteria.stop !== undefined && criteria.stop !== "") {
                                    newCriteria["rangeEnd"] = criteria.stop;
                                }
                            }
                            let newEventType = [];
                            let events = criteria.events;
                            if (events.length > 0) {
                                for (let k in events) {
                                    newEventType.push(events[k].event);
                                }
                            } else {
                                if (criteria.method === "replacements") {
                                    newEventType.push("R");
                                    newCriteria["residue"] = criteria.replacements.toUpperCase().replace(/ /g, '').replace(/,/g, "\n").replace(/;/g, "\n").split('\n');
                                } else if (criteria.method === "specinserts") {
                                    newEventType.push("I");
                                    newCriteria["residue"] = criteria.inserts.toUpperCase().replace(/ /g, '').replace(/,/g, "\n").replace(/;/g, "\n").split('\n');
                                } else if (criteria.method === "anyinsert") {
                                    newEventType.push("I");
                                    newCriteria["residue"] = [""];
                                } else if (criteria.method === "deletions" && criteria.text_label === "Deletion present beginning at") {
                                    newEventType.push("D");
                                    newCriteria["minResidueLength"] = criteria.mindel ? criteria.mindel : 1;
                                    newCriteria["residueLength"] = criteria.maxdel;
                                    newCriteria["exactPosition"] = criteria.start;
                                    delete newCriteria["rangeStart"];
                                    delete newCriteria["rangeEnd"];
                                } else if (criteria.method === "deletions" && criteria.text_label === "No deletion beginning at") {
                                    newEventType.push("D");
                                    newCriteria["minResidueLength"] = criteria.mindel ? criteria.mindel : 1;
                                    newCriteria["residueLength"] = criteria.maxdel;
                                    newCriteria["exactPosition"] = criteria.start;
                                    delete newCriteria["rangeStart"];
                                    delete newCriteria["rangeEnd"];
                                } else if (criteria.method === "percIdentity") {
                                    newEventType.push("M");
                                } else if (criteria.method === "maxDiff") {
                                    newEventType.push("M");
                                }
                            }
                            if (criteria.method === "percIdentity") {
                                newCriteria["percIdentity"] = criteria.percIdentity;
                            } else if (criteria.method === "maxDiff") {
                                newCriteria["maxDiffLength"] = criteria.maxDiff;
                            }
                            newCriteria["eventType"] = newEventType;
                            if (criteria.text_label.includes("absent") || criteria.text_label === "No insertion" || criteria.text_label === "No deletion" || criteria.text_label === "No deletion beginning at") {
                                newCriteria["eventMatch"] = "absent";
                            } else if (criteria.text_label.includes("present") || criteria.method === "percIdentity" || criteria.method === "maxDiff") {
                                newCriteria["eventMatch"] = "present";
                            }
                            vmCriteria.push(newCriteria);
                        }
                        newFilter["vmCriteria"] = vmCriteria;
                        newFilter["filterMode"] = filter.widgetMode;
                        newFilter["filterCustomQuery"] = filter.widgetCustomQuery;
                        newVmFilters.push(newFilter);
                    }
                    //console.log(newVmFilters);
                    javaCall.data["vmFilters"] = newVmFilters;

                    // Pass below param to avoid splitting VM ranges in backend
                    javaCall.data["isAbWorkflow"] = isAbWorkflow;
                } else if (key === "annot" && query[key] && Object.keys(query[key]).length > 0) {
                    let i;
                    let checkboxes = [];
                    for (i in query[key].checkboxes) {
                        if (query[key].checkboxes[i]) {
                            if (i === "checked") {
                                checkboxes.push("1");
                            } else if (i === "unchecked") {
                                checkboxes.push("0");
                            }
                        }
                    }

                    let stars = [];
                    for (i in query[key].stars) {
                        if (query[key].stars[i]) {
                            stars.push(i);
                        }
                    }

                    let colors = [];
                    for (i in query[key].colors) {
                        if (query[key].colors[i]) {
                            colors.push(i);
                        }
                    }

                    javaCall.data["checkboxes"] = checkboxes;
                    javaCall.data["stars"] = stars;
                    javaCall.data["colors"] = colors;
                    javaCall.data["notes"] = query.annot?.notes;
                } else if (key === "smartText" && Object.keys(query[key]).length > 0) {
                    filterTextsTrue = query[key];
                } else if (key === "smartTextNegate" && Object.keys(query[key]).length > 0) {
                    filterTextsFalse = query[key];
                }
            });
            javaCall.data["deduplication"] = filter.state.deduplication;
            javaCall.data["filterTextsTrue"] = filterTextsTrue;
            javaCall.data["filterTextsFalse"] = filterTextsFalse;

            if (filter.state.deduplication) {
                javaCall.data["dedDbPrefs"] = dedDatabasePrefs;
            }

            if (groupQuery && groupQuery.GROUP_BY) {
                javaCall.data["analysisBy"] = groupQuery.GROUP_BY;
                javaCall.data["analysisNum"] = groupQuery.NBVALS;
                javaCall.data["analysisTotal"] = querydb.count;
            }
        }

        if(!_.isEmpty(window.reportFilters)) {
            let reportABFilters = window.reportFilters;
            if (reportABFilters !== undefined && reportABFilters !== null && reportABFilters !='') {
                javaCall.data = extend(javaCall.data, reportABFilters);
            }
        }
    }

    function previewABReport() {
        
        var filters = {
            data: getCurrentPageFilters()
        };

        applyFilterForJava(filters);

        return filters;
    }


    function getCurrentPageFilters() {
        let groupCriteria;
        let pnsPerPage = '';
        let alignmentsPerPage = '';
        let workflowId = resid.match(/\d+/)[0];
        switch (fieldConfig.tableDisplayMode) {
            case 'family':
                groupCriteria = 'fam';
                if (fieldConfig.applyEsf) {
                    pnsPerPage = fieldConfig.famViewOption;
                    alignmentsPerPage = fieldConfig.famViewAliOption;
                }
                break;
            case 'document':
                groupCriteria = 'patent';
                if (fieldConfig.applyEsf) {
                    alignmentsPerPage = fieldConfig.pnViewOption;
                }
                break;
            case 'flat':
                groupCriteria = 'alignment';
                break;
            case 'ufs':
                groupCriteria = 'ufs';
                alignmentsPerPage = fieldConfig.ufsOption;
                break;
            case 'cas':
                groupCriteria = 'cas';
                break;
            case 'ssv':
                groupCriteria = 'ssv';
                if (fieldConfig.applyEsf) {
                    alignmentsPerPage = fieldConfig.ssvOption;
                }
                break;
        }

        let data = {
            workflowId: workflowId, // database ID ("GQ-classic" compatible)
            userId: userdir ? userdir : window.$javaCall.data.userId,
            groupCriteria: groupCriteria,
            pnsPerPage: pnsPerPage,
            alignmentsPerPage: alignmentsPerPage,
            sortCriteria: filter.state.sort,
            sortCriterias: fieldConfig.populateSortCriterias(filter.state.sort),
            pageNum: currentPage
        };

        return data;

    }

    async function callGQApi() {
        let api = await DiscoverService.getApi(resid, history);
        if (api && api.response_content) {
            if (api.response_content.type === 'GQEXCEPTION') {
                alert(api.response_content.message);
                return;
            }

            window.$javaCall.data.gqApiKey = api.response_content.gqApiKey;
            setGqApiKey(api.response_content.gqApiKey);
        }
    }

    function popupInfoMsg(msg) {
        if (msg) {
            setInfoMsg(msg);
            setShowInfoMsg(true);
        }
    }

    function dismissInfoMsg() {
        setInfoMsg('');
        setShowInfoMsg(false);
    }

    function extend(dest, src) {
        for (let key in src) {
            dest[key] = src[key];
        }
        return dest;
    }

    function prepClaimsCollapse(data, tableDisplayMode, collapse) {
        let currentValue;
        if (claimsCollapse === undefined) {
            claimsCollapse = {};
        }
        if (collapse !== undefined) {
            currentValue = collapse;
        } else {
            currentValue = claimsCollapseAll ? false : true;
        }
        claimsCollapseAll = currentValue;
        if (tableDisplayMode === 'family' || tableDisplayMode === 'cas' || tableDisplayMode === 'ssv'
            || tableDisplayMode === 'qs' || tableDisplayMode === 'hsp' || tableDisplayMode === 'org'
            || tableDisplayMode === 'ali' || tableDisplayMode === 'npa' || tableDisplayMode === 'gname') {
            // Special handling for CAS display mode here, it is in the alginment level, so treat it same as family mode
            for (let idx in data.data) {
                for (let sub in data.data[idx]["nodes"]) {
                    if (data.data[idx]["nodes"][sub]['dbkey'] && claimsCollapse[data.data[idx]["nodes"][sub]['dbkey']] === undefined) {
                        claimsCollapse[data.data[idx]["nodes"][sub]['dbkey']] = currentValue;
                    }
                }
            }
        } else if (tableDisplayMode === 'ufs') {
            for (let idx in data.data) {
                for (let sub in data.data[idx]["nodes"]) {
                    for (let subIdx in data.data[idx]["nodes"][sub]["nodes"]) {
                        if (data.data[idx]["nodes"][sub]["nodes"][subIdx]["dbkey"]
                            && claimsCollapse[data.data[idx]["nodes"][sub]["nodes"][subIdx]['dbkey']] === undefined) {
                            claimsCollapse[data.data[idx]["nodes"][sub]["nodes"][subIdx]["dbkey"]] = currentValue;
                        }
                    }
                }
            }
        } else {
            for (let idx  in data.data) {
                let item = data.data[idx];
                //if (item.SUBJECT_CT !== undefined && item.SUBJECT_CT !== null && item.SUBJECT_CT.length > 0) {
                if (claimsCollapse[item['dbkey']] === undefined) {
                    // _.set(claimsCollapse, item['dbkey'], currentValue);
                    claimsCollapse[item['dbkey']] = currentValue;
                }
                //}
            }
        }
    }

    function prepAlignmentsCollapse(data, tableDisplayMode, collapse) {
        var currentValue = false;
        if (alignmentsCollapse === undefined) {
            alignmentsCollapse = {};
        }
        if (collapse !== undefined) {
            currentValue = collapse;
        } else if (alignmentsCollapseAll !== undefined) {
            currentValue = alignmentsCollapseAll ? false : true;
        }
        alignmentsCollapseAll = currentValue;
        if (tableDisplayMode === 'family' || tableDisplayMode === 'ufs') {
            for (let idx in data.data) {
                for (let sub in data.data[idx]["nodes"]) {
                    for (let subIdx in data.data[idx]["nodes"][sub]["nodes"]) {
                        if (data.data[idx]["nodes"][sub]["nodes"][subIdx]["dbkey"]
                            && alignmentsCollapse[data.data[idx]["nodes"][sub]["nodes"][subIdx]["dbkey"]] === undefined) {
                            alignmentsCollapse[data.data[idx]["nodes"][sub]["nodes"][subIdx]["dbkey"]] = currentValue;
                        }
                    }
                }
            }
        } else if (tableDisplayMode === 'document' || tableDisplayMode === 'cas' || tableDisplayMode === 'ssv'
            || tableDisplayMode === 'qs' || tableDisplayMode === 'hsp' || tableDisplayMode === 'org'
            || tableDisplayMode === 'ali' || tableDisplayMode === 'npa' || tableDisplayMode === 'gname') {
            for (let idx in data.data) {
                for (let sub in data.data[idx]["nodes"]) {
                    if (data.data[idx]["nodes"][sub]['dbkey'] && alignmentsCollapse[data.data[idx]["nodes"][sub]['dbkey']] === undefined) {
                        alignmentsCollapse[data.data[idx]["nodes"][sub]['dbkey']] = currentValue;
                    }
                }
            }
        } else {
            for (let idx in data.data) {
                let item = data.data[idx];
                if (item.RESULT_FALI_FORMATTED !== undefined && item.RESULT_FALI_FORMATTED !== null && item.RESULT_FALI_FORMATTED.length > 0) {
                    if (alignmentsCollapse[item['dbkey']] === undefined) {
                        alignmentsCollapse[item['dbkey']] = currentValue;
                    }
                }
            }
        }
    }

    function prepDetailPageCollapse(data, tableDisplayMode, collapseLevelOne, collapseLevelTwo, collapseLevelThree, anState) {
        let currentValueLevelOne;
        let currentValueLevelTwo;
        let currentValueLevelThree;
        if (collapseLevelOne !== undefined) {
            currentValueLevelOne = collapseLevelOne;
        } else {
            currentValueLevelOne = detailPageCollapseAllLevelOne ? true : false;
        }
        if (collapseLevelTwo !== undefined) {
            currentValueLevelTwo = collapseLevelTwo;
        } else {
            currentValueLevelTwo = detailPageCollapseAllLevelTwo ? true : false;
        }
        if (collapseLevelThree !== undefined) {
            currentValueLevelThree = collapseLevelThree;
        } else {
            currentValueLevelThree = detailPageCollapseAllLevelThree ? true : false;
        }
        anState.fullRecordExpand = {};
        detailPageCollapseAllLevelOne = currentValueLevelOne;
        detailPageCollapseAllLevelTwo = currentValueLevelTwo;
        detailPageCollapseAllLevelThree = currentValueLevelThree;

        if (tableDisplayMode === 'family' || tableDisplayMode === 'ufs') {
            for (let idx in data.data) {
                if (data.data[idx]['dbkey']) {
                    _.set(anState.fullRecordExpand, data.data[idx]['dbkey'], currentValueLevelOne);
                }
                for (var sub in data.data[idx]["nodes"]) {
                    if (data.data[idx]["nodes"][sub]['dbkey']) {
                        _.set(anState.fullRecordExpand, data.data[idx]["nodes"][sub]['dbkey'], currentValueLevelTwo);
                    }
                    for (var sub2 in data.data[idx]["nodes"][sub]['nodes']) {
                        if (data.data[idx]["nodes"][sub]['nodes'][sub2]['dbkey']) {
                            _.set(anState.fullRecordExpand, data.data[idx]["nodes"][sub]['nodes'][sub2]['dbkey'], currentValueLevelThree);
                        }
                    }
                }
            }
        } else if (tableDisplayMode === 'document' || tableDisplayMode === 'cas' || tableDisplayMode === 'ssv'
            || tableDisplayMode === 'qs' || tableDisplayMode === 'hsp' || tableDisplayMode === 'org'
            || tableDisplayMode === 'ali' || tableDisplayMode === 'npa' || tableDisplayMode === 'gname') {
            for (let idx in data.data) {
                if (data.data[idx]['dbkey']) {
                    _.set(anState.fullRecordExpand, data.data[idx]['dbkey'], currentValueLevelOne);
                }
                for (let sub in data.data[idx]["nodes"]) {
                    if (data.data[idx]["nodes"][sub]['dbkey']) {
                        _.set(anState.fullRecordExpand, data.data[idx]["nodes"][sub]['dbkey'], currentValueLevelTwo);
                    }
                }
            }
        } else {
            for (let idx in data.data) {
                _.set(anState.fullRecordExpand, data.data[idx]['dbkey'], currentValueLevelOne);
            }
        }
    }

    function getSupportedDisplayModes(resInfo) {
        let isGqPat = false,
            isGeneSeq = false,
            isOther = false,
            i, hasGqPat = false,
            hasGeneSeq = false,
            hasOther = false,
            supported;
        if (resInfo && resInfo.databases) {
            for (i = 0; resInfo.databases && i < resInfo.databases.length; i++) {
                isGqPat = resInfo.databases[i].indexOf("GQPAT") === 0;
                isGeneSeq = resInfo.databases[i].indexOf("GEAA") === 0 || resInfo.databases[i].indexOf("GENA") === 0;
                isOther = !(isGqPat || isGeneSeq);
                hasGqPat = hasGqPat || isGqPat;
                hasGeneSeq = hasGeneSeq || isGeneSeq;
                hasOther = hasOther || isOther;
            }
        }
        if (hasGqPat && !hasGeneSeq && !hasOther) {
            // every database is a GQ-Pat database
            supported = {family: true, document: true, ufs: true, flat: true, cas: true, default: 'document'};
        } else if ((hasGqPat || hasGeneSeq) && !hasOther) {
            // this is either a mix of only GeneSeq and GQ-Pat or is only GeneSeq
            supported = {family: true, document: true, ufs: true, flat: true, cas: true, default: 'document'};
        } else {
            // there is at least one non patent database in the mix
            // Bug 132986, changed to all ture for virtual databases
            // supported = {family: false, document: false, ufs: false, flat: false, default: 'flat'};
            supported = {family: true, document: true, ufs: true, flat: true, cas: true, default: 'flat'};
        }
        return supported;
    }

    function populateGqAlignments(filter, data, gqAlignments, gqAlignmentsNoSumm, gqAlignmentsStrNoSumm, gqAlignmentsSumm, gqAlignmentsVariation) {
        let rowIdx, item, alignment, node0, node1;
        for (rowIdx in data) {
            item = data[rowIdx];
            initDocItems(item);

            if (item.hasOwnProperty('RESULT_FALI_FORMATTED')) {
                alignment = colorAlignment(filter, item['RESULT_FALI_FORMATTED'], filter.extractLvaHintInfo(item), item, gqAlignmentsNoSumm, gqAlignmentsStrNoSumm, gqAlignmentsSumm, gqAlignmentsVariation);
                gqAlignments[item['dbkey']] = alignment; //$sce.trustAsHtml(alignment);
            } else {
                for (let nodeIdx in item.nodes) {
                    node0 = item.nodes[nodeIdx];
                    initDocItems(node0);
                    if (node0.hasOwnProperty('RESULT_FALI_FORMATTED')) {
                        alignment = colorAlignment(filter, node0['RESULT_FALI_FORMATTED'], filter.extractLvaHintInfo(node0), node0, gqAlignmentsNoSumm, gqAlignmentsStrNoSumm, gqAlignmentsSumm, gqAlignmentsVariation);
                        gqAlignments[node0['dbkey']] = alignment; //$sce.trustAsHtml(alignment);
                    } else {
                        for (let nodeIdx1 in node0.nodes) {
                            node1 = node0.nodes[nodeIdx1];
                            initDocItems(node1);
                            if (node1.hasOwnProperty('RESULT_FALI_FORMATTED')) {
                                alignment = colorAlignment(filter, node1['RESULT_FALI_FORMATTED'], filter.extractLvaHintInfo(node1), node1, gqAlignmentsNoSumm, gqAlignmentsStrNoSumm, gqAlignmentsSumm, gqAlignmentsVariation);
                                gqAlignments[node1['dbkey']] = alignment; //$sce.trustAsHtml(alignment);
                            }
                        }
                    }
                }
            }
        }
    }

    function colorAlignment(filter, alignment, seqData, item, gqAlignmentsNoSumm, gqAlignmentsStrNoSumm, gqAlignmentsSumm, gqAlignmentsVariation) {
        let hints = filter.getLvaAlignmentHints(filter, seqData);

        // break alignment into lines using newline characters
        let i,
            nextLine = false,
            match = false,
            lines = _.split(alignment, "\n"),
            line,
            newAlignment = '',
            spacer,
            //direction = seqData.RESULT_ALI_DIR,
            chunkIdx = 0,
            subjectLine = false;

        // lines[0] = '&nbsp;&nbsp;&nbsp;' + direction + lines[0];

        for (i = 0; i < lines.length; i++) {
            line = lines[i];
            if (nextLine && match) {
                // now we're working with the line between query and subject sequence
                spacer = line.substr(0, match); // leading spaces that we don't want to replace

                line = line.substr(match); // the ||||| |||+||||||||||||||||| part of the alignment without leading spaces
                // do the actual replacement of spaces and + signs with colored ones
                line = line.replace(/ +/g, function (hit) {
                    return '<span class="mismatch">' + hit + '</span>'
                });
                line = line.replace(/\++/g, function (hit) {
                    return '<span class="match">' + hit + '</span>'
                });
                // add the leading spaces back in
                line = spacer + line;

                chunkIdx++;
            }
            nextLine = line.indexOf("Q:") === 0; // true when we're on the Query line of the alignment
            if (nextLine) {
                // find out where the query sequence starts
                match = 2 + (/[0-9] /.exec(line)).index;

                // Append the LVA hints before appending the query line
                if (chunkIdx in hints.hintLines) {
                    spacer = "".padStart(match);
                    let lvaLines = "";  //  We need to do them in reverse order, rather than using Array.reverse()

                    // Merge the flags which indicate whether the chunk is replaced/inserted/deleted,
                    // so that we could display extra color/lower case on it
                    let mergedChunkFlags = null;

                    let qChunk = null;
                    for (let level in hints.hintLines[chunkIdx]) {
                        lvaLines = spacer + hints.hintLines[chunkIdx][level].line + "\n" + lvaLines;

                        if (mergedChunkFlags === null) {
                            mergedChunkFlags = hints.hintLines[chunkIdx][level].qChunkFlags;
                        } else {
                            let tmpMergedChunkFlags = null;
                            if (mergedChunkFlags.length >= hints.hintLines[chunkIdx][level].qChunkFlags.length) {
                                tmpMergedChunkFlags = mergedChunkFlags;
                            } else {
                                tmpMergedChunkFlags = hints.hintLines[chunkIdx][level].qChunkFlags;
                            }

                            // Merge the chunk flags from multiple hint lines
                            for (let chunkFlagsIndx in tmpMergedChunkFlags) {
                                let value = tmpMergedChunkFlags[chunkFlagsIndx];
                                let value2 = hints.hintLines[chunkIdx][level].qChunkFlags[chunkFlagsIndx];
                                let value3 = mergedChunkFlags[chunkFlagsIndx];

                                if (value2 && value2 > value) {
                                    tmpMergedChunkFlags[chunkFlagsIndx] = value2;
                                    value = value2;
                                }
                                if (value3 && value3 > value) {
                                    tmpMergedChunkFlags[chunkFlagsIndx] = value3;
                                }
                            }
                            mergedChunkFlags = tmpMergedChunkFlags;
                        }

                        if (qChunk === null) {
                            qChunk = hints.hintLines[chunkIdx][level].qChunk;
                        }
                    }
                    newAlignment += lvaLines;

                    let qAlignmentLine = "";
                    for (let chunkFlagsIndx in mergedChunkFlags) {
                        let value = mergedChunkFlags[chunkFlagsIndx];
                        if (value === 0) {
                            qAlignmentLine += qChunk.charAt(chunkFlagsIndx);
                        } else if (value === 1) {
                            // Background color - gq blue
                            qAlignmentLine += "<span class='lva-marked'>" + qChunk.charAt(chunkFlagsIndx) + "</span>";
                        } else {
                            // Background color - gq blue, and lower case
                            qAlignmentLine += "<span class='lva-marked lva-not-exact-match'>" + qChunk.charAt(chunkFlagsIndx) + "</span>";
                        }
                    }
                    if (mergedChunkFlags.length < qChunk.length) {
                        qAlignmentLine += qChunk.substring(mergedChunkFlags.length, qChunk.length);
                    }
                    line = line.replace(qChunk, qAlignmentLine);
                }
            }
            subjectLine = line.indexOf("S:") === 0;
            if (subjectLine) { // true when we're on the Subject line of the alignment
                let sChunkIdx = chunkIdx - 1;

                // Merge the flags which indicate whether the chunk is replaced/inserted/deleted,
                // so that we could display extra color/lower case on it
                if (sChunkIdx in hints.hintLines) {
                    let mergedChunkFlags = null;
                    let sChunk = null;
                    for (let level in hints.hintLines[sChunkIdx]) {
                        if (mergedChunkFlags === null) {
                            mergedChunkFlags = hints.hintLines[sChunkIdx][level].sChunkFlags;
                        } else {
                            let tmpMergedChunkFlags = null;
                            if (mergedChunkFlags.length >= hints.hintLines[sChunkIdx][level].sChunkFlags.length) {
                                tmpMergedChunkFlags = mergedChunkFlags;
                            } else {
                                tmpMergedChunkFlags = hints.hintLines[sChunkIdx][level].sChunkFlags;
                            }

                            // Merge the chunk flags from multiple hint lines
                            for (let chunkFlagsIndx in tmpMergedChunkFlags) {
                                let value = tmpMergedChunkFlags[chunkFlagsIndx];
                                let value2 = hints.hintLines[sChunkIdx][level].sChunkFlags[chunkFlagsIndx];
                                let value3 = mergedChunkFlags[chunkFlagsIndx];

                                if (value2 && value2 > value) {
                                    tmpMergedChunkFlags[chunkFlagsIndx] = value2;
                                    value = value2;
                                }
                                if (value3 && value3 > value) {
                                    tmpMergedChunkFlags[chunkFlagsIndx] = value3;
                                }
                            }
                            mergedChunkFlags = tmpMergedChunkFlags;
                        }

                        if (sChunk === null) {
                            sChunk = hints.hintLines[sChunkIdx][level].sChunk;
                        }
                    }

                    let sAlignmentLine = "";
                    for (let chunkFlagsIndx in mergedChunkFlags) {
                        let value = mergedChunkFlags[chunkFlagsIndx];
                        if (value === 0) {
                            sAlignmentLine += sChunk.charAt(chunkFlagsIndx);
                        } else if (value === 1) {
                            // Background color - gq blue
                            sAlignmentLine += "<span class='lva-marked'>" + sChunk.charAt(chunkFlagsIndx) + "</span>";
                        } else {
                            // Background color - gq blue, and lower case
                            sAlignmentLine += "<span class='lva-marked lva-not-exact-match'>" + sChunk.charAt(chunkFlagsIndx) + "</span>";
                        }
                    }
                    if (mergedChunkFlags.length < sChunk.length) {
                        sAlignmentLine += sChunk.substring(mergedChunkFlags.length, sChunk.length);
                    }
                    line = line.replace(sChunk, sAlignmentLine);
                }
            }

            // put the alignment back together
            newAlignment += line + "\n";
        }

        // Remove the last "\n", and store it into $scope.gqAlignmentsNoSumm without the Variation Summary Table
        gqAlignmentsNoSumm[item['dbkey']] = newAlignment.substr(0, newAlignment.length - 2); //$sce.trustAsHtml(newAlignment.substr(0, newAlignment.length - 2));
        let obj = {};
        obj[item['dbkey']] = newAlignment.substr(0, newAlignment.length - 2)
            .replace(/<\/?span class='singletooltiptext'.[^>]*>/g, "").replace(/<\/?span class='tooltiptext'.[^>]*>/g, "")
            .replace(/'/g, '"').replace(/&nbsp;/g, "&#8254;");
        //.replace(/lva-hint/g, '').replace(/lva-bar/g, '').replace(/lva-left-end/g, '').replace(/lva-right-end/g, '').replace(/lva-no-ends/g, '').replace(/lva-bg-white/g, '');
        gqAlignmentsStrNoSumm.push(obj);

        // For the Variation Summary Table
        if (hasLvaAccess) { // && !hasCrossSearch
            // Populates the alginment variations for current hints
            var hintIdx, markerIdx, markersSumm = [], markersSummIdx, duplicateMarker = false;
            for (hintIdx in hints.hints) {
                for (markerIdx in hints.hints[hintIdx].markersSumm) {
                    // Merge the marker summary and filter out the duplicate one
                    duplicateMarker = false;
                    for (markersSummIdx in markersSumm) {
                        if (markersSumm[markersSummIdx].description === hints.hints[hintIdx].markersSumm[markerIdx].description) {
                            duplicateMarker = true;
                            break;
                        }
                    }
                    if (!duplicateMarker) {
                        markersSumm.push(hints.hints[hintIdx].markersSumm[markerIdx]);
                    }
                }
            }
            let gqAlignmentsSummArray = [];
            if (markersSumm.length > 0) {
                // Sort the markersSumm by position
                markersSumm.sort(filter.compareMarkersSummPos);
                for (markersSummIdx in markersSumm) {
                    gqAlignmentsSummArray.push(markersSumm[markersSummIdx]);
                }
            }
            obj = {};
            obj[item['dbkey']] = gqAlignmentsSummArray;
            gqAlignmentsVariation.push(obj);

            // Gets the hints again and populates the alignment summary
            hints = filter.getAlignmentSummary(seqData);
            markersSumm = [];
            for (hintIdx in hints.hints) {
                for (markerIdx in hints.hints[hintIdx].markersSumm) {
                    // Merge the marker summary and filter out the duplicate one
                    duplicateMarker = false;
                    for (markersSummIdx in markersSumm) {
                        if (markersSumm[markersSummIdx].description === hints.hints[hintIdx].markersSumm[markerIdx].description) {
                            duplicateMarker = true;
                            break;
                        }
                    }
                    if (!duplicateMarker) {
                        markersSumm.push(hints.hints[hintIdx].markersSumm[markerIdx]);
                    }
                }
            }
            // Sort the markersSumm by position
            markersSumm.sort(filter.compareMarkersSummPos);
            gqAlignmentsSummArray = [];
            // Add the Variation Summary Table
            if (markersSumm.length > 0) {
                let alignmentTable = "<div class='clearfix lvm-summary-row'>";
                alignmentTable += createLVMSummCol("Query position", true);
                alignmentTable += createLVMSummCol("Variation type", true);
                alignmentTable += createLVMSummCol("Query", true);
                alignmentTable += createLVMSummCol("Subject", true);
                alignmentTable += createLVMSummCol("Description", true);
                alignmentTable += "</div>";
                let alignmentTableCsvString = "Query identifier: ," + item['QUERY_ID'];
                alignmentTableCsvString += "\r\n";
                alignmentTableCsvString += "Subject identifier: ," + item['SUBJECT_ID'];
                alignmentTableCsvString += "\r\n";
                alignmentTableCsvString += "Subject orientation: ," + ((item['RESULT_NFQ'] >= 3 && item['RESULT_NFQ'] <= 6) ? "Reverse" : "Forward");
                alignmentTableCsvString += "\r\n";
                alignmentTableCsvString += "\r\n";
                alignmentTableCsvString += "Variation Summary Table: ";
                alignmentTableCsvString += "\r\n";
                alignmentTableCsvString += "Query position,Variation type,Query,Subject,Description";

                // Variation hits
                let vmHits = '';
                for (markersSummIdx in markersSumm) {
                    if (vmHits !== '') {
                        vmHits += ', ';
                    }
                    let vmLink = process.env.REACT_APP_URL + 'discover/' + resid + '/variation/'
                        + item['QUERY_ID'] + '/' + markersSumm[markersSummIdx].description + '(1)';
                    markersSumm[markersSummIdx].vmHits = '<a target="_blank" rel="noreferrer" href="' + vmLink + '">' + markersSumm[markersSummIdx].description + '</a>';
                    vmHits += markersSumm[markersSummIdx].vmHits;

                        //alignmentTable += "\n";
                    alignmentTable += "<div class='clearfix lvm-summary-row'>";
                    alignmentTable += createLVMSummCol(markersSumm[markersSummIdx].pos, false);
                    alignmentTable += createLVMSummCol(markersSumm[markersSummIdx].type, false);
                    alignmentTable += createLVMSummCol(markersSumm[markersSummIdx].qChunk, false);
                    alignmentTable += createLVMSummCol(markersSumm[markersSummIdx].sChunk, false);
                    alignmentTable += createLVMSummCol(markersSumm[markersSummIdx].description, false);
                    alignmentTable += "</div>";

                    alignmentTableCsvString += "\r\n";
                    alignmentTableCsvString += markersSumm[markersSummIdx].pos + "," + markersSumm[markersSummIdx].type + ","
                        + markersSumm[markersSummIdx].qChunk + "," + markersSumm[markersSummIdx].sChunk + "," + markersSumm[markersSummIdx].description;

                    gqAlignmentsSummArray.push(markersSumm[markersSummIdx]);
                }
                item['RESULT_VMH'] = vmHits;

                let blob = new Blob([alignmentTableCsvString], {type: "text/csv"});
                let fileURL = URL.createObjectURL(blob);
                let fileName = state.fileName + ".csv";

                // <img src="img/download.png">
                newAlignment += "<div class='lvm-summary-header-row'><div class='lvm-summary-link'><a download='" + fileName + "' href=\"" + fileURL + "\"><span class='lvm-summary-link-label'>Download this table</span></a></div><div class='lvm-summary-header-label'>Variation Summary Table</div></div>";
                newAlignment += alignmentTable;
            } else {
                // Remove the last "\n"
                newAlignment = newAlignment.substr(0, newAlignment.length - 2);
            }

            obj = {};
            obj[item['dbkey']] = gqAlignmentsSummArray;
            gqAlignmentsSumm.push(obj);

        }

        // return colored alignment
        return newAlignment;
    }

    function createLVMSummCol(value, isHeader) {
        if (isHeader) {
            return "<div class='lvm-summary-gqcol lvm-summary-sub-header '><span>" + value + "</span></div>";
        } else {
            return "<div class='lvm-summary-gqcol'><div hover-delay='lvm-summary-gqcol-hover'><span>" + value + "</span></div></div>";
        }
    }

    function initDocItems(item) {
        if (item.type === 'doc') {
            if (item.databases) {
                let dbId = getDefaultDatabaseId(item.dbkey, item.databases);
                docItems[item.dbkey] = item.databases[dbId];
                docItemsSelected[item.dbkey] = dbId;
            }
        } else {
            docItems[item.dbkey] = item;
        }
        setDocItems(docItems);
        setDocItemsSelected(docItemsSelected);
        // console.log(docItems[item.dbkey]);
    }

    function getDefaultDatabaseId(dbkey, databases) {
        let dbs = Object.keys(databases);
        if (fieldConfig.databasePrefs && fieldConfig.databasePrefs.length > 0) {
            for (let i = 0; i < fieldConfig.databasePrefs.length; i++) {
                for (let j = 0; j < dbs.length; j++) {
                    if (getPrefName(dbs[j]) === fieldConfig.databasePrefs[i]) {
                        return dbs[j];
                    }
                }
            }
        } else {
            // No preference settings, default as GQ-Pat
            let names = ['GQPAT_NUC', 'GQPAT_PRT', 'GQPAT_PREMIUM_PRT', 'GQPAT_PREMIUM_NUC']
            for (let i = 0; i < names.length; i++) {
                if (databases[names[i]]) {
                    return names[i];
                }
            }
        }

        return dbs[0];
    }

    function getPrefName(dbId) {
        let name = fieldConfig.patentDatabases[dbId];
        if (name) {
            return name;
        } else {
            return dbId;
        }
    }

    function prepDatabases(resInfo) {
        //databasesForDed = {};
        let dbId, databases = {};
        _.forEach(
            resInfo.databases,
            function (SUBJECT_GNAME) {
                dbId = _.split(SUBJECT_GNAME, ':')[0];
                databases[dbId] = fieldConfig.getDatabaseBriefName(SUBJECT_GNAME);
                if (fieldConfig.isPatentDatabase(SUBJECT_GNAME)) {
                    let id = getPrefName(dbId);
                    databasesForDed[id] = fieldConfig.getPatentDatabaseLabel(id);
                }

                if (dbId.indexOf("CASNP") === 0 || dbId.indexOf("REGNP") === 0) {
                    //hasRegNp = true;
                    setHasRegNp(true);
                }
            }
        );
        setDataBasesForDed(databasesForDed);
        return databases;
    }

     // prepare group filter (show all PNs that hit at least three different query sequences)
     //groupQuery = {};
     function ApplyGroupQuery(fieldId, nbseqs){
        if (fieldId === 'TOTAL') {
            groupQuery = {};
        } else {
            groupQuery = {
                GROUP_BY: fieldId,
                GROUP_CONCAT: 'RESULT_NQ',
                METHOD: 'NBVALS',
                NBVALS: nbseqs
            };
        }
        setGroupQuery(groupQuery);
        loadResultsFromBackend(true);
     }

    function expandAllDetailPage(displayMode, type, value) {
        if (displayMode === 'family') {
            if (type === 'fam' || type === 'ufs') {
                detailPageCollapseAllLevelOne = value;
                setDetailPageCollapseAllLevelOne(detailPageCollapseAllLevelOne);
            } else if (type === 'doc') {
                detailPageCollapseAllLevelTwo = value;
                setDetailPageCollapseAllLevelTwo(detailPageCollapseAllLevelTwo);
            } else {
                detailPageCollapseAllLevelThree = value;
                setDetailPageCollapseAllLevelThree(detailPageCollapseAllLevelThree);
            }
        } else if (displayMode === 'ufs') {
            if (type === 'ufs') {
                detailPageCollapseAllLevelOne = value;
                setDetailPageCollapseAllLevelOne(detailPageCollapseAllLevelOne);
            } else if (type === 'fam') {
                detailPageCollapseAllLevelTwo = value;
                setDetailPageCollapseAllLevelTwo(detailPageCollapseAllLevelTwo);
            } else {
                detailPageCollapseAllLevelThree = value;
                setDetailPageCollapseAllLevelThree(detailPageCollapseAllLevelThree);
            }
        } else if (displayMode === 'document' || displayMode === 'cas' || displayMode === 'ssv'
            || displayMode === 'qs' || displayMode === 'hsp' || displayMode === 'org'
            || displayMode === 'ali' || displayMode === 'npa' || displayMode === 'gname') {
            if (type === 'doc' || type === 'cas' || type === 'ssv' || type === 'qs' || type === 'hsp'
                || type === 'ali' || type === 'npa' || type === 'gname' || type === 'org') {
                detailPageCollapseAllLevelOne = value;
                setDetailPageCollapseAllLevelOne(detailPageCollapseAllLevelOne);
            } else {
                detailPageCollapseAllLevelTwo = value;
                setDetailPageCollapseAllLevelTwo(detailPageCollapseAllLevelTwo);
            }
        } else {
            detailPageCollapseAllLevelOne = value;
            setDetailPageCollapseAllLevelOne(detailPageCollapseAllLevelOne);
        }
    }

    function expandAllClaims(val) {
        claimsCollapseAll = val;
        setClaimsCollapseAll(val);
    }

    function expandAllAlignments(val) {
        alignmentsCollapseAll = val;
        setAlignmentsCollapseAll(val);
    }

    function switchView(viewName, fieldConfig, filter, editable) {
        fieldConfig.selectView(fieldConfig, viewName, filter, editable);
        let displayMode = 'flat';
        if (viewName === 'default') {
            displayMode = fieldConfig.tableDisplayMode;
        } else if (viewName === 'defaultAli') {
            displayMode = 'flat';
        } else if (viewName === 'defaultFt') {
            displayMode = 'document';
        }  else if (viewName === 'defaultFam') {
            displayMode = 'family';
        } else if (viewName === 'defaultSsv') {
            displayMode = 'ssv';
        } else if (!fieldConfig.views[viewName]) { // For delete case
            return;
        } else if (fieldConfig.views[viewName].hasOwnProperty('tableDisplayMode')) {
            displayMode = fieldConfig.views[viewName].tableDisplayMode;
        }
        if (displayMode !== fieldConfig.tableDisplayMode) {
            // Reload the page because of the change of display mode
            // Will call saveResultState() in loadResultsFromBackend()
            fieldConfig.tableDisplayMode = displayMode;
            selectedGroupOption = displayMode;
            setSelectedGroupOption(displayMode);
            loadResultsFromBackend(false);
        } else {
            if (editable === true) {
                saveResultState(filter, fieldConfig);
            }

            searchResultData.columns = fieldConfig.userTableTemplate['flat'];
            searchResultData.tableWidth = fieldConfig.estimateTableWidth(fieldConfig.userTableTemplate, fieldConfig.tableDisplayMode);
            setSearchResultData({...searchResultData});
        }
    }

    function loadResultsFromBackend(loadStatisticInfo) {
        if (pnListErr && pnListErr !== '') {
            return;
        }

        UIlog.record(resid, 'APPLY_BUTTON', 'pressed');
        setApplyEnabled(false);
        state.currentPage = 1;
        setCurrentPage(1);
        //self.gqtour.end();
        if (editable === true) {
            saveResultState(filter, fieldConfig);
        }
        if (loadStatisticInfo) {
            loadStatistic(resInfo);
        }
        fetchResultsNow();
        //fieldConfig.estimateTableWidth();

        setAbFilters(previewABReport());
    }

    function updateViewsHover(viewName, val) {
        let finalData = [...viewsHover];
        finalData[viewName] = val;
        setViewsHover(finalData);
    }

    function showColumnConfigModal(viewName) {
        // yet to set field config attributes for view names
        if (editable !== true) {
            return;
        }

        setTempTemplate(JSON.parse(JSON.stringify(fieldConfig.userTableTemplate)));
        if (viewName !== 'new') {
            fieldConfig.userTableTemplate = fieldConfig.views[viewName];
            fieldConfig.newViewName = viewName;
        }
        else {
            fieldConfig.newViewName = "";
        }
        setAddDisplayColumnShow(true)
    }
    const closeFolderModal = () => {
        setAddDisplayColumnShow(false);
    }

    const saveViews = () => {
        fieldConfig.views = {...fieldConfig.views};
        fieldConfig.viewKeys = [...fieldConfig.viewKeys];
        setViewKeys([...fieldConfig.viewKeys]);
    }

    const cancelDispModal = () => {
        fieldConfig.userTableTemplate = tempTemplate;
        searchResultData.columns = fieldConfig.userTableTemplate[fieldConfig.tableDisplayModeFromView];
       

    }
    const handleColumns = () => {
        searchResultData.columns = fieldConfig.userTableTemplate[fieldConfig.tableDisplayModeFromView];
        searchResultData.tableWidth = fieldConfig.estimateTableWidth(fieldConfig.userTableTemplate, fieldConfig.tableDisplayMode);
        setSearchResultData({...searchResultData});
    }

    const smartHighlightGetOptions = (e, filter, text) => {
        setSmartHighlightText(text);
        filter.state.smartHighlight = text;
        if (!hlFilterSearching || hlFilterSearching === _NOT_SEARCHING) {
            smartHighlightGetOptionsBackendBfql(e, filter, text);
        }
    }

    async function smartHighlightGetOptionsBackendBfql(e, filter, val) {
        let text = val;
        if (text && text.length >= minSmartTextLen) {
            let data = {
                workflowId: resid.match(/\d+/)[0],
                userId: userdir ? userdir : window.$javaCall.data.userId,
                searchValue: text
            }
            let response = await DiscoverService.searchText(data, window.$javaCall.data.gqApiKey);
            if (response) {
                //if (text !== smartHighlightText) {
                    //callAgain = true;
                //}
                let highlightOptions = [];
                _.each(response.fields, function (key, val) {
                    highlightOptions.push({
                        key: key,
                        count: 1,
                        text: text
                    });
                });
                setSmartHighlightPopup(true);
                setHlFilterSearching(_NOT_SEARCHING);
                if (_.size(highlightOptions) > 0) {
                    setSmartHighlightOptions(highlightOptions);
                } else {
                    setSmartHighlightOptions([]);
                }
                //document.getElementById("hlText").focus();
                //if (callAgain) {
                    //smartHighlightGetOptions(e, filter, text);
                //}
            } else {
                setSmartHighlightPopup(false);
                setSmartHighlightOptions([]);
            }
        } else {
            setSmartHighlightPopup(false);
            setSmartHighlightOptions([]);
        }
    }

    function addSmartHighlight(resultFilter, field) {
        let text, existingEntry, splitted, filter;
        resultFilter.state.smartHighlight = smartHighlightText;
        text = resultFilter.state.smartHighlight;
        filter = {...smartHighlightFilter};
        if (!filter) {
            filter = {};
        }
        if (text.length >= minSmartTextLen) {
            if (!(field in filter)) {
                filter[field] = [];
            }
            // check for redundant entries
            existingEntry = _.find(filter[field], function (obj) {
                return (obj.text === text);
            });
            if (existingEntry !== undefined) {
                // the entry already exists, check the checkbox
                existingEntry.value = true;
            } else {
                // split on whitespace, but respect phrases in single or double quotes
                splitted = text.match(/"([^"]*)"|'([^']*)'|[^\s]+/g);
                // remove quotes from the splitted phrases
                // for (j = 0; j < splitted.length; j++) {
                //     splitted[j] = splitted[j].replace(/["']/g, '');
                // }
                UIlog.record(resid, 'HIGHLIGHT', field);
                filter[field].push({
                    text: text,
                    value: true,
                    negate: false,
                    color: 1,
                    match: splitted,
                    source: 'highlight'
                });
            }
        }
        resultFilter.state.smartHighlightFilter = filter;
        setSmartHighlightFilter(filter);
        // hide the smart text autocomplete UI
        setSmartHighlightPopup(false);
        if (editable === true) {
            saveResultState(filter, fieldConfig); // save changes to highlighting
        }
    }

    function addKeyWordsInContext(resultFilter, fieldRay, fieldConfig) {
        let filter = {...resultFilter.state.smartHighlightFilter};

        if(Array.isArray(fieldRay)){
            for(let i =0; i< fieldRay.length; i++){
            if(fieldRay[i] != undefined && fieldRay[i] != null){
                let existingEntry, splitted;
                

                if (!filter) {
                    filter = {};
                }
                if (fieldRay[i].length >= minSmartTextLen) {
                    if (!("ALL_TEXT" in filter)) {
                        filter["ALL_TEXT"] = [];
                    }
                    // check for redundant entries
                    existingEntry = _.find(filter["ALL_TEXT"], function (obj) {
                        return (obj.text === fieldRay[i]);
                    });
                    if (existingEntry === undefined) {

                        splitted = fieldRay[i].match(/"([^"]*)"|'([^']*)'|[^\s]+/g);
                        
                        UIlog.record(resid, 'HIGHLIGHT', "ALL_TEXT");
                        filter["ALL_TEXT"].push({
                            text: fieldRay[i],
                            value: true,
                            negate: false,
                            color: 1,
                            match: splitted,
                            source: 'highlight'
                        });
                    }
                }
            }
          }
        }

        resultFilter.state.smartHighlightFilter = filter;
        setSmartHighlightFilter(filter);
        if (editable === true) {
            // Correct the logic for resultFilter & fieldConfig
            saveResultState(resultFilter, fieldConfig);
        }
    }

    const toggleSideMenuDetail = (sideMenuItem) => {
        if (sideMenuDetail) {// Wait for the initialzation
            let val = (sideMenuDetail[sideMenuItem] ? false : true);
            sideMenuDetail[sideMenuItem] = val;
            setSideMenuDetail({...sideMenuDetail});
        }
    }

    function setHighlightCheckbox() {
        setSmartHighlightFilter({...smartHighlightFilter});
        filter.state.smartHighlightFilter = {...smartHighlightFilter};
        filter.state = {...filter.state};
        if (editable === true) {
            saveResultState(filter, fieldConfig); // save changes to highlighting
        }
    }

    function removeSmartHighlight(filter, field, idx) {
        console.log(field + '-' + filter.state.smartHighlightFilter[field]);
        filter.state.smartHighlightFilter[field].splice(idx, 1);
        console.log(smartHighlightFilter[field]);
        //smartHighlightFilter[field].splice(idx, 1);
    }

    const smartHighlightFilterEvent = (index, val) => {
        let finalData = [...smartHighlightFilterHover];
        finalData[index] = val;
        setSmartHighlightFilterHover(finalData);
    }
    const showHighlightColorBox = (index, val) => {
        highlightColorBox[index] = val;
        setHighlightColorBox({...highlightColorBox});
    }

    function selectHighlightColor(index, subIdx, color) {
        // Update the color
        smartHighlightFilter[index][subIdx].color = color;
        setSmartHighlightFilter({...smartHighlightFilter});
        // Close the color selection box
        highlightColorBox[index + '_' + subIdx] = false;
        setHighlightColorBox({...highlightColorBox});
        filter.state.smartHighlightFilter = {...smartHighlightFilter};
        if (editable === true) {
            saveResultState(filter, fieldConfig); // save changes to highlighting
        }
    }

    function clearHighlights() {
        if (editable !== true) {
            return;
        }
        setSmartHighlightText('');
        filter.state.smartHighlight = '';
        smartHighlightFilter= {};
        setSmartHighlightFilter({...smartHighlightFilter});
        filter.state.smartHighlightFilter = {...smartHighlightFilter};
        if (editable === true) {
            saveResultState(filter, fieldConfig); // save changes to highlighting
        }
    }

    function handleSideMenu(){
        if(sideMenuCollapse){
        setSideMenuCollapse(false);}
        else{
            setSideMenuCollapse(true);
        }
    }

    function handleDedup(resultFilter){
        setDeduplication(!deduplication);
        filter.state.deduplication = !deduplication;
        if (!dedDatabasePrefs || dedDatabasePrefs.length === 0) {
            let dedDatabasePrefs = [];
            dedDatabasePrefs.push('GQPat'); // Keeps GQPat as the first choice
            resultFilter.dbs.forEach(function (value) {
                let name = getPrefName(value);
                if (dedDatabasePrefs.length === 0 || dedDatabasePrefs.indexOf(name) < 0) {
                    dedDatabasePrefs.push(name);
                }
            });
            setDedDatabasePrefs(dedDatabasePrefs);
            filter.state.dedDatabasePrefs = dedDatabasePrefs;
        }
        loadResultsFromBackend(true);
    }

    function handleChartMenu(){
        setMultiQuerySeqMenu(false);
        if(chartMenu){
            setChartMenu(false);}
        else{
            setChartMenu(true);
        }
    }

    function handleMulti(field,nbseqs){
        ApplyGroupQuery(field,nbseqs);
    }

    function handleMultiQuerySeqMenu(){
        setChartMenu(false);
        if(multiQuerySeqMenu){
            setMultiQuerySeqMenu(false);}
        else{
            setMultiQuerySeqMenu(true);
        }
    }

    const closePopover = () => {
        setAnchorEl(null);
        setSmartHighlightPopup(false);
        setFilterAnchorEl(null);
        setSmartTextPopup(false);
    }

    function saveEsfGrouping(fieldConfig, selectedGroupOption, option1, option2, groupSize, groupSizeOper) {
        let displayMode = fieldConfig.tableDisplayMode;
        let newMode = selectedGroupOption;
        let viewName = fieldConfig.currentView;
        // Reset the collapse settings
        setDetailPageCollapseAllLevelOne(false);
        setDetailPageCollapseAllLevelTwo(false);
        setDetailPageCollapseAllLevelThree(false);

        // Change the view name to default when switching the grouping
        if (viewName && viewName !== 'default' && newMode !== displayMode) {
            fieldConfig.currentView = 'default';
        }
        if (newMode !== displayMode) {
            if (newMode === 'ssv') {
                // Change the default display column list
                fieldConfig.userTableTemplate["sin"] = ["QUERY_ID", "RESULT_RIQ", "RESULT_RID", "RESULT_RL", "RESULT_RI"];
            } else if (displayMode === 'ssv') {
                // Change back to the default display column list
                fieldConfig.userTableTemplate["sin"] = ["SUBJECT_SI", "QUERY_ID", "RESULT_RL", "RESULT_RIQ", "SUBJECT_PS"];
            }

            if (newMode === 'document') {
                fieldConfig.userTableTemplate["doc"] = ["SUBJECT_PN", "SUBJECT_PG", "SUBJECT_D1", "SUBJECT_D4", "SUBJECT_PU", "VT_FT", "SUBJECT_PT"];
                fieldConfig.userTableTemplate["sin"] = ["SUBJECT_ID", "QUERY_ID", "RESULT_RIQ", "RESULT_RID", "SUBJECT_PS"];
            } else if (newMode === 'ufs') {
                fieldConfig.userTableTemplate["ufs"] = ['UFS_DISPLAY', 'SUBJECT_PJ'];
                fieldConfig.userTableTemplate["flat"] = ["SUBJECT_ID", "QUERY_ID", "SUBJECT_PN", "SUBJECT_PG", "SUBJECT_PU", "VT_FT", "SUBJECT_PT",
                                                        "RESULT_RIQ", "RESULT_RID", "SUBJECT_PS", "SUBJECT_D1", "SUBJECT_D4"];
            } else if (newMode === 'qs') {
                // Change the default display column list, remove the QUERY_ID since it is in the grouped level
                fieldConfig.userTableTemplate["qs"] = ["QUERY_ID", "QUERY_L"];
                fieldConfig.userTableTemplate["sin"] = ["SUBJECT_ID", "QUERY_ID", "SUBJECT_PN", "SUBJECT_PG", "SUBJECT_PU", "VT_FT", "SUBJECT_PT",
                                                        "RESULT_RIQ", "RESULT_RID", "SUBJECT_PS", "SUBJECT_D1", "SUBJECT_D4"];
            } else if (newMode === 'hsp') {
                // Change the default display column list, remove the QUERY_ID since it is in the grouped level
                fieldConfig.userTableTemplate["hsp"] = ["QUERY_ID", "SUBJECT_ID", "QUERY_L"];
                fieldConfig.userTableTemplate["sin"] = ["SUBJECT_PA", "SUBJECT_PT", "SUBJECT_PG", "RESULT_COV", "RESULT_RIQ", "RESULT_RID", "RESULT_RL", "RESULT_RI"];
            } else if (newMode === 'gname') {
                fieldConfig.userTableTemplate["gname"] = ["SUBJECT_GNAME"];
                fieldConfig.userTableTemplate["sin"] = ["SUBJECT_ID", "QUERY_ID", "SUBJECT_PN", "SUBJECT_PG", "SUBJECT_PU", "VT_FT", "SUBJECT_PT",
                                                        "RESULT_RIQ", "RESULT_RID", "SUBJECT_PS", "SUBJECT_D1", "SUBJECT_D4"];
            } else if (newMode === 'npa') {
                fieldConfig.userTableTemplate["npa"] = ["SUBJECT_PU", "SUBJECT_PA"];
                fieldConfig.userTableTemplate["sin"] = ["SUBJECT_ID", "QUERY_ID", "SUBJECT_PN", "SUBJECT_PG", "SUBJECT_PU", "VT_FT", "SUBJECT_PT",
                                                        "RESULT_RIQ", "RESULT_RID", "SUBJECT_PS", "SUBJECT_D1", "SUBJECT_D4"];
            } else if (newMode === 'org') {
                fieldConfig.userTableTemplate["org"] = ["SUBJECT_OS", "SUBJECT_OO", "SUBJECT_OX", "SUBJECT_OC"];
                fieldConfig.userTableTemplate["sin"] = ["SUBJECT_ID", "QUERY_ID", "SUBJECT_PN", "SUBJECT_PG", "SUBJECT_PU", "VT_FT", "SUBJECT_PT",
                                                        "RESULT_RIQ", "RESULT_RID", "SUBJECT_PS", "SUBJECT_D1", "SUBJECT_D4"];
            } else if (newMode === 'ali') {
                fieldConfig.userTableTemplate["ali"] = ["ALIGN_DESC"];
                fieldConfig.userTableTemplate["sin"] = ["SUBJECT_ID", "QUERY_ID", "SUBJECT_PN", "SUBJECT_PG", "SUBJECT_PU", "VT_FT", "SUBJECT_PT",
                                                        "RESULT_RIQ", "RESULT_RID", "SUBJECT_PS", "SUBJECT_D1", "SUBJECT_D4"];
            } else if (displayMode === 'qs' || displayMode === 'hsp' || displayMode === 'ali' || displayMode === 'npa'
                        || displayMode === 'gname' || displayMode === 'org') {
                // Change back to the default display column list
                fieldConfig.userTableTemplate["sin"] = ["QUERY_ID", "RESULT_RIQ", "RESULT_RID", "RESULT_RL", "RESULT_RI"];
            }
        }

        if (newMode === 'family') {
            fieldConfig.famViewOption = getViewOption(option1);
            fieldConfig.famViewAliOption = getViewOption(option2);
            viewOptions.selectedFamViewOption = getViewOption(option1);
            viewOptions.selectedFamViewAliOption = getViewOption(option2);
        } else if (newMode === 'document') {
            fieldConfig.pnViewOption = getViewOption(option1);
            viewOptions.selectedPnViewOption = getViewOption(option1);
        } else if (newMode === 'ssv') {
            fieldConfig.ssvOption = getViewOption(option1);
            viewOptions.selectedSsvOption = getViewOption(option1);
        } else if (newMode === 'ufs') {
            fieldConfig.ufsOption = getViewOption(option1);
            viewOptions.selectedUfsOption = getViewOption(option1);
        } else if (newMode === 'qs') {
            fieldConfig.qsOption = getViewOption(option1);
            viewOptions.selectedQsOption = getViewOption(option1);
        } else if (newMode === 'hsp') {
            fieldConfig.hspOption = getViewOption(option1);
            viewOptions.selectedHspOption = getViewOption(option1);
        } else if (newMode === 'ali' || newMode === 'npa' || newMode === 'gname' || newMode === 'org') {
            fieldConfig.groupSize = groupSize;
            fieldConfig.groupSizeOper = groupSizeOper;
            fieldConfig.groupOption = option1;
            viewOptions.selectedGroupOption = option1;
        }
        setViewOptions({...viewOptions});
        fieldConfig.tableDisplayMode = newMode;
        loadResultsFromBackend(false);
    }

    function getViewOption(option) {
        let result = option;
        if (!option) {
            result = '';
        } else if (option.startsWith("All")) {
            result = 'All';
        } else if (option.startsWith("10 ")) {
            result = '10';
        } else if (option.startsWith("5 ")) {
            result = '5';
        } else if (option.startsWith("3 ")) {
            result = '3';
        } else if (option.startsWith("1 ")) {
            result = '1';
        }
        return result;
    }

    const smartTextGetOptions = (e, filter, text) => {
        setSmartText(text);
        filter.state.smartText = text;
        if (!filterSearching || filterSearching === _NOT_SEARCHING) {
            smartTextGetOptionsBackendBfql(e, filter, text);
        }
    }

    async function smartTextGetOptionsBackendBfql(e, filter, val) {
        let text = val;
        if (text && text.length >= minSmartTextLen) {
            let data = {
                workflowId: resid.match(/\d+/)[0],
                userId: userdir ? userdir : window.$javaCall.data.userId,
                searchValue: text
            }
            let response = await DiscoverService.searchText(data, window.$javaCall.data.gqApiKey);
            if (response) {
                //if (text !== smartText) {
                    //callAgain = true;
                //}
                let options = [];
                text = _.replace(text, new RegExp('^!'), 'NOT ');
                _.each(response.fields, function (key, val) {
                    options.push({
                        key: key,
                        count: val,
                        text: text
                    });
                });
                setSmartTextPopup(true);
                setFilterSearching(_NOT_SEARCHING);
                if (_.size(options) > 0) {
                    setSmartTextOptions(options);
                } else {
                    setSmartTextOptions([]);
                }
            } else {
                setSmartTextPopup(false);
                setSmartTextOptions([]);
            }
        } else {
            setSmartTextPopup(false);
            setSmartTextOptions([]);
        }
    }

    function addSmartText(resultFilter, field) {
        enableApplyButton();
        let text, existingEntry, splitted, j, filter, negate;
        resultFilter.state.smartText = smartText;
        text = smartText;
        filter = {...smartTextFilter};
        if (!filter) {
            filter = {};
        }
        negate = false;
        if (text.indexOf("!") === 0) {
            text = _.replace(text, new RegExp('^!'), '');
            negate = true;
        }
        if (text.length >= minSmartTextLen) {
            if (!(field in filter)) {
                filter[field] = [];
            }
            // check for redundant entries
            existingEntry = _.find(filter[field], function (obj) {
                return (obj.text === text && negate === obj.negate);
            });
            if (existingEntry !== undefined) {
                // the entry already exists, check the checkbox
                existingEntry.value = true;
            } else {
                // split on whitespace, but respect phrases in single or double quotes
                splitted = text.match(/"([^"]*)"|'([^']*)'|[^\s]+/g);
                // remove quotes from the splitted phrases
                for (j = 0; j < splitted.length; j++) {
                    splitted[j] = splitted[j].replace(/["']/g, '');
                }
                UIlog.record(resid, 'FILTER_SMARTTEXT', field);
                filter[field].push({
                    text: text,
                    value: true,
                    negate: negate,
                    color: 0,
                    match: splitted,
                    source: 'filter'
                });
            }
        }
        //resultFilter.state.smartTextFilter = filter;
        setSmartTextFilter(filter);
        // hide the smart text autocomplete UI
        setSmartTextPopup(false);
    }

    function removeSmartText(filter, field, idx) {
        let options = {...smartTextFilter};
        let selected = false;
        if (filter.state.smartTextFilter[field] && filter.state.smartTextFilter[field][idx]) {
            selected = filter.state.smartTextFilter[field][idx].value;
            filter.state.smartTextFilter[field].splice(idx, 1);
            if (filter.state.smartTextFilter[field].length === 0) {
                delete filter.state.smartTextFilter[field];
            }
        }
        options[field].splice(idx, 1);
        if (options[field].length === 0) {
            delete options[field];
        }
        setSmartTextFilter(options);

        if (selected) {
            filter.state.smartTextFilter = {...smartTextFilter};
            // When removing one applied filter, refresh the whole page
            Object.assign(filterState, filter.state);
            loadResultsFromBackend(true);
        }
    }

    const smartTextFilterEvent = (index, val) => {
        let finalData = [...smartTextHover];
        finalData[index] = val;
        setSmartTextHover(finalData);
    }

    function setSmartTextCheckbox() {
        setSmartTextFilter({...smartTextFilter});
        //filter.state.smartTextFilter = {...smartTextFilter};
        //filter.state = {...filter.state};
        enableApplyButton();
    }

    function clearSmartTextFilter(filter) {
        filter.state.smartText = '';
        setSmartText('');
        // Only unselects the checkboxes
        if (Object.keys(smartTextFilter).length > 0) {
            _.forEach(
                smartTextFilter,
                function (val) {
                    _.forEach(
                        val, function (item) {
                            item.value = false;
                        }
                    )
                }
            );
        }
        //smartTextFilter = {};
        setSmartTextFilter({...smartTextFilter});
        filter.state.smartTextFilter = {...smartTextFilter};
    }

    function resetAllFitlers(fieldConfig, filter) {
        filter.state.singleAlignmentId = undefined;
        filter.state.abExp = undefined;
        filter.state.vmExp = undefined;
        filter.state.vmQueryId = undefined;
        //fieldConfig.singleAlignment = false;
        //fieldConfig.forRegistry = false;

        clearSmartTextFilter(filter);
        clearQuerySeqIdFilter(filter);
        clearPnFilter(filter);
        clearFilter(filter, 'databaseNames');
        clearFilter(filter, 'SUBJECT_PN');
        clearFilter(filter, 'SUBJECT_PG');
        clearFilter(filter, 'SUBJECT_PS');
        clearAnnotationFilter(filter, 'checkboxes');
        clearAnnotationFilter(filter, 'stars');
        clearAnnotationFilter(filter, 'colors');
        // Clear notes
        noteListChanged(filter, '');
        clearCustomFilter(filter, 'customFilterCheckboxes');
        clearCustomFilter(filter, 'lvaFilterCheckboxes');

        // Clear AB filter (links from the AB report)
        window.reportFilters = {};

        setDeduplication(false);
        filter.state.deduplication = false;
        filter.state = {...filter.state};
        Object.assign(filterState, filter.state);
        setFilterState({...filterState});

        //$scope.filter.clearABFilters();
        groupQuery = {};

        saveResultState(filter, fieldConfig);
        //clearHighlights(); // This one will call saveState()
    }

    function applyAllFilters(filter) {
        filter.state.smartTextFilter = {...smartTextFilter};
        Object.assign(filterState, filter.state);
    }

    function setQuerySeqCheckbox(filter, seq) {
        enableApplyButton();
        filter.state.querySeqDbs = {...filterState.querySeqDbs};
        filter.state.querySeqDbs[querySeqIds[seq].id] = !filter.state.querySeqDbs[querySeqIds[seq].id];
        Object.assign(filterState, filter.state);
        setFilterState({...filterState});
    }

    function clearQuerySeqIdFilter(filter) {
        filter.state.querySeqDbs = {};
        filterState.querySeqDbs = {};
        setFilterState({...filterState});
    }

    function setDatabaseCheckbox(filter, db) {
        if (deduplication) {
            // Show alert
            setSelectedDatabase(db);
            setShowDedAlert(true);
        } else {
            enableApplyButton();
            filter.state.databaseNames = {...filterState.databaseNames};
            filter.state.databaseNames[db] = !filter.state.databaseNames[db];
            Object.assign(filterState, filter.state);
            setFilterState({...filterState});
        }
    }

    const closeDeduplicationModal = () => {
        setShowDedAlert(false);
    }

    const continueDeduplicationModal = (filter, filterState, db) => {
        setShowDedAlert(false);
        enableApplyButton();
        filter.state.databaseNames = {...filterState.databaseNames};
        filter.state.databaseNames[db] = !filter.state.databaseNames[db];
        Object.assign(filterState, filter.state);
        setFilterState({...filterState});
    }

    function clearFilter(filter, name) {
        filter.state = {...filter.state};
        filter.state[name] = {};
        Object.assign(filterState, filter.state);
        setFilterState({...filterState});
    }

    function clearCustomFilter(filter, name) {
        filter.state = {...filter.state};
        filter.state[name] = [];
        Object.assign(filterState, filter.state);
        setFilterState({...filterState});
    }

    function clearPnFilter(filter) {
        filter.state.publicationNumberList = '';
        filterState.publicationNumberList = '';
        setFilterState({...filterState});
    }

    function pnListChanged(filter, text) {
        // count number of entries in the list, and record in the log when PNs have been added / removed
        let prevNbEntries = (filter.state.publicationNumberList || []).length;
        let query = {};
        filter.prepPublicationNumberFilter(filter, query, text);
        if (query.publicationNumberList.length !== prevNbEntries) {
            UIlog.record(resid, 'FILTER_PNLIST', query.publicationNumberList.length + " PNs");
        }
        if (query.publicationNumberList.length > 4000) {
            setPnListErr('Maximum 4,000 patent numbers may be searched at one time');
            setApplyEnabled(false);
        } else {
            setPnListErr('');
            // enable the apply button
            enableApplyButton();
        }
        filter.state.publicationNumberList = text;
        Object.assign(filterState, filter.state);
        setFilterState({...filterState});
    }

    function setFacetCheckbox(filter, key, val) {
        enableApplyButton();
        filter.state[key] = {...filterState[key]};
        filter.state[key][val] = !filter.state[key][val];
        Object.assign(filterState, filter.state);
        setFilterState({...filterState});
    }

    function setAnnotationCheckbox(filter, key, val) {
        enableApplyButton();
        //filter.state.annot[key] = {...filterState.annot[key]};
        filter.state.annot[key][val] = !filter.state.annot[key][val];
        Object.assign(filterState, filter.state);
        setFilterState({...filterState});
    }

    function clearAnnotationFilter(filter, name) {
        filter.state.annot[name] = {}; //_.cloneDeep(originalState.annot[name]);
        filterState.annot[name] = {}; //_.cloneDeep(originalState.annot[name]);
        setFilterState({...filterState});
    }

           /* ====== result sorting ======================================= */

    // called from UI when the user changes the sort order
    function changeSort (sortKey) {
        console.log(filter.state.sort,"filter.state.sort");
        if (sortKey !== filter.state.sort) {
            filter.state.sort = sortKey;
            loadResultsFromBackend(false);
        }
    };

    const startExportDownload = (format) => {
        if (JSON.stringify(searchResultData.data) === '{}') {
            alert("Cannot export empty result set");
            return;
        }
        setDownloadType(format);
        if (format === "PNL") {
            setDownloadTypeDesc("Publication Number List");
        } else if (format === "PNFIL") {
            setDownloadTypeDesc("Publication Number, Family ID List");
        } else if (format === "Fastafrag" || format === "Fastasub") {
            setDownloadTypeDesc("Fasta");
        } else {
            setDownloadTypeDesc(format);
        }
        let message = '';
        if (format === 'Word' || format === "Word Simple" || format === "HTML"
            || format === 'EXCEL' || format === 'CSV' || format === 'TSV') {
            setTotalAlignmentNumber(searchResultData.stats.nav.total_count);
            setTotalPatentNumber(searchResultData.stats.nav.total_count);
            let totalAlignmentNumber = searchResultData.stats.nav.total_count;
            if (totalAlignmentNumber > process.env.REACT_APP_EXPORT_LIMIT) {
                message = "Maximum data export limit reached. 20,000 of " + totalAlignmentNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " records will be downloaded.";
            }
            setAlertMessage(message);
            if (format === 'EXCEL') {
                setIncludeResult(true);
                setIncludeAnnotation(true);
                setIncludeSS(false);
            } else if (format === 'Word' || format === "HTML") {
                setIncludeAnnotation(false);
                setIncludeSS(true);
            }
            setShowExportAllModal(true);
        } else {
            setTotalAlignmentNumber(viewCount.RESULT_ID);
            setTotalPatentNumber(viewCount.SUBJECT_PN);
            let totalPatentNumber = viewCount.SUBJECT_PN;
            let totalAlignmentNumber = viewCount.RESULT_ID;
            if (totalPatentNumber > process.env.REACT_APP_EXPORT_LIMIT) {
                message = "Maximum data export limit reached. 20,000 of " + totalPatentNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " patent numbers will be downloaded.";
            } else if (totalAlignmentNumber > process.env.REACT_APP_EXPORT_LIMIT) {
                message = "Maximum data export limit reached. 20,000 of " + totalAlignmentNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " alignments will be downloaded.";
            }
            setAlertMessage(message);

            //checkExportAmount();
            if (format === 'XML' || format === 'BizInt' || format === 'Geneious' || format === 'Fastafrag' ||format === 'Patbase'
                || format === "LifeQuest" || format === "FullText" || format === "Clustal") {
                displayExportSelection();
                setCustomTtlCount(searchResultData.stats.nav.total_count);
                let custMessage = '';
                let customTtlCount = searchResultData.stats.nav.total_count;
                if (customTtlCount > process.env.REACT_APP_EXPORT_LIMIT) {
                    custMessage = "Maximum data export limit reached. 20,000 of " + customTtlCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " results will be exported.";
                }
                setSaveAsName("Fulltext search of " + resInfo['title']);
                setCustomAlertMessage(custMessage);
                countCheckedRecords(format);
            } else {
                setShowExportAllModal(true);
            }
        }
    }

    function displayExportSelection() {
        if (fieldConfig.tableDisplayMode === 'ssv' && viewOptions.selectedSsvOption !== 'All') {
            setDisplayCustomSelection(true);
            setCustomSelectionLabel(ssvOptions[viewOptions.selectedSsvOption]);
        } else if (fieldConfig.tableDisplayMode === 'qs' && viewOptions.selectedQsOption !== 'All') {
            setDisplayCustomSelection(true);
            setCustomSelectionLabel(qsOptions[viewOptions.selectedQsOption]);
        } else if (fieldConfig.tableDisplayMode === 'hsp' && viewOptions.selectedHspOption !== 'All') {
            setDisplayCustomSelection(true);
            setCustomSelectionLabel(hspOptions[viewOptions.selectedHspOption]);
        } else if (fieldConfig.tableDisplayMode === 'document' && viewOptions.selectedPnViewOption !== 'All') {
            setDisplayCustomSelection(true);
            setCustomSelectionLabel(pnViewOptions[viewOptions.selectedPnViewOption]);
        } else if (fieldConfig.tableDisplayMode === 'ufs' && viewOptions.selectedUfsOption !== 'All') {
            setDisplayCustomSelection(true);
            setCustomSelectionLabel(ufsOptions[viewOptions.selectedUfsOption]);
        } else if (fieldConfig.tableDisplayMode === 'family') {
            if (viewOptions.selectedFamViewAliOption === 'All' && viewOptions.selectedFamViewOption === 'All') {
                setDisplayCustomSelection(false);
            } else if (viewOptions.selectedFamViewAliOption !== 'All' && viewOptions.selectedFamViewOption !== 'All') {
                setDisplayCustomSelection(true);
                setCustomSelectionLabel(famViewOptions[viewOptions.selectedFamViewOption] + ', ' + pnViewOptions[viewOptions.selectedFamViewAliOption]);
            } else if (viewOptions.selectedFamViewAliOption === 'All') {
                setDisplayCustomSelection(true);
                setCustomSelectionLabel(famViewOptions[viewOptions.selectedFamViewOption] + ', All Alignments per Patent Number');
            } else if (viewOptions.selectedFamViewOption === 'All') {
                setDisplayCustomSelection(true);
                setCustomSelectionLabel('All Patent Numbers per Family, ' + pnViewOptions[viewOptions.selectedFamViewAliOption]);
            }
        } else if ((fieldConfig.tableDisplayMode === 'ali' || fieldConfig.tableDisplayMode === 'npa'
                    || fieldConfig.tableDisplayMode === 'gname' || fieldConfig.tableDisplayMode === 'org')
                    && viewOptions.selectedGroupOption !== 'All') {
            setDisplayCustomSelection(true);
            setCustomSelectionLabel(viewOptions.selectedGroupOption + ' Alignment(s) per ' + fieldConfig.getTypeDescription(fieldConfig.tableDisplayMode));
        }
    }

    const countCheckedRecords = async(format) => {
        let javaCall = {
            data: {
                workflowId: resid.match(/\d+/)[0],
                userId: window.$javaCall.data.userId,
            }
        };
        let response = await DiscoverService.countCheckedRecords(javaCall.data, history, window.$javaCall.data.gqApiKey);
        if (response) {
            let count = parseInt(response.status);
            let msg = '';
            if (count > process.env.REACT_APP_EXPORT_LIMIT) {
                msg = "Maximum data export limit reached. 20,000 of " + count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " results will be exported.";
            }
            setSelectedAlertMessage(msg);
            setSelectedAlignmentsCount(count);
            setSelectedAlignmentsIds(response.selectedIds);

            setShowExportChoicesModal(true);
        }
    }

    const exportToExcel = (fieldConfig, filter, resInfo, format, exportSelection, exportOption) => {
        let columnsToDisplay = fieldConfig.getCurrentDisplayModeFieldInfo(fieldConfig)[0];
        if (hasLvaAccess && columnsToDisplay.includes("RESULT_VMH")
            && (format === "Word" || format === "Word Simple" || format === "HTML"
                || format === "EXCEL" || format === "CSV" || format === "TSV")) {
            setShowExportAllModal(false);
            setShowExportVmModal(true);
        } else if ((format === "Word" || format === "Word Simple" || format === "HTML")
            && hasLvaAccess && filter.lvaWidgetState && resInfo.options?.includeAli
            && filter.lvaWidgetState.lvaFilters && filter.lvaWidgetState.lvaFilters.length > 0) {
            setShowExportAllModal(false);
            setShowExportVmModal(true);
        } else {
            exportVmAlignments(fieldConfig, filter, resInfo, format, false, exportSelection, null, exportOption);
        }
    }

    const exportVmAlignments = async(fieldConfig, filter, resInfo, format, downloadVMGraphics, exportSelection, newTitle, exportOption) => {
        let groupCriteria = '', pnsPerPage = '', alignmentsPerPage = '';
        switch (fieldConfig.tableDisplayMode) {
            case 'family':
                groupCriteria = 'fam';
                pnsPerPage = fieldConfig.famViewOption;
                alignmentsPerPage = fieldConfig.famViewAliOption;
                break;
            case 'document':
                groupCriteria = 'patent';
                alignmentsPerPage = fieldConfig.pnViewOption;
                break;
            case 'flat':
                groupCriteria = 'alignment';
                break;
            case 'ufs':
                groupCriteria = 'ufs';
                alignmentsPerPage = fieldConfig.ufsOption;
                break;
            case 'cas':
                groupCriteria = 'cas';
                break;
            case 'ssv':
                groupCriteria = 'ssv';
                alignmentsPerPage = fieldConfig.ssvOption;
                break;
            case 'qs':
                groupCriteria = 'qs';
                alignmentsPerPage = fieldConfig.qsOption;
                break;
            case 'hsp':
                groupCriteria = 'hsp';
                alignmentsPerPage = fieldConfig.hspOption;
                break;
            case 'ali':
            case 'npa':
            case 'org':
            case 'gname':
                groupCriteria = fieldConfig.tableDisplayMode;
                alignmentsPerPage = fieldConfig.groupOption;
                break;
            default:
                break;
        }
        Object.assign(filterState, filter.state);
        setFilterState({...filterState});
        if (downloadVMGraphics) {
            setShowExportVmModal(false);

            let call = {
                data: {
                    workflowId: resid.match(/\d+/)[0],
                    userId: window.$javaCall.data.userId,
                    groupCriteria: groupCriteria,
                    pnsPerPage: pnsPerPage,
                    alignmentsPerPage: alignmentsPerPage,
                    sortCriteria: filter.state.sort,
                    sortCriterias: fieldConfig.populateSortCriterias(filter.state.sort),
                    vmExport: true,
                    pageNum: 1
                }
            };
            applyFilterForJava(call);
            let response = await DiscoverService.getSummary(call.data, window.$javaCall.data.gqApiKey);
            if (response) {
                console.log("Received VM records at " + new Date().toLocaleString());
                let newData = fieldConfig.javaObjectConverter(response, groupCriteria, querydb.SEQUENCE_ID, fieldConfig);
                let gqAlignments = [], gqAlignmentsNoSumm = [], gqAlignmentsStrNoSumm = [], gqAlignmentsSumm = [], gqAlignmentsVariation = [];
                populateGqAlignments(filter, newData.data, gqAlignments, gqAlignmentsNoSumm, gqAlignmentsStrNoSumm, gqAlignmentsSumm, gqAlignmentsVariation);
                console.log("Finish populating GQ alignments at " + new Date().toLocaleString());
                exportDocs(fieldConfig, filter, filterState, resInfo, format, gqAlignmentsStrNoSumm, gqAlignmentsSumm,
                    gqAlignmentsVariation, downloadVMGraphics, groupCriteria, pnsPerPage, alignmentsPerPage, exportSelection, newTitle, exportOption);
            }
        } else {
            exportDocs(fieldConfig, filter, filterState, resInfo, format, searchResultData.gqAlignmentsStrNoSumm, searchResultData.gqAlignmentsSumm,
                searchResultData.gqAlignmentsVariation, downloadVMGraphics, groupCriteria, pnsPerPage, alignmentsPerPage, exportSelection, newTitle, exportOption);
        }
    }

    const exportDocs = async(fieldConfig, filter, filterState, resInfo, format, gqAlignmentsStrNoSumm, gqAlignmentsSumm, gqAlignmentsVariation,
                             downloadVMGraphics, groupCriteria, pnsPerPage, alignmentsPerPage, exportSelection, newTitle, exportOption) => {
        let fileType, fileExtension, downloadApi;
        if (format === 'EXCEL') {
            fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            fileExtension = '.xlsx';
            downloadApi = 'exportExcel';
        } else if (format === 'CSV') {
            fileType = 'text/csv;charset=utf-8;';
            fileExtension = '.csv';
            downloadApi = 'exportCsv';
        } else if (format === 'TSV') {
            fileType = 'text/tab-separated-values';
            fileExtension = '.tsv';
            downloadApi = 'exportTsv';
        } else if (format === 'PNL' || format === 'Publication Number List'
            || format === 'PNFIL' || format === 'Publication Number, Family ID List') {
            fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            fileExtension = '.xlsx';
            downloadApi = 'exportPNxlsx';
        } else if (format === 'Word') {
            fileType = 'application/msword';
            fileExtension = '.doc';
            downloadApi = 'exportWord';
        } else if (format === 'Word Simple') {
            fileType = 'application/msword';
            fileExtension = '.doc';
            downloadApi = 'exportWordSimple';
        } else if (format === 'Fastafrag') {
            fileType = 'text/plain';
            fileExtension = '.txt';
            downloadApi = 'exportAlign';
        } else if (format === 'Fastasub' ) {
            fileType = 'text/plain';
            fileExtension = '.txt';
            downloadApi = 'exportSub';
        } else if (format === 'XML') {
            fileType = 'application/xml';
            fileExtension = '.xml';
            downloadApi = 'exportToXml';
        } else if (format === 'BizInt') {
            fileType = 'data/bizint-patent-data';
            fileExtension = '.bpd';
            downloadApi = 'exportToXml';
        } else if (format === 'Geneious') {
            fileType = 'data/geneious';
            fileExtension = '.geneious';
            downloadApi = 'exportToGeneious';
        } else if (format === 'LifeQuest') {
            downloadApi = 'exportToLQ';
        } else if (format === 'FullText' || format === 'Patbase') {
            downloadApi = 'exportToPatbase';
        } else if (format === 'HTML') {
            fileType = 'text/html';
            fileExtension = '.html';
            downloadApi = 'exportWord';
        } else if (format === 'Clustal') {
            fileType = 'text/html';
            fileExtension = '.html';
            downloadApi = 'exportClustalo';
        }

        let javaCall = {
            data: {
                workflowId: resid.match(/\d+/)[0],
                userId: window.$javaCall.data.userId,
                groupCriteria: groupCriteria,
                pnsPerPage: pnsPerPage,
                alignmentsPerPage: alignmentsPerPage,
                sortCriteria: filterState.sort,
                sortCriterias: fieldConfig.populateSortCriterias(filterState.sort),
                pageNum: 1,
                title: resInfo.title,
                vmExport: downloadVMGraphics,
                algorithm: resInfo.algorithm,
                exportType: format,
                isAbWorkflow: isAbWorkflow,
                newLink: true,
                firstName: userInfo.current_user?.first_name,
                lastName: userInfo.current_user?.last_name,
                loginName: userInfo.current_user?.login_name,
                accountingGroup: userInfo.current_user?.accounting_group_name,
                auditInfo: resInfo.options?.auditInfo,
                auditOnTop: resInfo.options?.auditOnTop,
                overview: resInfo.options?.overview,
                includeResult: resInfo.options?.includeResult,
                includeAli: resInfo.options?.includeAli,
                clipAli: resInfo.options?.clipAli,
                excludeClaim: resInfo.options?.excludeClaim,
                excludeFamilyData: resInfo.options?.excludeFamilyData,
                includeAnnotation: resInfo.options?.includeAnnotation,
                includeSS: resInfo.options?.includeSS,
                includeQS: resInfo.options?.includeQS,
            }
        };
        let contentType;
        if (format === 'EXCEL' || format === 'CSV' || format === 'TSV' || format === 'PNL' || format === 'PNFIL'
            || format === 'Publication Number List' || format === 'Publication Number, Family ID List') {
            javaCall['responseType'] = 'arraybuffer';
            contentType = 'arraybuffer';
        }
        applyFilterForJava(javaCall);

        if (format === 'Word' || format === "Word Simple" || format === "HTML"
            || format === 'EXCEL' || format === 'CSV' || format === 'TSV') {
            javaCall.data.workflowUrl = process.env.REACT_APP_URL + 'discover/' + resid;
            javaCall.data.columnsToDisplay = fieldConfig.getCurrentDisplayModeFieldInfo(fieldConfig)[0];
            let columns = fieldConfig.getColumnsInfoForExport(fieldConfig);
            javaCall.data.groupedColumns = columns[0];
            javaCall.data.detailedColumns = columns[1];
            javaCall.data.gqAlignments = {};
            javaCall.data.gqAlignmentSummaries = {};
            javaCall.data.vmHits = {};
            if (downloadVMGraphics) {
                for (let idx in gqAlignmentsStrNoSumm) {
                    for (let key in gqAlignmentsStrNoSumm[idx]) {
                        javaCall.data.gqAlignments[key] = gqAlignmentsStrNoSumm[idx][key];
                    }
                }
                // Variation hits
                for (let idx in gqAlignmentsSumm) {
                    for (let key in gqAlignmentsSumm[idx]) {
                        javaCall.data.gqAlignmentSummaries[key] = [];
                        let vmHits = '';
                        for (let sIdx in gqAlignmentsSumm[idx][key]) {
                            let obj = {};
                            obj.pos = gqAlignmentsSumm[idx][key][sIdx].pos;
                            obj.type = gqAlignmentsSumm[idx][key][sIdx].type;
                            // No idea why qChunk/sChunk could not be deserilized in backend, so using qchunk/schunk instead
                            obj.qchunk = gqAlignmentsSumm[idx][key][sIdx].qChunk;
                            obj.schunk = gqAlignmentsSumm[idx][key][sIdx].sChunk;
                            obj.description = gqAlignmentsSumm[idx][key][sIdx].description;
                            javaCall.data.gqAlignmentSummaries[key].push(obj);

                            if (vmHits !== '') {
                                vmHits += ', ';
                            }
                            if (format === 'EXCEL' || format === 'CSV' || format === 'TSV') {
                                vmHits += gqAlignmentsSumm[idx][key][sIdx].description;
                            } else {
                                vmHits += gqAlignmentsSumm[idx][key][sIdx].vmHits;
                            }
                        }
                        javaCall.data.vmHits[key] = vmHits;
                    }
                }
            }
            if (format === 'EXCEL' && Object.keys(filterState['smartHighlightFilter']).length > 0) {
                let highlightFilters = filterState['smartHighlightFilter'];
                javaCall.data.highlightFilters = {};
                let newFilters = {};
                Object.keys(highlightFilters).forEach(field => {
                    let subFilters = highlightFilters[field];
                    newFilters[field] = [];
                    Object.keys(subFilters).forEach(subKey => {
                        let filter = subFilters[subKey];
                        if (filter.value === true) {
                            let newFilter = {};
                            newFilter['text'] = filter.text;
                            newFilter['color'] = highlightColors[filter.color];
                            newFilters[field].push(newFilter);
                        }
                    });
                });
                javaCall.data.highlightFilters = newFilters;
                //console.log(javaCall.data.highlightFilters);
            }
        } else if (format === 'XML' || format === 'BizInt' || format === 'Geneious' || format === 'Fastafrag'
            || format === 'FullText' || format === 'Patbase' || format === 'LifeQuest' || format === 'Clustal') {
            javaCall.data.reachLimit = (exportSelection === 'all' && totalAlignmentNumber > process.env.REACT_APP_EXPORT_LIMIT);
            if (exportSelection === 'selected') {
                javaCall.data.selectedIds = selectedAlignmentsIds;
                javaCall.data.exportSelection = 'selected';
            } else if (exportSelection === 'custom') {
                javaCall.data.exportSelection = 'custom';
            }
            else if (exportSelection === 'all') {
                javaCall.data.exportSelection = 'all';
            }
            if (format === 'LifeQuest') {
                javaCall.data.lqAuthKey = lqAuthKey;
                javaCall.data.lqBaseUrl = lqBaseUrl;
                javaCall.data.loginName = loginName;
            } else if (format === 'Clustal') {
                if (exportOption === 'both') {
                    javaCall.data.includeBothSeqs = true;
                } else if (exportOption === 'qs') {
                    javaCall.data.includeQS = true;
                } else if (exportOption === 'ss') {
                    javaCall.data.includeSS = true;
                } else {
                    javaCall.data.includeSubjFragments = true;
                }
            }
        }

        closeExportModal();
        let response;
        if (format === 'Clustal') {
            response = await DiscoverService.exportClustal({data: javaCall.data});
        } else {
            response = await DiscoverService.exportDocs(javaCall.data, downloadApi, window.$javaCall.data.gqApiKey, contentType);
        }
        if (response) {
            if (format === 'LifeQuest') {
                window.open(process.env.REACT_APP_BASE_URL + 'query?do=gqredirect.lq&lq_ca=patentList/workfileInProgress&id=lq');
                return;
            } else if (format === 'Patbase') {
                console.log("Received PN list at " + new Date().toLocaleString());
                let result = response.exportResults;
                let pns = _.split(result, ",");
                setPatbasePnsStr(result);
                setPatbasePns(pns);
                setTotalPatbasePns(pns.length);
                let countdown = 3;
                pbTimer = setInterval(() => {
                    (async () => {
                        setPbCountdown(countdown--);
                        if (countdown === 0) {
                            closeExportModal();
                        }
                    })();
                }, 1000);
                setShowPatbaseModal(true);
                return;
            } else if (format === 'FullText') {
                console.log("Received PN list at " + new Date().toLocaleString());
                let ftwindow = window.open(process.env.REACT_APP_URL + "fulltextsearch");
                ftwindow.data = {};
                ftwindow.data.exportData = JSON.stringify(response.exportResults).replace(/"/ig, '');
                //await navigator.clipboard.writeText(response.exportResults);
                return;
            } else if (format === 'Clustal') {
                //localStorage.setItem('clustalo_' + response.response_id, response.response_content);
                const url = "#/clustaloreport/" + response.response_id;
                const newWindow = window.open(url, '_blank');
                newWindow.reportString = response.response_content;
                if (response.response_content && response.response_content.type === 'GQEXCEPTION') {
                    newWindow.message = response.response_content.message;
                } else if (response.response_content && response.response_content.type === 'GQSCRIPTGENERALEXCEPTION') {
                    newWindow.message = "Error: " + response.response_content.message + " Please retry with smaller and fewer sequences.";
                }
                newWindow.window.focus();
                return;
            }
            let blob;
            if (format === 'EXCEL' || format === 'CSV' || format === 'TSV' || format === 'PNL' || format === 'PNFIL') {
                blob = new Blob([response], {type: fileType});
            } else if (format === 'Clustal') {
                blob = new Blob([(response.response_content)], {type: fileType});
            } else {
                blob = new Blob([(response.exportResults)], {type: fileType});
            }

            let link = document.createElement("a");
            let fileURL = URL.createObjectURL(blob);
            link.setAttribute("href", fileURL);
            let fileName = _.replace(resInfo.title, new RegExp('/[^\-_a-zA-Z0-9\.]+/', 'g'), '_');
            link.setAttribute("download", fileName + fileExtension);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else if (format === 'LifeQuest') {
            window.open(process.env.REACT_APP_BASE_URL + 'query?do=gqredirect.lq&lq_ca=patentList/workfileInProgress&id=lq');
            return;
        }
    }

    const closeExportModal = () => {
        setShowExportAllModal(false);
        setShowExportChoicesModal(false);
        setShowExportVmModal(false);
        setShowPatbaseModal(false);
        if (pbTimer) {
            if (document.getElementById('pb_pn_list')) {
                document.getElementById('pb_pn_list').submit();
            }
            clearInterval(pbTimer);
        }
    }
    let dedDatabasePrefsForUI = [];
      /* ===== Function to show/hide duplicate records ==== */
      function displayDedpreferences (resultFilter) {
        
        let tmpDedDatabasePrefsForUI = [];
        tmpDedDatabasePrefsForUI.push('GQPat'); // Keeps GQPat as the first choice
        //console.log(resultFilter,"resultFilter")
        
        resultFilter.dbs.forEach(function (value) {
            var name = getPrefName(value);
            if (tmpDedDatabasePrefsForUI.length === 0 || tmpDedDatabasePrefsForUI.indexOf(name) < 0) {
                tmpDedDatabasePrefsForUI.push(name);
            }
        });
        
        if (!dedDatabasePrefs || dedDatabasePrefs.length === 0) {
            // Initialized from the user accessible database list
            setDeduplication(false);
            filter.state.deduplication = false;
            dedDatabasePrefsForUI = _.cloneDeep(tmpDedDatabasePrefsForUI);
        } else {
            if (dedDatabasePrefs.length !== tmpDedDatabasePrefsForUI.length) {
                dedDatabasePrefsForUI = _.cloneDeep(tmpDedDatabasePrefsForUI);
            } else {
                dedDatabasePrefsForUI = _.cloneDeep(dedDatabasePrefs);
            }
        }
        setDedDataBasePrefsForUI(dedDatabasePrefsForUI);
        setDedupModal(true);
    };

    const closeDedupModal = () => {
        setDedupModal(false);
    }

    const saveDedup = (filter, fieldConfig, dedDataBasePrefsForUI) => {
        setDedDatabasePrefs(_.cloneDeep(dedDataBasePrefsForUI));
        filter.state.dedDatabasePrefs = _.cloneDeep(dedDataBasePrefsForUI);
        if (editable) {
            saveResultState(filter, fieldConfig);
        }
        setDedupModal(false);
    }

    const openSaveResultsModal = (open) => {
        if (open) {
            setDownloadType("SaveResultAs");
            setSaveAsName("copy of " + resInfo['title']);
            setShowSaveResultsModal(true);
        } else {
            setShowSaveResultsModal(false);
        }
    }

    /* ====== Functions to manage ESF sorting options ======================================= */
    fieldConfig.customSortingFields.PFD_PA = Authorities.loadAuthorities();
    let saveAndApplySort = false;
    function errorsInSortOption(saveAndApply,sortname) {
        saveAndApplySort = saveAndApply;
        //fieldConfig.addSortOption(sortname);
        filter.state.sort=sortname;
        loadResultsFromBackend(false);
        saveAndApplySort = false;
    };

    function deleteSortOption(fieldConfig, filter){
        if (fieldConfig.sortOptionName === filter.state.sort){
            filter.state.sort = 'high_percid_query';
        }
        fieldConfig.deleteSortOption(fieldConfig, filter);
        if (editable === true) {
            saveResultState(filter, fieldConfig);
        }
        if (fieldConfig.sortOptionName === filter.state.sort){
            loadResultsFromBackend(false);
        }
    };

    function manageSortOptions() {
        setManageSortModal(true);
        saveAndApplySort = false;
        fieldConfig.sortOptionName = '';
        fieldConfig.sortOptions = [];
        fieldConfig.sortOptions[0] = fieldConfig.newSortingAtom();
    }


    function closeManageSortModal() {
        saveAndApplySort = false;
        setManageSortModal(false);
    }

    const saveFilteredResult = async(fieldConfig, filter, resInfo, title, format, choice) => {
        setShowSaveResultsModal(false);
        let javaCall = {
            data: {
                'workflow_type': 'GqWfAlndbCopy',
                'groupCriteria': 'alignment',
                'parent_id': resid.match(/\d+/)[0],
                'ttlAlignments': viewCount.RESULT_ID,
                'createVm': hasLvaAccess && lvaLoadingReady,
                'title': title,
                'firstName': userInfo.current_user?.first_name,
                'lastName': userInfo.current_user?.last_name,
                'loginName': userInfo.current_user?.login_name,
                'accountingGroup': userInfo.current_user?.accounting_group_name,
            }
        };
        Object.assign(filterState, filter.state);
        setFilterState({...filterState});
        applyFilterForJava(javaCall);
        let response = await DiscoverService.saveFilteredResult(javaCall.data);
        if (response) {
            let id = response.response_content;
            setSaveFiltedResultMsg(process.env.REACT_APP_URL + 'discover/wf:' + id + '.resdb');
            setShowSaveResultsSucModal(true);
        }
    }


    const saveFilteredResultBundle = async(filter,title,joinedEmails) => {
        let wftitle = 'SharedResult ' + resInfo['title'];

        let domaincheck = await ftAccess.checkDomains(joinedEmails);
        if((domaincheck.response_content[0] == "same" && userInfo.current_user.ppu_type == 0) ||
        userInfo.current_user.ppu_type == 2 ){
            toast.info("Please wait while the workflow is being shared...", {
                autoClose: 5000,  
              });  
            let javaCall = {
                data: {
                    'workflow_type': 'GqWfAlndbCopy',
                    'groupCriteria': 'alignment',
                    'parent_id': resid.match(/\d+/)[0],
                    'ttlAlignments': viewCount.RESULT_ID,
                    'createVm': hasLvaAccess && lvaLoadingReady,
                    'title': wftitle,
                    'firstName': userInfo.current_user?.first_name,
                    'lastName': userInfo.current_user?.last_name,
                    'loginName': userInfo.current_user?.login_name,
                    'accountingGroup': userInfo.current_user?.accounting_group_name,
                }
            };
            Object.assign(filterState, filter.state);
            setFilterState({...filterState});
            applyFilterForJava(javaCall);
            let response = await DiscoverService.saveFilteredResult(javaCall.data);
            if (response) {
                let id = response.response_content;
                let parentId = resid.match(/\d+/)[0];
                const getaddShareResponse = await ftAccess.addSharedEmail(id, parentId, joinedEmails);
                if (getaddShareResponse && getaddShareResponse.response_status == 0) {
                    toast.success('Workflow Shared with the user');

                } else if(getaddShareResponse && getaddShareResponse.response_status == 1) {
                    const myString = getaddShareResponse.response_content.message;

                    if (typeof myString === 'string' && myString.startsWith("Non unique")){
                        toast.error("Email id is not unique, and has multiple GQ accounts");  
                    }
                    else{
                        toast.error(getaddShareResponse.response_content.message);  
                    }
                            
                }
                else{	
                    toast.error('Adding in Error.');
                    }
                
            }
        }
        else{	
            toast.error('Email not in same domain.');
            }
    
}

    const closeSaveResultsSucModal = () => {
        setShowSaveResultsModal(false);
        setShowSaveResultsSucModal(false);
    }

    const displayItemsView = (dbkey1, dbkey2, parentGroup, fieldConfig, filter) => {
        let value1 = '', value2 = '';
        if (dbkey1 && dbkey1 !== '') {
            value1 = dbkey1.substr(dbkey1.indexOf('_') + 1, dbkey1.length);
            if (value1 === 'NULL' || value1 === 'undefined') {
                value1 = '';
            }
            let type = dbkey1.substr(0, dbkey1.indexOf('_'));
            if (type === 'hsp') {
                // Special case for hsp, set value1=qframe_queryId, value2=sframe_subjectId
                let qframe = value1.substr(0, value1.indexOf('_'));
                let sframe = value1.substr(value1.indexOf('_') + 1);
                // parentGroup.RESULT_FQ is a converted string, so use qframe passed from backend instead,
                // same case for parentGroup.RESULT_NFD
                value1 = qframe + "_" + parentGroup.QUERY_ID;
                value2 = sframe + "_" + parentGroup.SUBJECT_ID;
            } else if (type === 'ali') { // for ALI grouping
                // Special case for Alignment grouping, set value1=queryId, value2=FQ_OOBQ_FD_OOBD_S.L_S.D
                value2 = value1;
                value1 = parentGroup.QUERY_ID;
            }
        } else {
            console.log('empty dbkey for more items.');
            return;
        }
        if (dbkey2 && dbkey2 !== '') {
            value2 = dbkey2.substr(dbkey2.indexOf('_') + 1, dbkey2.length);
            if (value2 === 'NULL' || value2 === 'undefined') {
                value2 = '';
            }
        }
        setGroupValue1(value1);
        setGroupValue2(value2);
        fetchItemsNow(fieldConfig, filter, value1, value2, 1);
    }

    const changeItemsPage = async (e, page, fieldConfig, filter, groupValue1, groupValue2) => {
        if (page) {
            fetchItemsNow(fieldConfig, filter, groupValue1, groupValue2, page);
        }
    }

    function createMoreItemsFilter(fieldConfig, groupValue1, groupValue2) {
        let newItemFilter = {};
        newItemFilter["filterMode"] = "AND";
        let criterias = [];
        let newContent = {};
        newContent["fieldType"] = "string";
        newContent["fieldOperator"] = "EQUALS";
        switch (fieldConfig.tableDisplayMode) {
            case 'family':
                newItemFilter["filterName"] = "familyGroupedItems";
                newContent["fieldName"] = "SUBJECT_PB";
                newContent["fieldValue"] = groupValue1;
                criterias.push(newContent);
                if (groupValue2 && groupValue2 !== '') {
                    newContent = {};
                    newContent["fieldName"] = "SUBJECT_PN";
                    newContent["fieldType"] = "string";
                    newContent["fieldOperator"] = "EQUALS";
                    newContent["fieldValue"] = groupValue2;
                    criterias.push(newContent);
                }
                break;
            case 'document':
                newItemFilter["filterName"] = "docGroupedItems";
                newContent["fieldName"] = "SUBJECT_PN";
                newContent["fieldValue"] = groupValue1;
                criterias.push(newContent);
                break;
            case 'ufs':
                newItemFilter["filterName"] = "ufsGroupedItems";
                newContent["fieldName"] = "SUBJECT_PJ";
                newContent["fieldValue"] = groupValue1;
                criterias.push(newContent);
                if (groupValue2 && groupValue2 !== '') {
                    newContent = {};
                    newContent["fieldName"] = "SUBJECT_PB";
                    newContent["fieldType"] = "string";
                    newContent["fieldOperator"] = "EQUALS";
                    newContent["fieldValue"] = groupValue2;
                    criterias.push(newContent);
                }
                break;
            case 'cas':
                newItemFilter["filterName"] = "casGroupedItems";
                newContent["fieldName"] = "SUBJECT_RG";
                newContent["fieldValue"] = groupValue1;
                criterias.push(newContent);
                break;
            case 'ssv':
                newItemFilter["filterName"] = "ssvGroupedItems";
                newContent["fieldName"] = "SUBJECT_ID";
                newContent["fieldValue"] = groupValue1;
                criterias.push(newContent);
                break;
            case 'qs':
                newItemFilter["filterName"] = "qsGroupedItems";
                newContent["fieldName"] = "QUERY_ID";
                newContent["fieldValue"] = groupValue1;
                criterias.push(newContent);
                break;
            case 'npa':
                newItemFilter["filterName"] = "npaGroupedItems";
                newContent["fieldName"] = "SUBJECT_PU";
                newContent["fieldValue"] = groupValue1;
                criterias.push(newContent);
                break;
            case 'org':
                newItemFilter["filterName"] = "orgGroupedItems";
                newContent["fieldName"] = "SUBJECT_OS";
                newContent["fieldValue"] = groupValue1;
                criterias.push(newContent);
                break;
            case 'gname': // only a prefix for Subject database name
                newItemFilter["filterName"] = "gnameGroupedItems";
                newContent["fieldName"] = "SUBJECT_GNAME";
                newContent["fieldValue"] = groupValue1;
                criterias.push(newContent);
                break;
            case 'ali':
                newItemFilter["filterName"] = "aliGroupedItems";
                newContent["fieldName"] = "QUERY_ID";
                newContent["fieldValue"] = groupValue1;
                criterias.push(newContent);

                // groupValue2=FQ_OOBQ_FD_OOBD_S.L_RID
                const values = _.split(groupValue2, "_");
                newContent = {};
                newContent["fieldName"] = "RESULT_FQ";
                newContent["fieldType"] = "string";
                newContent["fieldOperator"] = "EQUALS";
                newContent["fieldValue"] = values[0];
                criterias.push(newContent);

                newContent = {};
                newContent["fieldName"] = "RESULT_OOBQ";
                newContent["fieldType"] = "int";
                newContent["fieldOperator"] = "EQUALS";
                newContent["fieldValue"] = values[1];
                criterias.push(newContent);

                newContent = {};
                newContent["fieldName"] = "RESULT_NFD";
                newContent["fieldType"] = "string";
                newContent["fieldOperator"] = "EQUALS";
                newContent["fieldValue"] = values[2];
                criterias.push(newContent);

                newContent = {};
                newContent["fieldName"] = "RESULT_OOBD";
                newContent["fieldType"] = "int";
                newContent["fieldOperator"] = "EQUALS";
                newContent["fieldValue"] = values[3];
                criterias.push(newContent);

                newContent = {};
                newContent["fieldName"] = "SUBJECT_L";
                newContent["fieldType"] = "int";
                newContent["fieldOperator"] = "EQUALS";
                newContent["fieldValue"] = values[4];
                criterias.push(newContent);

                if (values.length > 5 && values[5] && values[5] !== '') {
                    newContent = {};
                    newContent["fieldName"] = "RESULT_SD";
                    newContent["fieldType"] = "string";
                    newContent["fieldOperator"] = "EQUALS";
                    newContent["fieldValue"] = values[5];
                    criterias.push(newContent);
                }
                break;
            case 'hsp':
                let groupValue3 = groupValue1.substr(groupValue1.indexOf('_') + 1);
                groupValue1 = groupValue1.substr(0, groupValue1.indexOf('_'));
                newItemFilter["filterName"] = "hspGroupedItems";
                newContent["fieldName"] = "QUERY_ID";
                newContent["fieldValue"] = groupValue3;
                criterias.push(newContent);

                newContent = {};
                newContent["fieldName"] = "RESULT_FQ";
                newContent["fieldType"] = "string";
                newContent["fieldOperator"] = "EQUALS";
                newContent["fieldValue"] = groupValue1;
                criterias.push(newContent);

                let groupValue4 = groupValue2.substr(groupValue2.indexOf('_') + 1);
                groupValue2 = groupValue2.substr(0, groupValue2.indexOf('_'));
                newContent = {};
                newContent["fieldName"] = "SUBJECT_ID";
                newContent["fieldType"] = "string";
                newContent["fieldOperator"] = "EQUALS";
                newContent["fieldValue"] = groupValue4;
                criterias.push(newContent);

                newContent = {};
                newContent["fieldName"] = "RESULT_NFD";
                newContent["fieldType"] = "string";
                newContent["fieldOperator"] = "EQUALS";
                newContent["fieldValue"] = groupValue2;
                criterias.push(newContent);
                break;
            default:
                break;
        }
        newItemFilter["fieldCriterias"] = criterias;
        return newItemFilter;
    }

    const fetchItemsNow = async(fieldConfig, filter, groupValue1, groupValue2, itemsCurrentPage) => {
        setItemsCurrentPage(itemsCurrentPage);
        Object.assign(filterState, filter.state);
        setFilterState({...filterState});

        let call = {
            data: {
                workflowId: resid.match(/\d+/)[0],
                userId: window.$javaCall.data.userId,
                groupCriteria: 'alignment',
                sortCriteria: filterState.sort,
                sortCriterias: fieldConfig.populateSortCriterias(filterState.sort),
                pageNum: itemsCurrentPage
            }
        };
        applyFilterForJava(call);
        let newItemFilter = createMoreItemsFilter(fieldConfig, groupValue1, groupValue2);
        if (call.data["customFilters"] && Object.keys(call.data["customFilters"]).length > 0) {
            call.data["customFilters"].push(newItemFilter);
        } else {
            call.data["customFilters"] = [];
            call.data["customFilters"].push(newItemFilter);
        }

        let response = await DiscoverService.getItems(call.data, window.$javaCall.data.gqApiKey, history);
        if (response) {
            let newData = fieldConfig.javaObjectConverter(response, 'alignment', querydb.SEQUENCE_ID, fieldConfig);
            let itemsData = {};
            itemsData.data = newData.data;
            itemsData.itemsStats = newData.stats;
            itemsData.itemsStats.count = [];
            itemsData.itemsStats.count.ali = newData.stats.nav.total_count;

            // restores checkboxes, colors, stars
            itemsAnnotState.checkboxes = {};
            for (let idx in newData.checkboxes) {
                itemsAnnotState.checkboxes[idx] = newData.checkboxes[idx];
            }
            itemsAnnotState.colors = {};
            for (let idx in newData.colors) {
                itemsAnnotState.colors[idx] = newData.colors[idx];
            }
            itemsAnnotState.stars = {};
            for (let idx in newData.stars) {
                itemsAnnotState.stars[idx] = newData.stars[idx];
            }
            itemsAnnotState.notes = {};
            for (let idx in newData.notes) {
                itemsAnnotState.notes[idx] = newData.notes[idx];
            }
            itemsAnnotState.dbkeys = {};
            for (let idx in newData.data) {
                itemsAnnotState.dbkeys[newData.data[idx]['dbkey']] = newData.data[idx]["RESULT_ID"];
            }

            if (itemsData && itemsData.data) {
                prepClaimsCollapse(itemsData, 'flat', claimsCollapseAll);
                prepAlignmentsCollapse(itemsData, 'flat', alignmentsCollapseAll);
                prepDetailPageCollapse(itemsData, 'flat', detailPageCollapseAllLevelOne, detailPageCollapseAllLevelTwo, detailPageCollapseAllLevelThree, itemsAnnotState);
            }
            itemsAnnotState.claimsCollapse = claimsCollapse;
            itemsAnnotState.claimsCollapseLess = {};
            itemsAnnotState.alignmentsCollapse = alignmentsCollapse;

            let gqAlignments = [], gqAlignmentsNoSumm = [], gqAlignmentsStrNoSumm = [], gqAlignmentsSumm = [], gqAlignmentsVariation = [];
            populateGqAlignments(filter, newData.data, gqAlignments, gqAlignmentsNoSumm, gqAlignmentsStrNoSumm, gqAlignmentsSumm, gqAlignmentsVariation);

            // For the table display
            setItemsTotalPage(response.lastPage);
            itemsData.displayMode = 'flat';
            annotState.claimsCollapse = claimsCollapse;
            annotState.claimsCollapseLess = {};
            annotState.alignmentsCollapse = alignmentsCollapse;
            itemsData.annotState = itemsAnnotState;
            itemsData.gqAlignments = gqAlignments;
            itemsData.gqAlignmentsNoSumm = gqAlignmentsNoSumm;
            itemsData.gqAlignmentsStrNoSumm = gqAlignmentsStrNoSumm;
            itemsData.gqAlignmentsSumm = gqAlignmentsSumm;
            itemsData.gqAlignmentsVariation = gqAlignmentsVariation;
            itemsData.docItems = docItems;
            itemsData.docItemsSelected = docItemsSelected;
            itemsData.algorithm = resInfo.algorithm;

            let itemsViewFields = [];
            if (fieldConfig.tableDisplayMode === 'family') {
                itemsViewFields = fieldConfig.userTableTemplate['fam'].concat(fieldConfig.userTableTemplate['doc']).concat(fieldConfig.userTableTemplate['sin']);
            } else if (fieldConfig.tableDisplayMode === 'document') {
                itemsViewFields = fieldConfig.userTableTemplate['doc'].concat(fieldConfig.userTableTemplate['sin']);
            } else if (fieldConfig.tableDisplayMode === 'ufs') {
                itemsViewFields = fieldConfig.userTableTemplate['ufs'].concat(fieldConfig.userTableTemplate['fam']).concat(fieldConfig.userTableTemplate['flat']);
            } else if (fieldConfig.tableDisplayMode === 'cas') {
                if (fieldConfig.userTableTemplate['cas'] === undefined || fieldConfig.userTableTemplate['cas'] === 'undefined') {
                    itemsViewFields = fieldConfig.defaultUserTableTemplate['cas'].concat(fieldConfig.userTableTemplate['flat']);
                } else {
                    itemsViewFields = fieldConfig.userTableTemplate['cas'].concat(fieldConfig.userTableTemplate['flat']);
                }
            } else if (fieldConfig.tableDisplayMode === 'ssv') {
                if (fieldConfig.userTableTemplate['ssv'] === undefined || fieldConfig.userTableTemplate['ssv'] === 'undefined') {
                    itemsViewFields = fieldConfig.defaultUserTableTemplate['ssv'].concat(fieldConfig.userTableTemplate['sin']);
                } else {
                    itemsViewFields = fieldConfig.userTableTemplate['ssv'].concat(fieldConfig.userTableTemplate['sin']);
                }
            } else if (fieldConfig.tableDisplayMode === 'qs') {
                if (fieldConfig.userTableTemplate['qs'] === undefined || fieldConfig.userTableTemplate['qs'] === 'undefined') {
                    itemsViewFields = fieldConfig.defaultUserTableTemplate['qs'].concat(fieldConfig.userTableTemplate['sin']);
                } else {
                    itemsViewFields = fieldConfig.userTableTemplate['qs'].concat(fieldConfig.userTableTemplate['sin']);
                }
            } else if (fieldConfig.tableDisplayMode === 'hsp') {
                if (fieldConfig.userTableTemplate['hsp'] === undefined || fieldConfig.userTableTemplate['hsp'] === 'undefined') {
                    itemsViewFields = fieldConfig.defaultUserTableTemplate['hsp'].concat(fieldConfig.userTableTemplate['sin']);
                } else {
                    itemsViewFields = fieldConfig.userTableTemplate['hsp'].concat(fieldConfig.userTableTemplate['sin']);
                }
            } else {
                if (fieldConfig.userTableTemplate[fieldConfig.tableDisplayMode] === undefined
                    || fieldConfig.userTableTemplate[fieldConfig.tableDisplayMode] === 'undefined') {
                    itemsViewFields = fieldConfig.defaultUserTableTemplate[fieldConfig.tableDisplayMode].concat(fieldConfig.userTableTemplate['sin']);
                } else {
                    itemsViewFields = fieldConfig.userTableTemplate[fieldConfig.tableDisplayMode].concat(fieldConfig.userTableTemplate['sin']);
                }
            }
            itemsData.columns = itemsViewFields;

            let width = 310;
            itemsViewFields.forEach(function (value) {
                width += parseInt(fieldConfig.fieldConfig[value].colwidth) + 20;
            });
            itemsData.tableWidth = width;

            setItemsAnnotState(itemsAnnotState);
            setItemsData(itemsData);
            setShowItemsModal(true);
        }

    }

    const refreshAnnotState = (notesState) => {
        setAnnotState(notesState);
    }

    const closeItemsModal = () => {
        setAnnotState({...annotState});
        setShowItemsModal(false);
    }

    const closeFilterModal = () => {
        setAddFilterPopup(false);
    }

    const closeDeleteFilterModal = () =>{
        setDeleteFilterPopup(false);
    }

    const saveFilter = (filter, fieldConfig, sideMenuDetail) => {
        // Save the filter into database
        filter.addCustomFilterToList(filter, fieldConfig);
        enableApplyButton();
        setAddFilterPopup(false);
        if (fieldConfig.showAlert) {
            // Show the alert message
            popupInfoMsg(fieldConfig.alertMsg);
        } else {
            UIlog.record(resid, 'CUSTOMFILTER_NEW', filter.widgetState.customFilterName);
            filter.widgetState = {...filter.widgetState};
            filter.state = {...filter.state};

            // Open the filter list when adding a new one
            setWidgetState({...filter.widgetState});
            sideMenuDetail['mycustomfilter'] = true;
            setSideMenuDetail({...sideMenuDetail});

            // TODO, Save the result state?
            //saveResultState(filter, fieldConfig);
        }
    }

    const setFilterCheckbox = (filter, idx) => {
        enableApplyButton();
        if (!filter.state.customFilterCheckboxes) {
            filter.state.customFilterCheckboxes = [];
        }
        filter.state.customFilterCheckboxes[idx] = !filter.state.customFilterCheckboxes[idx];
        Object.assign(filterState, filter.state);
        setFilterState({...filterState});
    }

    const startFilteringWidget = (filter, type, idx) => {
        if (type === 'default') {
            // View/Edit default filter
            widgetState.widgetContent = _.cloneDeep(filter.widgetState.defaultCustomFilters[idx].widgetContent);
            widgetState.widgetMode = filter.widgetState.defaultCustomFilters[idx].widgetMode;
            widgetState.widgetCustomQuery = filter.widgetState.defaultCustomFilters[idx].widgetCustomQuery;
            widgetState.customFilterName = filter.widgetState.defaultCustomFilters[idx].customFilterName;
        } else if (type === 'custom') {
            // View/Edit custom filter
            widgetState.widgetContent = _.cloneDeep(filter.widgetState.customFilters[idx].widgetContent);
            widgetState.widgetMode = filter.widgetState.customFilters[idx].widgetMode;
            widgetState.widgetCustomQuery = filter.widgetState.customFilters[idx].widgetCustomQuery;
            widgetState.customFilterName = filter.widgetState.customFilters[idx].customFilterName;
        } else {
            // Create new filter
            widgetState.widgetContent = [{field: 'ALL_TEXT', field_type: 'string', operator: 'CONTAINS', value: '', second_value: ''}];
            widgetState.widgetMode = 'AND';
            widgetState.widgetCustomQuery = '';
            widgetState.customFilterName = '';
        }
        setWidgetState({...widgetState});
        setAddFilterPopup(true);
    }

    const removeCustomFilterFromList = (filter, fieldConfig, idx) => {
        filter.lvaWidgetState = {...filter.lvaWidgetState};
        filter.widgetState = {...filter.widgetState}; // When saved as global
        filter.state = {...filter.state};

        setDeleteIdx(idx);
        setDeleteFilterPopup(true);
    }

    const removeCustomFilterFromListAction = (filter, fieldConfig, idx) => {
        let oldQuery = filter.query;
        let needReload = false;
        let uuid = filter.widgetState.customFilters[idx].uuid;
        for (let key in oldQuery.customFilters) {
            if (uuid === oldQuery.customFilters[key].uuid) {
                needReload = true;
                break;
            }
        }

        let defaultSize = filter.widgetState.defaultCustomFilters.length;
        filter.widgetState.customFilters.splice(idx, 1);
        filter.state.customFilterCheckboxes?.splice(idx + defaultSize, 1); // remove checkbox state as well
        //filter.widgetState.customFilterDeleted[idx + defaultSize] = true;
        setWidgetState({...filter.widgetState});

        if (singleAlignmentId === undefined && abExp === undefined && vmExp === undefined && reportId === undefined) {
            fieldConfig.saveConfig(fieldConfig, 'default', filter); // save
            saveResultState(filter, fieldConfig);
        }

        if (needReload) {
            loadResultsFromBackend(true);
        }
    }

    const updateCustomFiltersHover = (idx, val) => {
        customFiltersHover[idx] = val;
        setCustomFiltersHover([...customFiltersHover]);
    }

    const closeMessageModal = (fieldConfig) => {
        fieldConfig.showAlert = false;
        dismissInfoMsg();
    }

    const saveAnnotation = async(type, choice, annotated, dbkey, value,
                            annotState, filter, fieldConfig,
                            fromItemsPage, groupValue1, groupValue2, parentAnnotState) => {
        // Type = checkbox/star/color
        // Choice = single/thisPage/all/thisPageWhole/whole/thisGroup
        // annotated = true for Annotated/false for Unanotated
        // dbkey, used for single annotation
        // fromItemsPage, groupValue1, groupValue2, when comes from the 'More Items' page

        let groupCriteria, dbkeyPrefix, pnsPerPage = '', alignmentsPerPage = '';
        if (fromItemsPage) {
            groupCriteria = 'alignment';
            //currentPage = itemsCurrentPage;
        } else {
            switch (fieldConfig.tableDisplayMode) {
                case 'family':
                    groupCriteria = 'fam';
                    dbkeyPrefix = "fam_";
                    pnsPerPage = fieldConfig.famViewOption;
                    alignmentsPerPage = fieldConfig.famViewAliOption;
                    break;
                case 'document':
                    groupCriteria = 'patent';
                    dbkeyPrefix = "doc_";
                    alignmentsPerPage = fieldConfig.pnViewOption;
                    break;
                case 'flat':
                    groupCriteria = 'alignment';
                    dbkeyPrefix = "ali_";
                    break;
                case 'ufs':
                    groupCriteria = 'ufs';
                    dbkeyPrefix = "ufs_";
                    alignmentsPerPage = fieldConfig.ufsOption;
                    break;
                case 'cas':
                    groupCriteria = 'cas';
                    dbkeyPrefix = "cas_";
                    break;
                case 'ssv':
                    groupCriteria = 'ssv';
                    dbkeyPrefix = "ssv_";
                    alignmentsPerPage = fieldConfig.ssvOption;
                    break;
                case 'qs':
                    groupCriteria = 'qs';
                    dbkeyPrefix = "qs_";
                    alignmentsPerPage = fieldConfig.qsOption;
                    break;
                case 'hsp':
                    groupCriteria = 'hsp';
                    dbkeyPrefix = "hsp_";
                    alignmentsPerPage = fieldConfig.hspOption;
                    break;
                case 'flat':
                    groupCriteria = 'ali';
                    dbkeyPrefix = "align_";
                    alignmentsPerPage = fieldConfig.groupOption;
                    break;
                default:
                    groupCriteria = fieldConfig.tableDisplayMode;
                    dbkeyPrefix = fieldConfig.tableDisplayMode + "_";
                    alignmentsPerPage = fieldConfig.groupOption;
                    break;
            }
        }
        let call = {
            data: {
                workflowId: resid.match(/\d+/)[0],
                userId: userdir ? userdir : window.$javaCall.data.userId,
                groupCriteria: groupCriteria,
                pnsPerPage: pnsPerPage,
                alignmentsPerPage: alignmentsPerPage,
                sortCriteria: filter.state.sort,
                sortCriterias: fieldConfig.populateSortCriterias(filter.state.sort),
                //pageNum: state.currentPage
            }
        };
        if (type === 'checkbox') {
            call.data['value'] = value <= 0 ? 1 : 0;
        } else if (type === 'notes') {
            call.data['value'] = 0;
            call.data['comment'] = value;
        } else {
            call.data['value'] = value;
        }
        call.data['annotType'] = type;
        call.data['unannotated'] = !annotated;

        if (choice === 'thisPage' || choice === 'all' || choice === 'thisGroup') {
            filter.state = {...filter.state};
            applyFilterForJava(call, false);
        }
        if ((fromItemsPage && choice === 'all') || choice === 'thisGroup') {
            let newItemFilter = createMoreItemsFilter(fieldConfig, groupValue1, groupValue2);
            if (call.data["customFilters"] && Object.keys(call.data["customFilters"]).length > 0) {
                call.data["customFilters"].push(newItemFilter);
            } else {
                call.data["customFilters"] = [];
                call.data["customFilters"].push(newItemFilter);
            }
        }

        let selectedIds = [];
        if (choice === 'single') {
            if (type === 'checkbox') {
                annotState.checkboxes[dbkey] = Boolean(value <= 0);
                if (fromItemsPage && parentAnnotState.dbkeys[dbkey]) {
                    parentAnnotState.checkboxes[dbkey] = Boolean(value <= 0);
                }
            } else if (type === 'star') {
                annotState.stars[dbkey] = value;
                if (fromItemsPage && parentAnnotState.dbkeys[dbkey]) {
                    parentAnnotState.stars[dbkey] = value;
                }
            } else if (type === 'color') {
                annotState.colors[dbkey] = value;
                if (fromItemsPage && parentAnnotState.dbkeys[dbkey]) {
                    parentAnnotState.colors[dbkey] = value;
                }
            } else if (type === 'notes') {
                annotState.notes[dbkey] = value;
                if (fromItemsPage && parentAnnotState.dbkeys[dbkey]) {
                    parentAnnotState.notes[dbkey] = value;
                }
            }
            call.data['allPages'] = false;
            call.data['unannotated'] = false;
            selectedIds.push(annotState.dbkeys[dbkey]);
            if (fromItemsPage) {
                setItemsAnnotState({...annotState});
                setAnnotState(parentAnnotState);
            } else {
                setAnnotState({...annotState});
            }
        } else {
            if (choice === 'thisPage' || choice === 'thisPageWhole') {
                call.data['allPages'] = false;
                for (let idx in annotState.dbkeys) {
                    selectedIds.push(annotState.dbkeys[idx]);
                }
            } else if (choice === 'thisGroup' ) {
                call.data['allPages'] = false;
            } else {
                call.data['allPages'] = true;
            }

            if (choice !== 'thisGroup') {
                // Update the values for current page
                for (let idx in annotState.dbkeys) {
                    if (type === 'checkbox') {
                        if (annotated) {
                            annotState.checkboxes[idx] = Boolean(value <= 0);
                            if (fromItemsPage && parentAnnotState.dbkeys[idx]) {
                                parentAnnotState.checkboxes[idx] = Boolean(value <= 0);
                            }
                        } else if (!annotated && !annotState.checkboxes[idx]) {
                            annotState.checkboxes[idx] = Boolean(value <= 0);
                            if (fromItemsPage && parentAnnotState.dbkeys[idx]) {
                                parentAnnotState.checkboxes[idx] = Boolean(value <= 0);
                            }
                        }
                    } else if (type === 'star') {
                        if (annotated) {
                            annotState.stars[idx] = value;
                            if (fromItemsPage && parentAnnotState.dbkeys[idx]) {
                                parentAnnotState.stars[idx] = value;
                            }
                        } else if (!annotated && (!annotState.stars[idx] || annotState.stars[idx] === 0)) {
                            annotState.stars[idx] = value;
                            if (fromItemsPage && parentAnnotState.dbkeys[idx]) {
                                parentAnnotState.stars[idx] = value;
                            }
                        }
                    } else if (type === 'color') {
                        if (annotated) {
                            annotState.colors[idx] = value;
                            if (fromItemsPage && parentAnnotState.dbkeys[idx]) {
                                parentAnnotState.colors[idx] = value;
                            }
                        } else if (!annotated && (!annotState.colors[idx] || annotState.colors[idx] === 0)) {
                            annotState.colors[idx] = value;
                            if (fromItemsPage && parentAnnotState.dbkeys[idx]) {
                                parentAnnotState.colors[idx] = value;
                            }
                        }
                    } else if (type === 'notes') {
                        if (annotated) {
                            annotState.notes[idx] = value;
                            if (fromItemsPage && parentAnnotState.dbkeys[idx]) {
                                parentAnnotState.notes[idx] = value;
                            }
                        } else if (!annotated && (!annotState.notes[idx] || annotState.notes[idx] === '')) {
                            annotState.notes[idx] = value;
                            if (fromItemsPage && parentAnnotState.dbkeys[idx]) {
                                parentAnnotState.notes[idx] = value;
                            }
                        }
                    }
                }
            } else {
                let keys = annotState.dbkeysMap[dbkeyPrefix + groupValue1];
                if (keys) {
                    for (let keyIndex in keys) {
                        let key = keys[keyIndex];
                        if (key) {
                            if (type === 'checkbox') {
                                if (annotated) {
                                    annotState.checkboxes['ali_' + key] = Boolean(value <= 0);
                                } else if (!annotated && !annotState.checkboxes['ali_' + key]) {
                                    annotState.checkboxes['ali_' + key] = Boolean(value <= 0);
                                }
                            } else if (type === 'star') {
                                if (annotated) {
                                    annotState.stars['ali_' + key] = value;
                                } else if (!annotated && (!annotState.stars['ali_' + key] || annotState.stars['ali_' + key] === 0)) {
                                    annotState.stars['ali_' + key] = value;
                                }
                            } else if (type === 'color') {
                                if (annotated) {
                                    annotState.colors['ali_' + key] = value;
                                } else if (!annotated && (!annotState.colors['ali_' + key] || annotState.colors['ali_' + key] === 0)) {
                                    annotState.colors['ali_' + key] = value;
                                }
                            } else if (type === 'notes') {
                                if (annotated) {
                                    annotState.notes['ali_' + key] = value;
                                } else if (!annotated && (!annotState.notes['ali_' + key] || annotState.notes['ali_' + key] === '')) {
                                    annotState.notes['ali_' + key] = value;
                                }
                            }
                        }
                    }
                }
            }

            if (fromItemsPage) {
                if (choice === 'whole') {
                    for (let idx in parentAnnotState.dbkeys) {
                        if (type === 'checkbox') {
                            if (parentAnnotState && parentAnnotState.checkboxes[idx]) {
                                parentAnnotState.checkboxes[idx] = Boolean(value <= 0);
                            } else if (!annotated && !parentAnnotState.checkboxes[idx]) {
                                parentAnnotState.checkboxes[idx] = Boolean(value <= 0);
                            }
                        } else if (type === 'star') {
                            if (annotated && parentAnnotState.stars[idx] && parentAnnotState.stars[idx] > 0) {
                                parentAnnotState.stars[idx] = value;
                            } else if (!annotated && (!parentAnnotState.stars[idx] || parentAnnotState.stars[idx] === 0)) {
                                parentAnnotState.stars[idx] = value;
                            }
                        } else if (type === 'color') {
                            if (annotated && parentAnnotState.colors[idx] && parentAnnotState.colors[idx] > 0) {
                                parentAnnotState.colors[idx] = value;
                            } else if (!annotated && (!parentAnnotState.colors[idx] || parentAnnotState.colors[idx] === 0)) {
                                parentAnnotState.colors[idx] = value;
                            }
                        } else if (type === 'notes') {
                            if (annotated && parentAnnotState.notes[idx] && parentAnnotState.notes[idx] > 0) {
                                parentAnnotState.notes[idx] = value;
                            } else if (!annotated && (!parentAnnotState.notes[idx] || parentAnnotState.notes[idx] === '')) {
                                parentAnnotState.notes[idx] = value;
                            }
                        }
                    }
                }
                setItemsAnnotState({...annotState});
                setAnnotState(parentAnnotState);
            } else {
                setAnnotState({...annotState});
            }
        }
        call.data['selectedIds'] = selectedIds;

        // Save annotation
        const response = await DiscoverService.saveAnnotation(call.data, window.$javaCall.data.gqApiKey);
        if (response && response.selectedIds && choice === 'thisGroup') {
            // Update the annotState for the group
            for (let id in response.selectedIds) {
                if (type === 'checkbox') {
                    annotState.checkboxes['ali_' + response.selectedIds[id]] = Boolean(value <= 0);
                } else if (type === 'star') {
                    annotState.stars['ali_' + response.selectedIds[id]] = value;
                } else if (type === 'color') {
                    annotState.colors['ali_' + response.selectedIds[id]] = value;
                } else if (type === 'notes') {
                    annotState.notes['ali_' + response.selectedIds[id]] = value;
                }
            }
            setAnnotState({...annotState});
        }
    }

    function getAbFiltersDesc() {
        if (!_.isEmpty(window.reportFilters)) {
            var reportABFiltersData = window.reportFilters;
            var clone = Object.assign({}, reportABFiltersData);
            delete clone.workflowId;
            delete clone.userId;
            var repFilters = Object.keys(clone);
            var filterList = [];
            for (let key of repFilters) {
                if (!_.isEmpty(reportABFiltersData[key]) && key !== 'groupBy' && key !== 'cdrCombinationsString' && key !== 'cdrCombinations' ) {
                    var descr = abFiltersMap[key];
                    if (descr) { // When the key exists, displays as a filter
                        filterList.push(descr + " - " +reportABFiltersData[key]);
                    }
                }
                if (!_.isEmpty(reportABFiltersData[key]) && ( key == 'cdrCombinations' )) {
                    var cdrs = reportABFiltersData['cdrCombinations'];
                    var matchString = reportABFiltersData['cdrCombinationsString'];
                    var matchcdrs = []; var missingcdrs= []; var mismatchcdrs=[];
                    var description = 'Sequences with ';
                    for(var i=0; i<matchString.length;i++) {
                        if (matchString.charAt(i) == 'M') matchcdrs.push(cdrs[i]);
                        else if (matchString.charAt(i) == '-')  missingcdrs.push(cdrs[i]);
                        else if (matchString.charAt(i) == 'X') mismatchcdrs.push(cdrs[i]);
                    }
                    // var descr = "Sequences with ";
                    var descr = [];
                    if (!_.isEmpty(matchcdrs)){
                        descr.push("matching " + matchcdrs.join());
                        //console.log('matches for ' + matchcdrs.join());
                    }
                    if (!_.isEmpty(missingcdrs)) {
                        descr.push("no " + missingcdrs.join());
                        // console.log('missing cdrs for ' + missingcdrs.join());
                    }
                    if (!_.isEmpty(mismatchcdrs)) {
                        descr.push("mismatches for " + mismatchcdrs.join());
                        // console.log('mismatches for ' + mismatchcdrs.join());
                    }
                    filterList.push("Sequences with " + descr.join(", "));
                }
            }
            return filterList;
           /* var repFilters = Object.values(clone);
            var filterList = [];
            for (let value of repFilters) {
                if (!_.isEmpty(value) ) {
                    filterList.push(value);
                }
            }*/
           // return filterList.join(', ');
        }else{
            return [];
        }
    }

    function noteListChanged(filter, text) {
        filter.state.annot.noteList = text;
        Object.assign(filterState, filter.state);
        setFilterState({...filterState});
        enableApplyButton();
    }

    function resetNotes(filter){
        if(filter.state.annot.noteList !== null){
            filter.state.annot.noteList = '';
        }
        Object.assign(filterState, filter.state);
        setFilterState({...filterState});
        enableApplyButton();
    }

    const saveVmFilter = (filter, fieldConfig, sideMenuDetail) => {
        sideMenuDetail['lvaModules'] = true;
        sideMenuDetail['lvaFilters'] = true;
        setSideMenuDetail({...sideMenuDetail});

        filter.addLvaFilterToList(filter, fieldConfig);
        enableApplyButton();
        UIlog.record(resid, 'VM_CUSTOMFILTER_NEW', filter.lvaWidgetState.customFilterName);
        filter.lvaWidgetState = {...filter.lvaWidgetState};
        filter.widgetState = {...filter.widgetState}; // When saved as global
        filter.state = {...filter.state};
        // Save the filter into database
        if (editable === true) {
            saveResultState(filter, fieldConfig); // Save LVA filters
        }

        setVmIds({...vmIds}); // In order to refresh the VM fitler list, especially when adding from the toolbar
    }

    const setLvaFilterCheckbox = (filter, idx) => {
        enableApplyButton();
        if (!filter.state.lvaFilterCheckboxes) {
            filter.state.lvaFilterCheckboxes = [];
        }
        filter.state.lvaFilterCheckboxes[idx] = !filter.state.lvaFilterCheckboxes[idx];
        Object.assign(filterState, filter.state);
        setFilterState({...filterState});
    }

    const removeLvaFilterFromList = (filter, fieldConfig, idx) => {
        if (!filter.lvaWidgetState.lvaFilters[idx]) {
            // Already removed
            return;
        }
        let oldQuery = filter.query;
        let needReload = false;
        let uuid = filter.lvaWidgetState.lvaFilters[idx].uuid;
        for (let key in oldQuery.lvaFilters) {
            if (uuid === oldQuery.lvaFilters[key].uuid) {
                needReload = true;
                break;
            }
        }

        //fieldConfig.saveConfig(fieldConfig, 'default', filter); // only needed for global filters

        // Remove the filter from the existing list
        filter.lvaWidgetState.lvaFilters.splice(idx, 1);
        filter.state.lvaFilterCheckboxes?.splice(idx, 1); // remove checkbox state as well
        if (editable === true) {
            saveResultState(filter, fieldConfig);
        }

        if (needReload) {
            // When it is used in the last query, reload the whole page
            loadResultsFromBackend(true);
        } else {
            setVmIds({...vmIds}); // In order to refresh the VM fitler list
        }
    }
    
    const enableApplyButton = () => {
        // Won't enable the apply button when coming from reports
        setApplyEnabled(reportId === undefined);
    }

    return (
        <Styles>
        <div id = 'tour-start' className={classes.grow}>
            <Row>
                { !sideMenuCollapse &&
                <Col md="3" sm="3" xs="3" style={{minWidth: '300px', maxWidth: '300px', paddingLeft: '0px'}}>
                    <Col md="12" className="appTextColor">
                        <h6><b>My Column Groups</b></h6>
                        <Accordion square expanded={true}>
                            <AccordionSummary aria-controls='panel-content-cols' id='paneld-header-cols' className="subMenuHeading p-0">
                                <p className="subMenuHeading m-0" onClick={(e) => {showColumnConfigModal('new');e.preventDefault();}} style={{paddingLeft: '5px'}}>
                                    <FaRegPlusSquare style={{marginRight: '5px', verticalAlign: 'middle'}} className="fa fa-plus-square"></FaRegPlusSquare>
                                    <span>Select Displayed Columns</span>
                                </p>
                            </AccordionSummary>
                        </Accordion>
                        {sideMenuDetail &&
                        <Accordion square expanded={sideMenuDetail['saved-view']} onChange={() => toggleSideMenuDetail('saved-view')} style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="p-0" styles = {{backgroundColor: '#292929'}}>
                                <p className="m-0 p-0">
                                    {sideMenuDetail['saved-view'] && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                    {!sideMenuDetail['saved-view'] && <ArrowRightIcon className={classes.arrowIcon} />}
                                    <span className={classes.arrowIconTitle}>Saved Views</span>
                                </p>
                            </AccordionSummary>
                            <AccordionDetails className="appTextColor" style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                <Col md="12" sm='12' xs='12' className="side-menu-item-view">
                                    {fieldConfig.currentView === 'default' &&
                                        <span style={{fontWeight: 'bold'}}> - </span>
                                    }
                                    <label style={{fontWeight: fieldConfig.currentView === 'default' ? 'bold' : '', marginBottom: '0px'}} onClick={()=>switchView('default', fieldConfig)}>Default View</label>
                                </Col>
                                <Col md="12" sm='12' xs='12' className="side-menu-item-view">
                                    {fieldConfig.currentView === 'defaultAli' &&
                                        <span style={{fontWeight: 'bold'}}> - </span>
                                    }
                                    <label style={{fontWeight: fieldConfig.currentView === 'defaultAli' ? 'bold' : '', marginBottom: '0px'}} onClick={()=>switchView('defaultAli', fieldConfig, filter, editable)}>Default Alignment</label>
                                </Col>
                                <Col md="12" sm='12' xs='12' className="side-menu-item-view">
                                    <span className={(fieldConfig.currentView === 'defaultFam') ? 'bold-view':''}>
                                    {fieldConfig.currentView === 'defaultFam' &&
                                        <span style={{fontWeight: 'bold'}}> - </span>
                                    }
                                    <label style={{fontWeight: fieldConfig.currentView === 'defaultFam' ? 'bold' : '', marginBottom: '0px'}} onClick={()=>switchView('defaultFam', fieldConfig, filter, editable)}>Default Patent Family</label>
                                    </span>
                                </Col>
                                <Col md="12" sm='12' xs='12' className="side-menu-item-view">
                                    {fieldConfig.currentView === 'defaultFt' &&
                                        <span style={{fontWeight: 'bold'}}> - </span>
                                    }
                                    <label style={{fontWeight: fieldConfig.currentView === 'defaultFt' ? 'bold' : '', marginBottom: '0px'}} onClick={()=>switchView('defaultFt', fieldConfig, filter, editable)}>Default Full Text</label>
                                </Col>
                                {viewKeys && Object.keys(viewKeys).length > 0 &&
                                    Object.keys(viewKeys).map(
                                        (viewName) => (
                                            <Col key={'views_' + viewName} md="12" sm='12' xs='12' className="side-menu-item-view">
                                                {fieldConfig.currentView === viewKeys[viewName] &&
                                                    <span style={{fontWeight: 'bold'}}> - </span>
                                                }
                                                <span className = {classes.hoverChanges} style={{fontWeight: (fieldConfig.currentView === viewKeys[viewName] ? 'bold' : '')}}
                                                      onClick={()=>switchView(viewKeys[viewName], fieldConfig, filter, editable)}
                                                      onMouseEnter={(e) => updateViewsHover(viewKeys[viewName], true)}
                                                      onMouseLeave={(e) => updateViewsHover(viewKeys[viewName], false)}>{viewKeys[viewName]}
                                                {viewsHover[viewKeys[viewName]] && editable &&
                                                    <span>
                                                        &nbsp;&nbsp; <a href="#" onClick={(e) => {showColumnConfigModal(viewKeys[viewName]);e.preventDefault();}}>edit</a> | <a href="#" onClick={(e) => {fieldConfig.deleteView(fieldConfig, viewKeys[viewName], filter);setViewKeys([...fieldConfig.viewKeys]);e.preventDefault();}}>del</a>
                                                    </span>
                                                }
                                                </span>
                                            </Col>
                                        )
                                    )
                                }
                            </AccordionDetails>
                        </Accordion>
                        }

                        <h6 id ="HighlightText" className="mt-2"><b>Highlight Text</b></h6>
                        <div id = 'tour-highlight' className="side-menu-block">
                            <div className="invisible-popup sidebar-tour-width"
                                 //tour-step tour-step-order="8"
                                 //tour-step-content="Highlight text by typing here."
                            >
                                <input id="hlText" key="hlText" type="text" className="search-input" placeholder="Highlight all text"
                                       value={smartHighlightText} autoComplete="off" spellCheck="false"
                                       onChange={(e) => {setAnchorEl(e.currentTarget);smartHighlightGetOptions(e, filter, e.target.value);}}
                                       onKeyDown={(e) => {if(e.key==='Enter'){addSmartHighlight(filter, 'ALL_TEXT');};if(e.key==='Escape'){setSmartHighlightPopup(false);};e.stopPropagation();}}
                                       onClick={(e) => {setAnchorEl(e.currentTarget);smartHighlightGetOptions(e, filter, e.target.value);e.stopPropagation()}}
                                       //onBlur={(e) => {setSmartHighlightPopup(false)}}
                                       style={toggle ? {backgroundColor: ''}:{backgroundColor:'#292929', color: 'white'}}
                                />
                                {smartHighlightPopup && smartHighlightText && smartHighlightText.length >= minSmartTextLen &&
                                    <Popover id={"highlight-popover"}
                                             open={smartHighlightPopup}
                                             anchorEl={anchorEl}
                                             onClose={closePopover}
                                             anchorReference="anchorEl"
                                             anchorOrigin={{
                                                 vertical: "bottom",
                                                 horizontal: "left",
                                             }}
                                             transformOrigin={{
                                                 vertical: "top",
                                                 horizontal: "left",
                                             }}
                                             // pass these props to the popover component
                                             disableAutoFocus={true}
                                             disableEnforceFocus={true}
                                    >
                                        <div className="autocomplete-container">
                                            {smartHighlightOptions.length>0 &&
                                                <div>
                                                    <div className="autocomplete-item"
                                                         onClick={() => addSmartHighlight(filter, 'ALL_TEXT')}>
                                                        {smartHighlightOptions[0].text} in <b>Any Text Field</b>
                                                    </div>
                                                    <div className="autocomplete-spacer"></div>
                                                    {smartHighlightOptions.map(
                                                        (option, optIdx) => (
                                                            <div className="autocomplete-item" key={'hloption_' + optIdx}
                                                                 onClick={() => addSmartHighlight(filter, smartHighlightOptions[optIdx].key)}>
                                                                {smartHighlightOptions[optIdx].text} in <b>{
                                                                fieldConfig.getFieldInfo(smartHighlightOptions[optIdx].key).title
                                                            }</b>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            }
                                            {smartHighlightOptions.length <= 0 &&
                                                <div className="autocomplete-item">"{filter.state.smartHighlight}" Not Found
                                                </div>
                                            }
                                        </div>
                                    </Popover>
                                }

                            </div>

                            {smartHighlightFilter &&
                                Object.keys(smartHighlightFilter).map(index => (
                                    <div className="cursor-default" key={'smartHighlightFilter_' + index}>
                                        <div className="side-menu-item-hl">
                                            <span onClick={(e)=> toggleSideMenuDetail('text-'+index)}>
                                                {ReactHtmlParser(fieldConfig.getFieldName(index))}</span>
                                        </div>
                                        {sideMenuDetail && !sideMenuDetail['text-'+index] &&
                                        <div className="side-menu-indent">
                                            {smartHighlightFilter[index] &&
                                                Object.keys(smartHighlightFilter[index]).map(
                                                    (subIdx,itemIdx) => (
                                                <div className="side-menu-sub-items" key={"smartHighlightFilterSubDiv_" + itemIdx}
                                                     onMouseEnter={(e) => smartHighlightFilterEvent(index + '_' + itemIdx, true)}
                                                     onMouseLeave={(e) => {smartHighlightFilterEvent(index + '_' + itemIdx, false);showHighlightColorBox(index + '_' + itemIdx, false)}}>
                                                    <div className="clearfix">
                                                        <div className="pull-left">
                                                            <input type="checkbox" checked={smartHighlightFilter[index][subIdx].value || false}
                                                                   onChange={(e)=> {smartHighlightFilter[index][subIdx].value = !smartHighlightFilter[index][subIdx].value;setHighlightCheckbox()}}/>
                                                        </div>
                                                        <div className="color-select-div pull-left">
                                                            <div onClick={() => showHighlightColorBox(index + '_' + itemIdx, true)}
                                                                className={'pull-right color-select-highlight highlight-' + highlightColors[smartHighlightFilter[index][subIdx].color]}></div>
                                                            {highlightColorBox[index + '_' + itemIdx] &&
                                                                <div id="highlightcolorbox" className="clearfix color-box-container" onBlur={() => showHighlightColorBox(index + '_' + itemIdx, false)}>
                                                                    {highlightColors.map(
                                                                        (color, colorIdx) => (
                                                                            <div key={'colorbox_' + color} className={'color-box pull-left highlight-' + color} onClick={() => selectHighlightColor(index, itemIdx, colorIdx)}></div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="pull-left"><span
                                                            onClick={(e) => {smartHighlightFilter[index][subIdx].value = !smartHighlightFilter[index][subIdx].value;setHighlightCheckbox()}}
                                                        >&nbsp;{smartHighlightFilter[index][subIdx].text}</span>
                                                        </div>
                                                        {smartHighlightFilterHover[index + '_' + itemIdx] &&
                                                            <div className="pull-left">
                                                                &nbsp;&nbsp;<a href="#" onClick={(e) => {removeSmartHighlight(filter,index,itemIdx); setHighlightCheckbox(); e.preventDefault();}}>del</a>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        }
                                    </div>))
                            }
                            {smartHighlightFilter && Object.keys(smartHighlightFilter).length > 0 &&
                                <div className="side-menu-block">
                                    <div className="clearfix button-div">
                                        <button className="pull-left small-button grey" onClick={() => clearHighlights()}>Clear Highlights</button>
                                    </div>
                                </div>
                            }
                        </div>

                        <div>
                        <h6 id = "FilterText" className="mt-2"><b>Filter Text</b></h6>
                            <div className="invisible-popup sidebar-tour-width"
                                //tour-step tour-step-order="10"
                                //tour-step-content="Filter results by typing text here. For example type the word claim."
                            >
                                <input id="filterText" key="filterText" type="text" className="search-input" placeholder="Search all text"
                                       value={smartText} autoComplete="off" spellCheck="false"
                                       onChange={(e) => {setFilterAnchorEl(e.currentTarget);smartTextGetOptions(e, filter, e.target.value);}}
                                       onKeyDown={(e) => {if(e.key==='Enter'){addSmartText(filter, 'ALL_TEXT');};if(e.key==='Escape'){setSmartTextPopup(false);};e.stopPropagation();}}
                                       onClick={(e) => {setFilterAnchorEl(e.currentTarget);smartTextGetOptions(e, filter, e.target.value);e.stopPropagation()}}
                                       style={toggle ? {backgroundColor: ''}:{backgroundColor:'#292929', color: 'white'}}
                                />
                                {smartTextPopup && smartText && smartText.length >= minSmartTextLen &&
                                    <Popover id={"smarttext-popover"}
                                             open={smartTextPopup}
                                             anchorEl={filterAnchorEl}
                                             onClose={closePopover}
                                             anchorReference="anchorEl"
                                             anchorOrigin={{
                                                 vertical: "bottom",
                                                 horizontal: "left",
                                             }}
                                             transformOrigin={{
                                                 vertical: "top",
                                                 horizontal: "left",
                                             }}
                                             disableAutoFocus={true}
                                             disableEnforceFocus={true}
                                    >
                                        <div className="autocomplete-container">
                                            {smartTextOptions.length>0 &&
                                                <div>
                                                    <div className="autocomplete-item"
                                                         onClick={() => addSmartText(filter, 'ALL_TEXT')}>
                                                        {smartTextOptions[0].text} in <b>Any Text Field</b>
                                                    </div>
                                                    <div className="autocomplete-spacer"></div>
                                                    {smartTextOptions.map(
                                                        (option, optIdx) => (
                                                            <div className="autocomplete-item" key={'ft_option_' + optIdx}
                                                                 onClick={() => addSmartText(filter, smartTextOptions[optIdx].key)}>
                                                                {smartTextOptions[optIdx].text} in <b>{
                                                                fieldConfig.getFieldInfo(smartTextOptions[optIdx].key).title
                                                            }</b>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            }
                                            {smartTextOptions.length <= 0 &&
                                                <div className="autocomplete-item">"{filter.state.smartText}" Not Found
                                                </div>
                                            }
                                        </div>
                                    </Popover>
                                }

                            </div>

                            {smartTextFilter &&
                                Object.keys(smartTextFilter).map(index => (
                                    <div className="cursor-default" key={'smartTextFilter_' + index}>
                                        <div className="side-menu-item-hl">
                                            <span onClick={(e)=> toggleSideMenuDetail('text-'+index)}>
                                                {ReactHtmlParser(fieldConfig.getFieldName(index))}</span>
                                        </div>
                                        {sideMenuDetail && !sideMenuDetail['text-'+index] &&
                                            <div className="side-menu-indent">
                                                {smartTextFilter[index] &&
                                                    Object.keys(smartTextFilter[index]).map(
                                                        (subIdx,itemIdx) => (
                                                            <div className="side-menu-sub-items" key={"smartTextFilterSubDiv_" + itemIdx}
                                                                 onMouseEnter={(e) => smartTextFilterEvent(index + '_' + itemIdx, true)}
                                                                 onMouseLeave={(e) => {smartTextFilterEvent(index + '_' + itemIdx, false);}}>
                                                                <div className="clearfix">
                                                                    <div className="pull-left">
                                                                        <input type="checkbox" checked={smartTextFilter[index][subIdx].value || false}
                                                                               onChange={(e)=> {smartTextFilter[index][subIdx].value = !smartTextFilter[index][subIdx].value;setSmartTextCheckbox()}}/>
                                                                    </div>
                                                                    <div className="pull-left"><span
                                                                        onClick={(e) => {smartTextFilter[index][subIdx].value = !smartTextFilter[index][subIdx].value;setSmartTextCheckbox()}}
                                                                    >
                                                                        {smartTextFilter[index][subIdx].negate &&
                                                                            <span>&nbsp;<b>NOT</b></span>
                                                                        }
                                                                        &nbsp;{smartTextFilter[index][subIdx].text}</span>
                                                                    </div>
                                                                    {smartTextHover[index + '_' + itemIdx] && editable &&
                                                                        <div className="pull-left">
                                                                            &nbsp;&nbsp;<a href="#" onClick={(e) => {removeSmartText(filter,index,itemIdx);e.preventDefault();}}>del</a>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))}
                                            </div>
                                        }
                                    </div>))
                            }
                            {/*smartTextFilter && Object.keys(smartTextFilter).length > 0 &&
                                <div className="side-menu-block">
                                    <div className="clearfix button-div">
                                        <button className="pull-left small-button grey" onClick={() => clearSmartText()}>Remove All Text Filters</button>
                                    </div>
                                </div>
                            */}

                        {sideMenuDetail &&
                        <Accordion square expanded={sideMenuDetail['queryseqid']} onChange={() => {toggleSideMenuDetail('queryseqid')}} style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                            <AccordionSummary aria-controls="panelqid-content" id="panelqid-header" className="subMenuHeading p-0">
                                <p className="subMenuHeading m-0">
                                    {sideMenuDetail['queryseqid'] && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                    {!sideMenuDetail['queryseqid'] && <ArrowRightIcon className={classes.arrowIcon} />}
                                    <span className="mt-4">Query Sequence ID</span>
                                </p>
                            </AccordionSummary>
                            <AccordionDetails className="appTextColor" style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                {Object.keys(querySeqIds).map(
                                    (seq, seqIdx) => (
                                    <Col key={'seq_' + seqIdx} md="12" sm='12' xs='12'>
                                        <input type="checkbox" id={'cb_seq_' + seqIdx} key={'cb_seq_' + seqIdx}
                                               style={{verticalAlign: 'middle'}}
                                               checked={(filter.state.querySeqDbs && filter.state.querySeqDbs[querySeqIds[seq].id]) || false}
                                               onChange={(e)=> {setQuerySeqCheckbox(filter, seq)}}/>
                                        <label htmlFor={'cb_seq_' + seqIdx} key={'cb_seq_label_' + seqIdx}>&nbsp;{querySeqIds[seq].id.toUpperCase()}&nbsp;({Number(querySeqIds[seq].count).toLocaleString()})</label>
                                    </Col>
                                ))}
                                {filter.state.querySeqDbs && _.size(filter.state.querySeqDbs) > 0 && reportId === undefined &&
                                    <div className="clearfix button-div button-div-pad">
                                        <button className="pull-left small-button grey"
                                                onClick={() => {clearQuerySeqIdFilter(filter); loadResultsFromBackend(true)}}>
                                            Reset Query Sequence ID Filter
                                        </button>
                                    </div>
                                }
                                {!_.isEmpty(window.reportFilters) &&
                                <div class="paddingletf15 filter-widget-top">
                                    <span className="mt-4 filterABText">Antibody Filters</span>
                                    <div class="clearfix filterABText">
                                        {abFilterDesc != [] && abFilterDesc.map(abfilterItem => {
                                        return(
                                            <span class="pull-left filterABText">{abfilterItem}</span>
                                        );})
                                        }
                                    </div> 
                                </div>
                                }
                            </AccordionDetails>
                        </Accordion>
                        }

                        {sideMenuDetail &&
                        <Accordion square expanded={sideMenuDetail['pubnumbers']} onChange={() => {toggleSideMenuDetail('pubnumbers')}} style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                            <AccordionSummary aria-controls="panelpns-content" id="panelpns-header" className="subMenuHeading p-0">
                                <p className="subMenuHeading m-0">
                                    {sideMenuDetail['pubnumbers'] && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                    {!sideMenuDetail['pubnumbers'] && <ArrowRightIcon className={classes.arrowIcon} />}
                                    <span className="mt-4">Patent Numbers</span>
                                </p>
                            </AccordionSummary>
                            <AccordionDetails className="appTextColor" style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                <Col key={'col_pns'} md="12" sm='12' xs='12'>
                                    <div>
                                    <Tooltip title={pnListErr} placement="top" arrow>
                                    <textarea className={'pnlist ' + ((pnListErr && pnListErr !== '')?'input-with-error':'')}
                                              placeholder="Copy & Paste PN list" value={filter.state.publicationNumberList}
                                              onChange={(e) => pnListChanged(filter, e.target.value)}>
                                    </textarea></Tooltip>
                                    </div>
                                    {filter.state.publicationNumberList && filter.state.publicationNumberList !== '' && reportId === undefined &&
                                        <div className="clearfix button-div ">
                                            <button className="pull-left small-button grey"
                                                    onClick={() => {clearPnFilter(filter); loadResultsFromBackend(true)}}>
                                                Reset Patent Number Filter
                                            </button>
                                        </div>
                                    }
                                </Col>
                            </AccordionDetails>
                        </Accordion>
                        }

                        {sideMenuDetail &&
                        <Accordion square expanded={sideMenuDetail['databasenames']} onChange={() => {toggleSideMenuDetail('databasenames')}} style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                            <AccordionSummary aria-controls="paneldbs-content" id="paneldbs-header" className="subMenuHeading p-0">
                                <p className="subMenuHeading m-0">
                                    {sideMenuDetail['databasenames'] && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                    {!sideMenuDetail['databasenames'] && <ArrowRightIcon className={classes.arrowIcon} />}
                                    <span className="mt-4">Sequence Databases</span>
                                </p>
                            </AccordionSummary>
                            <AccordionDetails className="appTextColor" style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                {Object.keys(databases).map(
                                    (db, dbIdx) => (
                                        <Col key={'coldb_' + dbIdx} md="12" sm='12' xs='12'>
                                            <input type="checkbox" id={'cb_db_' + dbIdx} key={'cb_db_' + dbIdx}
                                                   style={{verticalAlign: 'middle'}}
                                                   checked={(filter.state.databaseNames && filter.state.databaseNames[db]) || false}
                                                   onChange={(e)=> {setDatabaseCheckbox(filter, db)}}/>
                                            <label htmlFor={'cb_db_' + dbIdx} key={'cb_db_label_' + dbIdx}>&nbsp;{databases[db]}</label>
                                        </Col>
                                    ))}
                                {filter.state.databaseNames && _.size(filter.state.databaseNames) > 0 && reportId === undefined &&
                                    <div className="clearfix button-div button-div-pad">
                                        <button className="pull-left small-button grey"
                                                onClick={() => {clearFilter(filter, 'databaseNames'); loadResultsFromBackend(true)}}>
                                            Reset Sequence Database Filter
                                        </button>
                                    </div>
                                }
                            </AccordionDetails>
                        </Accordion>
                        }
                        </div>
                        <span id="annotationFilter" ></span>
                        {sideMenuDetail &&
                        <Accordion square expanded={sideMenuDetail['annotation-filters']} onChange={() => toggleSideMenuDetail('annotation-filters')} style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                            <AccordionSummary aria-controls="panelannot-content" id="panelannot-header" className="subMenuHeading p-0">
                                <p className="subMenuHeading m-0">
                                    {sideMenuDetail['annotation-filters'] && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                    {!sideMenuDetail['annotation-filters'] && <ArrowRightIcon className={classes.arrowIcon} />}
                                    <span className={classes.arrowIconTitle}>Annotation Filters</span>
                                </p>
                            </AccordionSummary>
                            <AccordionDetails className="appTextColor" style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                <Col md="12" sm='12' xs='12' className={classes.row1}>
                                    <Accordion square expanded={sideMenuDetail['annotation-checkboxes']} onChange={() => toggleSideMenuDetail('annotation-checkboxes')} style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                        <AccordionSummary aria-controls="panelcb-content" id="panelcb-header" className="p-0" style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                            <p className="m-0">
                                                {sideMenuDetail['annotation-checkboxes'] && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                                {!sideMenuDetail['annotation-checkboxes'] && <ArrowRightIcon className={classes.arrowIcon} />}
                                                <span className={classes.arrowIconTitle}>Checkboxes</span>
                                            </p>
                                        </AccordionSummary>
                                        <AccordionDetails className="appTextColor" style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                             <Col md="12" sm='12' xs='12' className={classes.row1}>
                                                 <input type="checkbox" id='annot_cb_unchecked' key='annot_cb_unchecked'
                                                        style={{verticalAlign: 'middle'}}
                                                        checked={filter.state.annot?.checkboxes?.unchecked || false}
                                                        onChange={(e)=> {setAnnotationCheckbox(filter, 'checkboxes', 'unchecked')}}/>
                                                 <label htmlFor='annot_cb_unchecked'>&nbsp;Unchecked</label>
                                            </Col>
                                            <Col md="12" sm='12' xs='12' className={classes.row2}>
                                                <input type="checkbox" id='annot_cb_checked' key='annot_cb_checked'
                                                       style={{verticalAlign: 'middle'}}
                                                       checked={filter.state.annot?.checkboxes?.checked || false}
                                                       onChange={(e)=> {setAnnotationCheckbox(filter, 'checkboxes', 'checked')}}/>
                                                <label htmlFor='annot_cb_checked'>&nbsp;Checked</label>
                                             </Col>
                                        </AccordionDetails>
                                     </Accordion>   
                                     <Accordion square expanded={sideMenuDetail['annotation-stars']} onChange={() => toggleSideMenuDetail('annotation-stars')} style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                        <AccordionSummary aria-controls="panelst-content" id="panelst-header" className="p-0" style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                            <p className="m-0 p-0">
                                                {sideMenuDetail['annotation-stars'] && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                                {!sideMenuDetail['annotation-stars'] && <ArrowRightIcon className={classes.arrowIcon} />}
                                                <span className={classes.arrowIconTitle}>Stars</span>
                                            </p>
                                        </AccordionSummary>
                                        <AccordionDetails className="appTextColor" style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                             <Col md="12" sm='12' xs='12' className={classes.row1}>
                                                 <input type="checkbox" id='annot_st_0' key='annot_st_0'
                                                        style={{verticalAlign: 'middle'}}
                                                        checked={(filter.state.annot?.stars && filter.state.annot.stars['0']) || false}
                                                        onChange={(e)=> {setAnnotationCheckbox(filter, 'stars', '0')}}/>
                                                 <label htmlFor='annot_st_0'>&nbsp;
                                                     <FaStar className="fa fa-star-mid" color={'#DDD'}></FaStar>
                                                     <FaStar className="fa fa-star-mid" color={'#DDD'}></FaStar>
                                                     <FaStar className="fa fa-star-mid" color={'#DDD'}></FaStar>
                                                     &nbsp;Zero Stars</label>
                                            </Col>
                                            <Col md="12" sm='12' xs='12' className={classes.row2}>
                                                <input type="checkbox" id='annot_st_1' key='annot_st_1'
                                                       style={{verticalAlign: 'middle'}}
                                                       checked={(filter.state.annot?.stars && filter.state.annot.stars['1']) || false}
                                                       onChange={(e)=> {setAnnotationCheckbox(filter, 'stars', '1')}}/>
                                                <label htmlFor='annot_st_1'>&nbsp;
                                                    <FaStar className="fa fa-star-mid" color={'#999'}></FaStar>
                                                    <FaStar className="fa fa-star-mid" color={'#DDD'}></FaStar>
                                                    <FaStar className="fa fa-star-mid" color={'#DDD'}></FaStar>
                                                    &nbsp;One Star</label>
                                             </Col>
                                             <Col md="12" sm='12' xs='12' className={classes.row2}>
                                                 <input type="checkbox" id='annot_st_2' key='annot_st_2'
                                                        style={{verticalAlign: 'middle'}}
                                                        checked={(filter.state.annot?.stars && filter.state.annot.stars['2']) || false}
                                                        onChange={(e)=> {setAnnotationCheckbox(filter, 'stars', '2')}}/>
                                                 <label htmlFor='annot_st_2'>&nbsp;
                                                     <FaStar className="fa fa-star-mid" color={'#999'}></FaStar>
                                                     <FaStar className="fa fa-star-mid" color={'#999'}></FaStar>
                                                     <FaStar className="fa fa-star-mid" color={'#DDD'}></FaStar>
                                                     &nbsp;Two Stars</label>
                                             </Col>
                                             <Col md="12" sm='12' xs='12' className={classes.row2}>
                                                 <input type="checkbox" id='annot_st_3' key='annot_st_3'
                                                        style={{verticalAlign: 'middle'}}
                                                        checked={(filter.state.annot?.stars && filter.state.annot.stars['3']) || false}
                                                        onChange={(e)=> {setAnnotationCheckbox(filter, 'stars', '3')}}/>
                                                 <label htmlFor='annot_st_3'>&nbsp;
                                                     <FaStar className="fa fa-star-mid" color={'#999'}></FaStar>
                                                     <FaStar className="fa fa-star-mid" color={'#999'}></FaStar>
                                                     <FaStar className="fa fa-star-mid" color={'#999'}></FaStar>
                                                     &nbsp;Three Stars</label>
                                             </Col>
                                        </AccordionDetails>
                                     </Accordion> 
                                     <Accordion square expanded={sideMenuDetail['annotation-colors']} onChange={() => toggleSideMenuDetail('annotation-colors')}>
                                        <AccordionSummary aria-controls="panelco-content" id="panelco-header" className="p-0" style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                            <p className="m-0 p-0">
                                                {sideMenuDetail['annotation-colors'] && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                                {!sideMenuDetail['annotation-colors'] && <ArrowRightIcon className={classes.arrowIcon} />}
                                                <span className={classes.arrowIconTitle}>Colors</span>
                                            </p>
                                        </AccordionSummary>
                                        <AccordionDetails className="appTextColor" style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                            {colors.map(
                                                (col, idx) => (
                                                    <Col key={'colco_' + idx} md="12" sm='12' xs='12'>
                                                        <input type="checkbox" id={'annot_co_' + idx}
                                                               style={{verticalAlign: 'middle'}}
                                                               checked={(filter.state.annot?.colors && filter.state.annot.colors[idx]) || false}
                                                               onChange={(e)=> {setAnnotationCheckbox(filter, 'colors', idx)}}/>
                                                        <label htmlFor={'annot_co_' + idx}>&nbsp;<div className={'color-example pull-left color-' + idx}></div>
                                                            {col}</label>
                                                    </Col>
                                                ))}
                                        </AccordionDetails>
                                     </Accordion>
                                    <Accordion square expanded={sideMenuDetail['annotation-notes']} onChange={() => {toggleSideMenuDetail('annotation-notes')}} style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                        <AccordionSummary aria-controls="paneltn-content" id="paneltn-header" className="p-0" style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}> 
                                            <p className="p-0 m-0">
                                                {sideMenuDetail['annotation-notes'] && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                                {!sideMenuDetail['annotation-notes'] && <ArrowRightIcon className={classes.arrowIcon} />}
                                                <span className="mt-4">Notes</span>
                                            </p>
                                        </AccordionSummary>
                                        <AccordionDetails className="appTextColor" style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                            <Col key={'col_notes'} md="12" sm='12' xs='12'>
                                                <div>
                                                    <textarea className={'pnlist'}
                                                              placeholder="Search by your notes here" value={filter.state.annot?.noteList}
                                                              onChange={(e) => noteListChanged(filter, e.target.value)}
                                                              >                                       
                                                    </textarea>
                                                    {filter.state.annot?.noteList && reportId === undefined &&
                                                        <button className="pull-left small-button grey" onClick={(e) => resetNotes(filter)}>Reset Notes</button>
                                                    }
                                                </div>
                                            </Col>
                                        </AccordionDetails>
                                    </Accordion>
                                 </Col>
                            </AccordionDetails>
                            </Accordion>
                        }

                        {sideMenuDetail &&
                            <Accordion square expanded={sideMenuDetail['SUBJECT_PN']} onChange={() => {toggleSideMenuDetail('SUBJECT_PN')}} style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                <AccordionSummary aria-controls='panel-content-SUBJECT_PN' id='paneld-header-SUBJECT_PN' className="subMenuHeading p-0">
                                    <p className="subMenuHeading m-0">
                                        {sideMenuDetail['SUBJECT_PN'] && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                        {!sideMenuDetail['SUBJECT_PN'] && <ArrowRightIcon className={classes.arrowIcon} />}
                                        <span className="mt-4">Patent Authorities</span>
                                    </p>
                                </AccordionSummary>
                                <AccordionDetails className="appTextColor" style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                    {facetInfo && facetInfo['SUBJECT_PN'] && Object.keys(facetInfo['SUBJECT_PN']).length > 0
                                        && Object.keys(facetInfo['SUBJECT_PN']).map(
                                            (db, dbIdx) => (
                                                <Col key={'col_' + db + "_" + dbIdx} md="12" sm='12' xs='12'>
                                                    <input type="checkbox" id={'cb_facet_SUBJECT_PN_' + dbIdx}
                                                           style={{verticalAlign: 'middle'}}
                                                           checked={filter.state['SUBJECT_PN'][db] || false}
                                                           onChange={(e)=> {setFacetCheckbox(filter, 'SUBJECT_PN', db)}}/>
                                                    <label htmlFor={'cb_facet_SUBJECT_PN_' + dbIdx}>
                                                        &nbsp;<span className={'flag-icon flag-icon-' + facetInfo['SUBJECT_PN'][db].desc.toLowerCase()}></span>
                                                        &nbsp;{facetInfo['SUBJECT_PN'][db].desc} ({Number(facetInfo['SUBJECT_PN'][db].count).toLocaleString()})</label>
                                                </Col>
                                            ))}
                                    {_.size(filter.state['SUBJECT_PN']) > 0 && reportId === undefined &&
                                        <div className="clearfix button-div button-div-pad">
                                            <button className="pull-left small-button grey"
                                                    onClick={() => {clearFilter(filter, 'SUBJECT_PN'); loadResultsFromBackend(true)}}>
                                                Reset Patent Authorities Filter
                                            </button>
                                        </div>
                                    }
                                </AccordionDetails>
                            </Accordion>
                        }
                        <span id="advFilterScroll"></span>
                        {sideMenuDetail &&
                            <Accordion square expanded={sideMenuDetail['SUBJECT_PG']} onChange={() => {toggleSideMenuDetail('SUBJECT_PG')}} style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                <AccordionSummary aria-controls='panel-content-SUBJECT_PG' id='paneld-header-SUBJECT_PG' className="subMenuHeading p-0">
                                    <p className="subMenuHeading m-0">
                                        {sideMenuDetail['SUBJECT_PG'] && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                        {!sideMenuDetail['SUBJECT_PG'] && <ArrowRightIcon className={classes.arrowIcon} />}
                                        <span className="mt-4">Extended Legal Status</span>
                                    </p>
                                </AccordionSummary>
                                <AccordionDetails className="appTextColor" style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                    {facetInfo && facetInfo['SUBJECT_PG'] && Object.keys(facetInfo['SUBJECT_PG']).length > 0
                                        && Object.keys(facetInfo['SUBJECT_PG']).map(
                                            (db, dbIdx) => (
                                                <Col key={'col_' + db + "_" + dbIdx} md="12" sm='12' xs='12'>
                                                    <input type="checkbox" id={'cb_facet_SUBJECT_PG_' + dbIdx}
                                                           style={{verticalAlign: 'middle'}}
                                                           checked={filter.state['SUBJECT_PG'][db] || false}
                                                           onChange={(e)=> {setFacetCheckbox(filter, 'SUBJECT_PG', db)}}/>
                                                    <label htmlFor={'cb_facet_SUBJECT_PG_' + dbIdx}>&nbsp;{facetInfo['SUBJECT_PG'][db].desc} ({Number(facetInfo['SUBJECT_PG'][db].count).toLocaleString()})</label>
                                                </Col>
                                            ))}
                                    {_.size(filter.state['SUBJECT_PG']) > 0 && reportId === undefined &&
                                        <div className="clearfix button-div button-div-pad">
                                            <button className="pull-left small-button grey"
                                                    onClick={() => {clearFilter(filter, 'SUBJECT_PG'); loadResultsFromBackend(true)}}>
                                                Reset Extended Legal Status Filter
                                            </button>
                                        </div>
                                    }
                                </AccordionDetails>
                            </Accordion>
                        }
                        {sideMenuDetail &&
                            <Accordion square expanded={sideMenuDetail['SUBJECT_PS']} onChange={() => {toggleSideMenuDetail('SUBJECT_PS')}} style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                <AccordionSummary aria-controls='panel-content-SUBJECT_PS' id='paneld-header-SUBJECT_PS' className="subMenuHeading p-0">
                                    <p className="subMenuHeading m-0">
                                        {sideMenuDetail['SUBJECT_PS'] && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                        {!sideMenuDetail['SUBJECT_PS'] && <ArrowRightIcon className={classes.arrowIcon} />}
                                        <span className="mt-4">Patent Sequence Location</span>
                                    </p>
                                </AccordionSummary>
                                <AccordionDetails className="appTextColor" style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                    {facetInfo && facetInfo['SUBJECT_PS'] && Object.keys(facetInfo['SUBJECT_PS']).length > 0
                                        && Object.keys(facetInfo['SUBJECT_PS']).map(
                                            (db, dbIdx) => (
                                                <Col key={'col_' + db + "_" + dbIdx} md="12" sm='12' xs='12'>
                                                    <input type="checkbox" id={'cb_facet_SUBJECT_PS_' + dbIdx}
                                                           style={{verticalAlign: 'middle'}}
                                                           checked={filter.state['SUBJECT_PS'][db] || false}
                                                           onChange={(e)=> {setFacetCheckbox(filter, 'SUBJECT_PS', db)}}/>
                                                    <label htmlFor={'cb_facet_SUBJECT_PS_' + dbIdx}>&nbsp;{facetInfo['SUBJECT_PS'][db].desc} ({Number(facetInfo['SUBJECT_PS'][db].count).toLocaleString()})</label>
                                                </Col>
                                            ))}
                                    {_.size(filter.state['SUBJECT_PS']) > 0 && reportId === undefined &&
                                        <div className="clearfix button-div button-div-pad">
                                            <button className="pull-left small-button grey"
                                                    onClick={() => {clearFilter(filter, 'SUBJECT_PS'); loadResultsFromBackend(true)}}>
                                                Reset Patent Seq. Location Filter
                                            </button>
                                        </div>
                                    }
                                </AccordionDetails>
                            </Accordion>
                        }
                        <span id="advFilter"></span>
                        {sideMenuDetail &&
                            <Accordion square expanded={sideMenuDetail['customfilter']} onChange={() => {toggleSideMenuDetail('customfilter')}} style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                <AccordionSummary aria-controls='panel-content-customfilter' id='paneld-header-customfilter' className="subMenuHeading p-0">
                                    <p className="subMenuHeading m-0">
                                        {sideMenuDetail['customfilter'] && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                        {!sideMenuDetail['customfilter'] && <ArrowRightIcon className={classes.arrowIcon} />}
                                        <span className="mt-4">Advanced Filters</span>
                                    </p>
                                </AccordionSummary>
                                <AccordionDetails className="appTextColor" style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                    <Col md="12" sm='12' xs='12' style={{paddingLeft: '5px'}}>
                                        <Accordion square expanded={true}>
                                            <AccordionSummary aria-controls='panel-content-samcustomfilter' id='paneld-header-samcustomfilter' className="subMenuHeading p-0">
                                                <p className="subMenuHeading m-0" onClick={() => {if (editable === true) {startFilteringWidget(filter, '', '');}}} style={{paddingLeft: '5px'}}>
                                                    <FaRegPlusSquare style={{marginRight: '5px', verticalAlign: 'middle'}} className="fa fa-plus-square"></FaRegPlusSquare>
                                                    <span>Create New Filter</span>
                                                </p>
                                            </AccordionSummary>
                                        </Accordion>
                                        <Accordion square expanded={sideMenuDetail['samplecustomfilter']}
                                                   onChange={() => {toggleSideMenuDetail('samplecustomfilter')}}>
                                            <AccordionSummary aria-controls='panel-content-samcustomfilter' id='paneld-header-samcustomfilter' className="subMenuHeading p-0">
                                                <p className="subMenuHeading m-0">
                                                    {sideMenuDetail['samplecustomfilter'] && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                                    {!sideMenuDetail['samplecustomfilter'] && <ArrowRightIcon className={classes.arrowIcon} />}
                                                    <span className="mt-4">Sample Filters</span>
                                                </p>
                                            </AccordionSummary>
                                            <AccordionDetails className="appTextColor" style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                                {filter.widgetState && filter.widgetState.defaultCustomFilters
                                                    && Object.keys(filter.widgetState.defaultCustomFilters).length > 0 &&
                                                    filter.widgetState.defaultCustomFilters.map(
                                                        (defaultFilter, idx) => (
                                                            <Col key={'defaultFilter_' + idx} style={{paddingLeft: '10px', whiteSpace: 'nowrap'}}>
                                                                <div onMouseEnter={(e) => updateCustomFiltersHover(idx, true)}
                                                                      onMouseLeave={(e) => updateCustomFiltersHover(idx, false)}>
                                                                    <input type="checkbox" id={'cb_defaultFilter_' + idx} style={{verticalAlign: 'middle'}}
                                                                           checked={filter.state?.customFilterCheckboxes ? filter.state?.customFilterCheckboxes[idx] || false : false}
                                                                           onChange={(e)=> {setFilterCheckbox(filter, idx)}}
                                                                    />
                                                                    <label htmlFor={'cb_defaultFilter_' + idx}>&nbsp;{filter.widgetState.defaultCustomFilters[idx].customFilterName}&nbsp;</label>
                                                                    <CustomTooltip arrow title={defaultCustomFiltersHelpMessage[idx]} placement="top">
                                                                        <span><FaInfoCircle/>&nbsp;&nbsp;</span>
                                                                    </CustomTooltip>
                                                                    {customFiltersHover[idx] && editable &&
                                                                        <a href="#" onClick={(e) => {startFilteringWidget(filter, 'default', idx);e.preventDefault();}}>edit</a>
                                                                    }
                                                                </div>
                                                            </Col>
                                                        )
                                                    )
                                                }
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion square expanded={sideMenuDetail['mycustomfilter']} onChange={() => {toggleSideMenuDetail('mycustomfilter')}} style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                            <AccordionSummary aria-controls='panel-content-mycustomfilter' id='paneld-header-mycustomfilter' className="subMenuHeading p-0" style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                                <p className="subMenuHeading m-0">
                                                    {sideMenuDetail['mycustomfilter'] && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                                    {!sideMenuDetail['mycustomfilter'] && <ArrowRightIcon className={classes.arrowIcon} />}
                                                    <span className="mt-4">My Filters</span>
                                                </p>
                                            </AccordionSummary>
                                            <AccordionDetails className="appTextColor" style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                                {filter.widgetState && filter.widgetState.customFilters && Object.keys(filter.widgetState.customFilters).length > 0 &&
                                                    filter.widgetState.customFilters.map(
                                                        (customFilter, idx) => (
                                                            <Col key={'customFilter_' + idx} style={{paddingLeft: '10px'}}>
                                                                <div onMouseEnter={(e) => updateCustomFiltersHover(idx + filter.widgetState.defaultCustomFilters.length, true)}
                                                                     onMouseLeave={(e) => updateCustomFiltersHover(idx + filter.widgetState.defaultCustomFilters.length, false)}
                                                                     className={'custom-filter-no-wrap'}
                                                                     >
                                                                    <input type="checkbox" id={'cb_customFilter_' + idx} style={{verticalAlign: 'middle'}}
                                                                           checked={(filter.state?.customFilterCheckboxes && filter.state?.customFilterCheckboxes[idx + filter.widgetState.defaultCustomFilters.length]) || false}
                                                                           onChange={(e)=> {setFilterCheckbox(filter, idx + filter.widgetState.defaultCustomFilters.length)}}
                                                                    />
                                                                    <span htmlFor={'cb_customFilter_' + idx} className={'custom-filter-label'}>
                                                                        &nbsp;{filter.widgetState.customFilters[idx].customFilterName}&nbsp;&nbsp;</span>
                                                                        {customFiltersHover[idx + filter.widgetState.defaultCustomFilters.length] && editable &&
                                                                            <div style={{display: 'inline-block'}}>
                                                                                <a href="#" onClick={(e) => {startFilteringWidget(filter, 'custom', idx);e.preventDefault();}}>edit</a>&nbsp;|&nbsp;
                                                                                <a href="#" onClick={(e) => {removeCustomFilterFromList(filter, fieldConfig, idx);e.preventDefault();}}>del</a>
                                                                            </div>
                                                                        }
                                                                </div>
                                                            </Col>
                                                        )
                                                    )
                                                }
                                            </AccordionDetails>
                                        </Accordion>
                                    </Col>
                                </AccordionDetails>
                            </Accordion>
                        }

                        <h6 id="ExportResultsScroll" className="mt-4"><b>GQ Power Tools</b></h6>
                        <div className="form-group">
                            {sideMenuDetail && <GQPowerTools
                                AB={isAbWorkflow}
                                VM={hasLvaAccess}
                                apiKey={gqApiKey}
                                //previewABReport = {previewABReport}
                                abFilters={abFilters}
                                //AbReportNames={abReportNames}
                                vmIds={vmIds}
                                userdir={userdir}
                                widgetState={widgetState}
                                filter={filter}
                                fieldConfig={fieldConfig}
                                applyFilterForJava={applyFilterForJava}
                                saveResultState={saveVmFilter}
                                setLvaFilterCheckbox={setLvaFilterCheckbox}
                                removeLvaFilterFromList={removeLvaFilterFromList}
                                sideMenuDetail={sideMenuDetail}
                                lvaLoadingReady={lvaLoadingReady}
                                reportId={reportId}
                                reportFilters={resultReportFilters}
                                editable={editable}

                                ftWindowsToOpen={ftWindowsToOpen}
                                setFtWindowsToOpen ={(value)=>{setFtWindowsToOpen(value)}}
                            ></GQPowerTools>}
                        </div>

                        <div className='floating-action-button-block'>
                            <div className="clearfix button-div sidebar-tour-width"
                            //tour-step tour-step-order="20"
                             //tour-step-content="Always press the Apply button after setting up a filter."
                            >
                                {reportId === undefined &&
                                <button id="floatApply"  className="pull-left small-button grey"
                                        onClick={() => {resetAllFitlers(fieldConfig, filter);loadResultsFromBackend(true);}}>Reset All Filters
                                </button>
                                }
                                {reportId !== undefined &&
                                    <button id="floatApply" disabled className="pull-left small-button grey">Reset All Filters</button>
                                }
                                {applyEnabled &&
                                    <button className="pull-left small-button gqblue-alert" style={{color: 'black'}}
                                            onClick={() => {applyAllFilters(filter);loadResultsFromBackend(true)}}
                                    >Apply</button>
                                }
                                {!applyEnabled &&
                                    <button disabled className="pull-left small-button gqblue-disabled" style={{color: 'black'}}>Apply</button>
                                }
                            </div>
                        </div>

                        <h6 id="ExportResults" className="mt-4"><b>Export Results</b></h6>
                        {sideMenuDetail &&
                            <Accordion square expanded={sideMenuDetail['exportspreadsheet']} onChange={() => toggleSideMenuDetail('exportspreadsheet')} style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                <AccordionSummary aria-controls="panelexcel-content" id="panelexcel-header" className="subMenuHeading p-0">
                                    <p className="subMenuHeading m-0">
                                        {sideMenuDetail['exportspreadsheet'] && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                        {!sideMenuDetail['exportspreadsheet'] && <ArrowRightIcon className={classes.arrowIcon} />}
                                        <span className={classes.arrowIconTitle}>Spreadsheet</span>
                                    </p>
                                </AccordionSummary>
                                <AccordionDetails className="appTextColor">
                                    <div className="side-menu-item">
                                        <span onClick={(e) => startExportDownload('EXCEL')} className="side-menu-span">- Microsoft Excel</span>
                                    </div>
                                    <div className="side-menu-item">
                                        <span onClick={(e) => startExportDownload('CSV')} className="side-menu-span">- Comma Separated (CSV)</span>
                                    </div>
                                    <div className="side-menu-item">
                                        <span onClick={(e) => startExportDownload('TSV')} className="side-menu-span">- Tab Separated (TSV)</span>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        }

                        {sideMenuDetail &&
                            <Accordion square expanded={sideMenuDetail['exportpnlist']} onChange={() => toggleSideMenuDetail('exportpnlist')} style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                <AccordionSummary aria-controls="panelpn-content" id="panelpn-header" className="subMenuHeading p-0">
                                    <p className="subMenuHeading m-0">
                                        {sideMenuDetail['exportpnlist'] && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                        {!sideMenuDetail['exportpnlist'] && <ArrowRightIcon className={classes.arrowIcon} />}
                                        <span className={classes.arrowIconTitle}>Publication Numbers</span>
                                    </p>
                                </AccordionSummary>
                                <AccordionDetails className="appTextColor">
                                    <div className="side-menu-item">
                                        <span onClick={(e) => startExportDownload('PNL')} className="side-menu-span">- Publication Number Only</span>
                                    </div>
                                    <div className="side-menu-item">
                                        <span onClick={(e) => startExportDownload('PNFIL')} className="side-menu-span">- Publication Number and Family Members</span>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        }

                        {sideMenuDetail &&
                            <Accordion square expanded={sideMenuDetail['exportdocument']} onChange={() => toggleSideMenuDetail('exportdocument')} style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                <AccordionSummary aria-controls="paneldoc-content" id="paneldoc-header" className="subMenuHeading p-0">
                                    <p className="subMenuHeading m-0">
                                        {sideMenuDetail['exportdocument'] && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                        {!sideMenuDetail['exportdocument'] && <ArrowRightIcon className={classes.arrowIcon} />}
                                        <span className={classes.arrowIconTitle}>Document</span>
                                    </p>
                                </AccordionSummary>
                                <AccordionDetails className="appTextColor">
                                    <div className="side-menu-item">
                                        <span onClick={() => {startExportDownload('Word')}} className="side-menu-span">- Microsoft Word</span>
                                    </div>
                                    <div className="side-menu-item">
                                        <span onClick={() => {startExportDownload('Word Simple')}} className="side-menu-span">- Microsoft Word (Simplified)</span>
                                    </div>
                                    <div className="side-menu-item">
                                        <span onClick={() => {startExportDownload('XML')}} className="side-menu-span">- XML Document</span>
                                    </div>
                                    <div className="side-menu-item">
                                        <span onClick={() => {startExportDownload('HTML')}} className="side-menu-span">- HTML Document</span>
                                    </div>
                                    <div className="side-menu-item">
                                        <span className="side-menu-span" style={{color: 'rgb(148 147 141 / 68%)'}}>- PDF Document</span>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        }

                        {sideMenuDetail &&
                            <Accordion square expanded={sideMenuDetail['exportother']} onChange={() => toggleSideMenuDetail('exportother')} style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                <AccordionSummary aria-controls="paneloth-content" id="paneloth-header" className="subMenuHeading p-0">
                                    <p className="subMenuHeading m-0">
                                        {sideMenuDetail['exportother'] && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                        {!sideMenuDetail['exportother'] && <ArrowRightIcon className={classes.arrowIcon} />}
                                        <span className={classes.arrowIconTitle}>Connectors</span>
                                    </p>
                                </AccordionSummary>
                                <AccordionDetails className="appTextColor">
                                    <div className="side-menu-item">
                                        <span onClick={() => {startExportDownload('LifeQuest')}} className="side-menu-span">- Export to LifeQuest</span>
                                    </div>
                                    {hasftAccess &&
                                        <div className="side-menu-item">
                                            <span onClick={() => {startExportDownload('FullText')}} className="side-menu-span">- Export to GQ Full Text</span>
                                        </div>
                                    }
                                    {!hasftAccess &&
                                        <div className="side-menu-item">
                                            <span className="side-menu-span" style={{color: 'rgb(148 147 141 / 68%)'}}>- Export to GQ Full Text</span>
                                        </div>
                                    }
                                    <div className="side-menu-item">
                                        <span onClick={() => {startExportDownload('BizInt')}} className="side-menu-span">- BizInt Smart Charts</span>
                                    </div>
                                    <div className="side-menu-item">
                                        <span onClick={() => {startExportDownload('Geneious')}} className="side-menu-span">- Geneious</span>
                                    </div>
                                    <div className="side-menu-item">
                                        <span className="side-menu-span" style={{color: 'rgb(148 147 141 / 68%)'}}>- Questel Orbit</span>
                                    </div>
                                    <div className="side-menu-item">
                                        <span onClick={() => {startExportDownload('Patbase')}} className="side-menu-span">- Export to PatBase</span>
                                    </div>
                                    <div className="side-menu-item">
                                        <span onClick={() => {openSaveResultsModal(true)}} className="side-menu-span">- Save Result As</span>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        }

                        {sideMenuDetail &&
                            <Accordion square expanded={sideMenuDetail['exportsequences']} onChange={() => toggleSideMenuDetail('exportsequences')} style={toggle? {backgroundColor: ''}:{backgroundColor: '#292929'}}>
                                <AccordionSummary aria-controls="panelseq-content" id="panelseq-header" className="subMenuHeading p-0">
                                    <p className="subMenuHeading m-0">
                                        {sideMenuDetail['exportsequences'] && <ArrowDropDownIcon className={classes.arrowIcon} />}
                                        {!sideMenuDetail['exportsequences'] && <ArrowRightIcon className={classes.arrowIcon} />}
                                        <span className={classes.arrowIconTitle}>Sequences</span>
                                    </p>
                                </AccordionSummary>
                                <AccordionDetails className="appTextColor">
                                    <div className="side-menu-item">
                                        <span onClick={() => {startExportDownload('Fastasub')}} className="side-menu-span">- Subject Sequences</span>
                                    </div>
                                    <div className="side-menu-item">
                                        <span onClick={() => {startExportDownload('Fastafrag')}} className="side-menu-span">- Alignment Fragments</span>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        }

                        <Accordion square expanded={false}>
                            <AccordionSummary aria-controls="panelclu-content" id="panelclu-header" className="subMenuHeading p-0">
                                <p className="subMenuHeading m-0">
                                    <ArrowRightIcon/>
                                    <span className={classes.arrowIconTitle} onClick={() => {startExportDownload('Clustal')}}>Clustal Omega</span>
                                </p>
                            </AccordionSummary>
                        </Accordion>

                    </Col>
                </Col>
                }
                <Col md="9" sm="9" xs="9">
                    {viewOptions && searchResultData.displayMode && (!hasLvaAccess || vmIds) && searchResultData.stats &&
                     <ResultStatsBar
                        tableDisplayMode={searchResultData.displayMode}
                        handleMenuCollapse={handleSideMenu}
                        sideMenuCollapse={sideMenuCollapse}
                        handleChartMenu={handleChartMenu}
                        viewCount={viewCount}
                        totalCount={searchResultData.stats.nav.total_groups}
                        fieldConfig={fieldConfig}
                        saveEsfGrouping={saveEsfGrouping}
                        viewOptions={viewOptions}
                        querySeqIdsCount ={querySeqIdsCount}
                        handleMultiQuerySeqMenu={handleMultiQuerySeqMenu}
                        deduplication={deduplication}
                        setDedDuplication={() => handleDedup(filter)}
                        databasesForDed={dataBasesForDed}
                        dedupModal={() => displayDedpreferences(filter)}
                        filter={filter}
                        handleSorting={changeSort}
                        handleManageSort={manageSortOptions}
                        groupOptions={groupOptions}
                        hasLvaAccess={hasLvaAccess}
                        lvaLoadingReady={lvaLoadingReady}
                        saveResultState={saveVmFilter}
                        vmIds={vmIds}
                        sideMenuDetail={sideMenuDetail}
                        editable={editable}
                        workflowId={resid.match(/\d+/)[0]}
                        userInfo={userInfo}
                        actionModal={saveFilteredResultBundle}
                    />}

                    { (multiQuerySeqMenu || chartMenu) &&
                        <TabUnderTool
                            chartobj={ChartService.chartObjects}
                            chart={chartMenu}
                            multiQuery={multiQuerySeqMenu}
                            queryDbStats={queryDbStats}
                            querydb={querydb}
                            supportedDisplayModes={supportedDisplayModes}
                            handleMulti={handleMulti}
                        />
                    }

                    <ResultTable
                        columns={searchResultData.columns}
                        data={searchResultData}
                        displayMode={searchResultData.displayMode}
                        tableWidth={searchResultData.tableWidth}
                        fieldConfig={fieldConfig}
                        annotState={annotState}
                        filter={filter}
                        expandAllDetailPage={expandAllDetailPage}
                        expandAllClaims={expandAllClaims}
                        expandAllAlignments={expandAllAlignments}
                        openItemsView={displayItemsView}
                        saveAnnotation={saveAnnotation}
                        refreshAnnotState={refreshAnnotState}
                        algorithm={resInfo.algorithm}
                        isAbWorkflow={isAbWorkflow}
                        editable={editable}
                        setFtWindowsToOpen ={(value)=>{setFtWindowsToOpen(value)}}
                    />

                    <Col className={'d-flex justify-content-center' + ((searchResultData.data && Object.keys(searchResultData.data).length) > 0 ? ' d-block' : ' d-none')} md="12">
                        <CustomPagination className={"float-right mt-2"} count={10} changePage={changePage} recordPerPage={pageCount} showFirstButton showLastButton defaultPage={1} page={currentPage} totalPages={totalPage} />
                    </Col>

                </Col>
                <SortingWidgetModal showModal={manageSortModal} 
                                   closeModal={closeManageSortModal} 
                                   fieldConfig={fieldConfig} filter={filter}
                                   sortOptions={fieldConfig.sortOptions}
                                   algorithm={resInfo.algorithm}
                                   regAccess={hasRegAccess}
                                   errorsInSortOption={errorsInSortOption}
                                   deleteSortOption={deleteSortOption}
                                    />
                <DisplayColumnModal fieldConfig={fieldConfig} showModal={addDisplayColumnShow} filter={filter} saveViews={saveViews}
                regNP={hasRegNp} regAccess={hasRegAccess} closeModal={closeFolderModal} columns={handleColumns} cancelModal={cancelDispModal} isAbWorkflow={isAbWorkflow} algorithm={resInfo.algorithm}/>
                <DiscoverContinueModal showModal={showDedAlert} closeButton={'Cancel'} actionButton={'Continue'}
                                       message={'This filter applies to the entire dataset, not just the deduplicated dataset.'}
                                       closeModal={closeDeduplicationModal} actionModal={continueDeduplicationModal}
                                       filter={filter} filterState={filterState} db={selectedDatabase} />
                <DiscoverContinueModal showModal={showContinueAlert} closeButton={'Continue'} actionButton={'Start Over'}
                                       message={continueMsg} closeModal={closeContinueModal} actionModal={startOver}
                                       filter={filter} filterState={filterState} db={resInfo} fieldConfig={fieldConfig}/>
                <DiscoverContinueModal showModal={showReportAlert} closeButton={'Read Only Report'} actionButton={'Editable Report'}
                                       message={continueMsg} closeModal={closeReportModal} actionModal={startOverReport}
                                       filter={filter} filterState={filterState} db={resInfo} fieldConfig={fieldConfig}/>
                <DiscoverMessageModal showModal={showInfoMsg} message={infoMsg}
                                      closeModal={closeMessageModal} fieldConfig={fieldConfig}/>
                <DiscoverExportAllModal showModal={showExportAllModal} closeModal={closeExportModal} actionModal={exportToExcel}
                                        totalAlignmentNumber={totalAlignmentNumber} totalPatentNumber={totalPatentNumber}
                                        downloadType={downloadType} downloadTypeDesc={downloadTypeDesc} alertMessage={alertMessage}
                                        filter={filter} fieldConfig={fieldConfig} resInfo={resInfo}
                                        includeAnnotation={includeAnnotation} includeSS={includeSS}/>
                <DedupPrefModal showModal={dedupModal} closeModal={closeDedupModal} saveModal={saveDedup} prefsForUI={dedDataBasePrefsForUI}
                                fieldConfig={fieldConfig} filter={filter} />
                <DiscoverExportChoicesModal showModal={showExportChoicesModal} closeModal={closeExportModal} actionModal={exportToExcel}
                                        totalAlignmentNumber={totalAlignmentNumber} customTtlCount={customTtlCount} displayCustomSelection={displayCustomSelection}
                                        downloadType={downloadType} downloadTypeDesc={downloadTypeDesc}
                                        selectedAlignmentsCount={selectedAlignmentsCount} customSelectionLabel={customSelectionLabel}
                                        alertMessage={alertMessage} selectedAlertMessage={selectedAlertMessage} customAlertMessage={customAlertMessage}
                                        filter={filter} fieldConfig={fieldConfig} resInfo={resInfo} />
                <DiscoverPatbaseModal showModal={showPatbaseModal} closeModal={closeExportModal} patbasePnsStr={patbasePnsStr}
                                      totalPatbasePns={totalPatbasePns} pbCountdown={pbCountdown} patbasePns={patbasePns} />
                <DiscoverExportVmModal showModal={showExportVmModal} closeModal={closeExportModal} actionModal={exportVmAlignments}
                                       downloadType={downloadType} filter={filter} fieldConfig={fieldConfig} resInfo={resInfo} />
                <DiscoverSaveResultsModal showModal={showSaveResultsModal} closeModal={closeSaveResultsSucModal} actionModal={saveFilteredResult}
                                          filter={filter} fieldConfig={fieldConfig} resInfo={resInfo} defaultTitle={saveAsName} downloadType={downloadType}
                                          selectedAlignmentsCount={selectedAlignmentsCount} customSelectionLabel={customSelectionLabel}
                                          totalAlignmentNumber={totalAlignmentNumber} customTtlCount={customTtlCount} displayCustomSelection={displayCustomSelection}/>
                <DiscoverSaveResultsSucModal showModal={showSaveResultsSucModal} closeModal={closeSaveResultsSucModal}
                                             saveFiltedResultMsg={saveFiltedResultMsg} downloadType={downloadType} />
                <DiscoverMoreItemsModal showModal={showItemsModal} closeModal={closeItemsModal} filter={filter}
                                        currentPage={itemsCurrentPage} totalPage={itemsTotalPage} changePage={changeItemsPage}
                                        searchResultData={itemsData} fieldConfig={fieldConfig} expandAllClaims={expandAllClaims}
                                        expandAllDetailPage={expandAllDetailPage} expandAllAlignments={expandAllAlignments}
                                        groupValue1={groupValue1} groupValue2={groupValue2} annotState={itemsAnnotState}
                                        saveAnnotation={saveAnnotation} parentAnnotState={annotState} refreshAnnotState={refreshAnnotState}
                                        algorithm={resInfo.algorithm} isAbWorkflow={isAbWorkflow}/>
                {addFilterPopup &&
                    <DiscoverFilterModal showModal={addFilterPopup} closeModal={closeFilterModal} callback={saveFilter}
                                         widgetState={widgetState} filter={filter} fieldConfig={fieldConfig} sideMenuDetail={sideMenuDetail}
                                         displayRegFields={hasRegAccess} hasRegNP={hasRegNp} isAbWorkflow={isAbWorkflow} algorithm={resInfo.algorithm}
                                         keyWordsInContext={(retRay)=>{addKeyWordsInContext(filter, retRay, fieldConfig)}}
                                         />}
                {deleteFilterPopup &&
                    <DiscoverFilterDeleteModal showModal={deleteFilterPopup} closeModal={closeDeleteFilterModal}
                                               filter={filter} fieldConfig={fieldConfig} deleteIdx={deleteIdx}
                                               removeCustomFilterFromListAction={removeCustomFilterFromListAction} />
                }
            </Row>
            {showLoader &&
                <div className={classes.loader}>
                    <div className={classes.loaderPosition}>
                        <div className="spinner-container">
                            <div className="spinner-socket">
                                <div className="spinner-hex spinner-tile-1">
                                    <div className="spinner-brick rotate-0"></div>
                                    <div className="spinner-brick rotate-1"></div>
                                    <div className="spinner-brick rotate-2"></div>
                                </div>
                                <div className="spinner-hex spinner-tile-2">
                                    <div className="spinner-brick rotate-0"></div>
                                    <div className="spinner-brick rotate-1"></div>
                                    <div className="spinner-brick rotate-2"></div>
                                </div>
                                <div className="spinner-hex spinner-tile-3 spinner-row-2">
                                    <div className="spinner-brick rotate-0"></div>
                                    <div className="spinner-brick rotate-1"></div>
                                    <div className="spinner-brick rotate-2"></div>
                                </div>
                                <div className="spinner-hex spinner-tile-4 spinner-row-2">
                                    <div className="spinner-brick rotate-0"></div>
                                    <div className="spinner-brick rotate-1"></div>
                                    <div className="spinner-brick rotate-2"></div>
                                </div>
                                <div className="spinner-hex spinner-tile-5 spinner-row-2">
                                    <div className="spinner-brick rotate-0"></div>
                                    <div className="spinner-brick rotate-1"></div>
                                    <div className="spinner-brick rotate-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
        </Styles>
    );
}
export default Discover;
