import { HashRouter, Switch, Redirect } from 'react-router-dom';
import React from 'react';
import history from '../helpers/history';
import Route from "./Route";
import Login from '../components/Auth/Login';
import ForgotPassword from '../components/Auth/Forgotpassword';
import Home from '../components/Home/Home'
import ApplicationPanel from '../components/Home/ApplicationPanel';
import SearchResult from '../components/SearchedResults/SearchResults';
import IpSequenceSearch from '../components/IpSequenceSearch/IpSequenceSearch';
import ChangePassword from '../components/Auth/ChangePassword';
import SearchManagement from '../components/SearchManagement/SearchManagement';
import AccountSettings from '../components/AccountSettings/AccountSettings';
import MergeResults from '../components/MergeResults/MergeResults';
import SearchResultSequence from '../components/SearchResultSequence/SearchResultSequence';
import SearchResultSeqdb from '../components/SearchResultSeqdb/SearchResultSeqdb';
import IpSequenceVariation from '../components/IpSequenceVariation/IpSequenceVariation';
import SearchResultAntibody from '../components/SearchResultAntibody/SearchResultAntibody'
import ResultReportFolder from '../components/ResultReportFolder/ResultReportFolder';

import FullTextSearch from '../components/FullTextSearch/FullTextSearch'
import NewPassword from '../components/Auth/NewPassword';
import ParsedXml from '../components/ConvertSequence/ParsedXml';
import ProteinSingle from '../components/ConvertSequence/ProteinSingle';
import ProteinTranslation from '../components/ConvertSequence/ProteinTranslation';
import PersonalDatabase from '../components/PersonalDatabase/PersonalDatabase';
import ScrollToTop from "../components/Home/ScrollToTop";
import fullDocView from "../components/FullTextSearch/FullTextResults/FullDocumentView";
import FullTextHistory from '../components/FullTextSearch/FullTextHistory/FullTextHistory';
import VmReport from '../components/Variation/VariationReport';
import Discover from '../components/Discover/Discover';
import AntiBodyReport from '../components/Antibody/AntiBodyReport';
import PatentNumberSearch from '../components/PatentNumberSearch/PatentNumberSearch'
import Ontologies from '../components/FullTextSearch/Ontologies';
import TabularFormConversion from '../components/ConvertSequence/TabularFormConversion';
import ThreeLetterCodeConversion from '../components/ConvertSequence/ThreeLetterCodeConversion';
// import SingleLetterConversionCode from '../components/ConvertSequence/SingleLetterCodeConversion';
import ConvertSequenceFormat from '../components/ConvertSequence/ConvertSequenceFormat';
import PatentSearchResult from '../components/PatentNumberSearch/PatentSearchResult'
import PatentDatabases from '../components/PatentNumberSearch/PatentDatabases';
import FullDocManager from '../components/FullDocManager/FullDocManager';
import ResultReport from '../components/Discover/GQPowerTools/ResultReport';
import UserDomains from '../components/AccountSettings/UserDomains';
import ClustaloReport from '../components/Clustal/ClustalOmegaReport';
import ClustalOmega from '../components/Clustal/ClustalOmega';

import CustomDBCreator from '../components/CustomDatabase/CustomDBCreator'

class Routes extends React.Component {


    render() {
        const isUserLogin = localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn') : false;

        return (
            <HashRouter history={history}>
                <ScrollToTop />
                <Switch>
                    <Route exact path="/" title="" component={Login} />
                    <Route exact path="/login" title="login" component={Login} />
                    <Route exact path="/forgot" title="forgot" component={ForgotPassword} />
                    <Route exact path="/newpassword" component={NewPassword} />
                    <Route exact path="/reset_password&key=:key&userId:userId" component={NewPassword} />
                    <Route exact path="/home" title="" component={Home} />

                   {isUserLogin ? 
                    <React.Fragment>
                        <Route exact path="/my_data" title="manageSearchRes" component={SearchManagement} />
                        <Route exact path="/changePassword" title="changePassMange" component={ChangePassword} />
                        <Route exact path="/search" title="savedSearchForms" component={SearchResult} />
                        {/* <Route exact path="/recent" component={Recent} /> */}
                        <Route exact path="/applicationPanel" component={ApplicationPanel} />
                        <Route exact path="/ipseqsearch" title="ipseqsearch" component={IpSequenceSearch} />
                        <Route exact path="/ipseqsearch/:parentId" title="ipseqsearch" component={IpSequenceSearch} />
                        <Route exact path="/ipseqsearch/template/:tempname" title="ipseqsearch" component={IpSequenceSearch} />
                        <Route exact path="/searchresseq" title="searchresseq" component={SearchResultSequence} />
                        <Route exact path="/searchresseq/:workflowId" title="searchresseq" component={SearchResultSequence} />
                        <Route exact path="/searchresdoc/:workflowId" title="searchresdoc" component={SearchResultSequence} />
                        <Route exact path="/searchresantibody" title="searchresantibody" component={SearchResultAntibody} />

                        <Route exact path="/searchresantibody/:workflowId" title="searchresantibody" component={SearchResultAntibody} />
                        <Route exact path="/searchresantibody/template/:tempname" title="searchresantibody" component={SearchResultAntibody} />
                        <Route exact path="/searchresdb/:workflowId" title="searchresdb" component={SearchResultSeqdb} />
                        <Route exact path="/searchResult" title="manageSearchRes" component={SearchManagement} />
                        {/*<Route exact path="/accountinfo" title="accountInfoMange" component={AccountInfo} />*/}
                        <Route exact path="/accountsettings" title="Account Management" component={AccountSettings} />
                        <Route exact path="/whitelistdomain" title="Domain Groups" component={UserDomains} />

                        <Route exact path="/merge" component={MergeResults} />
                        <Route exact path="/ipseqvariation" title="ipseqvariation" component={IpSequenceVariation} />
                        <Route exact path="/ipseqvariation/:parentId" title="ipseqvariation" component={IpSequenceVariation} />
                        <Route exact path="/ipseqvariation/template/:tempname" title="ipseqvariation" component={IpSequenceVariation} />
                        <Route exact path="/report/folder/:folderId" title='foldermoduletitle' component={ResultReportFolder} />

                        <Route exact path="/fulltextsearch" title="patentFullTextSearch" component={FullTextSearch} />
                        <Route exact path="/fulltextsearch/:workflowId" title="patentFullTextSearch" component={FullTextSearch} />
                     
                        <Route exact path="/patentnumbersearch" title="Keyword Search" component={PatentNumberSearch} />
                        <Route exact path="/patentnumbersearch/field_search" title="Keyword Search Result" component={PatentSearchResult} />
                        <Route exact path="/patentDatabases" title="Patent Search Result" component={PatentDatabases} />

                        <Route exact path="/ftSearchHistory" title="ftSearchHistory" component={FullTextHistory} />
                        <Route exact path="/UserSearchTerms" title="User's Search Terms" component={Ontologies}/>


                        <Route exact path="/convertsequence" title="Sequence Format Conversion" component={ConvertSequenceFormat} />
                        <Route exact path="/parsedxml" title="Sequence Format Conversion" component={ParsedXml} />
                        <Route exact path="/ProteinSingle" title="Sequence Format Conversion" component={ProteinSingle} />
                        <Route exact path="/ProteinTranslation" title="Sequence Format Conversion" component={ProteinTranslation} />
                        <Route exact path="/tabularformconversion" title="Sequence Format Conversion" component={TabularFormConversion} />
                        <Route exact path="/ThreeLetterConversion" title="Sequence Format Conversion" component={ThreeLetterCodeConversion} />
                        {/* <Route exact path="/SingleLetterConversionCode" title="Sequence Format Conversion" component={SingleLetterConversionCode} /> */}
                        <Route exact path="/personaldb" title="Personal Databases" component={PersonalDatabase} />
                        <Route exact path="/fulldocview/:patentId" title="Full Document View" component={fullDocView} />
                        <Route exact path="/vmreport/:id/:file" title="Variation Landscape Report" component={VmReport} />
                        <Route exact path="/discover/:resid" title="GQ Discover" component={Discover} />
                        <Route exact path="/discover/:resid/:alerts" title="GQ Discover" component={Discover} />
                        <Route exact path="/discover/:resid/alignment/:singleAlignmentId" title="GQ Discover" component={Discover} />
                        <Route exact path="/discover/:resid/report/:reportId" title="GQ Discover Report" component={Discover} />
                        <Route exact path="/discover/:resid/variation/:vmQueryId/:vmExp" title="GQ Discover" component={Discover} />
                        <Route exact path="/discover/:resid/variation/:vmQueryId/:vmExp/:reportId" title="GQ Discover" component={Discover} />
                        <Route exact path="/antibody/:resid" title="GQ Discover antibody Report" component={AntiBodyReport}/>
                        <Route exact path="/antibody/:resid/:reportName" title="GQ Discover antibody Report" component={AntiBodyReport}/>
                        <Route exact path="/discover/:resid/antibody/:abReportfilters" title="GQ Discover from AB" component={Discover} />
                        <Route exact path="/discover/:resid/antibody/:abReportfilters/:reportId" title="GQ Discover from AB" component={Discover} />
                        <Route exact path="/FullDocManager" title="Full-text Document Manager" component={FullDocManager} />
                        <Route exact path="/resultreport/:resid" title="GQ Discover Graphical Report" component={ResultReport}/>
                        <Route exact path="/resultreport/:resid/:reportName" title="GQ Discover Graphical Report" component={ResultReport}/>
                        <Route exact path="/clustaloreport/:file" title="Clustal Omega Report" component={ClustaloReport}/>
                        <Route exact path="/clustalo" title="clustalo" component={ClustalOmega}/>
                        <Route exact path="/CustomDB" title="Custom Database Creator" component={CustomDBCreator}/>


                    </React.Fragment> 
                     :  
                    <Redirect to='/login' />
                    } 


                </Switch>
            </HashRouter>)
    }
}

export default Routes

